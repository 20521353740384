import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LOADIPHLPAPI } from 'dns';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit, OnChanges {
  @ViewChild('nzCarousel', { static: false }) nzCarousel!: any;
  @Input() images: string[] | any = [];
  @Input() isDisablePreview: boolean = false;

  public imagesMock = [
    'https://images.unsplash.com/photo-1580757468214-c73f7062a5cb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80',
    'https://images.unsplash.com/photo-1595835018349-198460e1d309?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1631&q=80',
    'https://images.unsplash.com/photo-1599154456742-c82164d2dfb0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1631&q=80',
    'https://images.unsplash.com/photo-1605804097539-f7b052b4960d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1631&q=80',
  ];
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.images = this.images || [];
    // console.log(this.images);
  }

  ngOnInit(): void {
    // this.images = this.images || this.imagesMock;
  }

  switchImage(type: string) {
    switch (type) {
      case 'prev':
        this.nzCarousel.pre();
        break;
      case 'next':
        this.nzCarousel.next();
        break;
    }
  }
}
