import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LandingPageModule } from './landing-page/landing-page.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SharedModule, LandingPageModule],
})
export class FeaturesModule {}
