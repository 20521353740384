import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDragService } from 'ng-zorro-antd/core/services';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { QuillModule } from 'ngx-quill';
import { UiModule } from 'src/app/core/ui/ui.module';
import { SharedPreviewModule } from '../preview/shared/shared-preview.module';
import { SharedModule } from './../../shared/shared.module';
import { AddLandingPageComponent } from './components/add-landing-page/add-landing-page.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { DetailSampleComponent } from './components/detail-sample/detail-sample.component';
import { EditFunctionBtnComponent } from './components/edit-components/edit-function-btn/edit-function-btn.component';
import { EditTypeActionComponent } from './components/edit-components/edit-type-action/edit-type-action.component';
import { EditTypeButtonComponent } from './components/edit-components/edit-type-button/edit-type-button.component';
import { EditTypeContentComponent } from './components/edit-components/edit-type-content/edit-type-content.component';
import { EditTypeImageComponent } from './components/edit-components/edit-type-image/edit-type-image.component';
import { EditTypeLinkComponent } from './components/edit-components/edit-type-link/edit-type-link.component';
import { EditContainerComponent } from './components/edit-container/edit-container.component';
import { EditContainerModule } from './components/edit-container/edit-container.module';
import { EditFooterComponent } from './components/edit-footer/edit-footer.component';
import { PreviewLandingComponent } from './components/preview-landing/preview-landing.component';
import { LandingPageComponent } from './landing-page.component';
import { LandingPageRoutes } from './landing-page.routing';
import { SharedComponentModule } from './shared/shared-component.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { EditAlignmentComponent } from './components/edit-components/edit-alignment/edit-alignment.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import { SetRoleCopyComponent } from './components/set-role-copy/set-role-copy.component';
import { PushWarehouseComponent} from './components/push-warehouse/push-warehouse.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@NgModule({
  declarations: [
    LandingPageComponent,
    AddLandingPageComponent,
    DetailSampleComponent,
    EditContainerComponent,
    EditFooterComponent,
    EditTypeContentComponent,
    EditTypeLinkComponent,
    EditTypeImageComponent,
    ColorPickerComponent,
    EditTypeButtonComponent,
    EditFunctionBtnComponent,
    PreviewLandingComponent,
    EditTypeActionComponent,
    EditAlignmentComponent,
    SetRoleCopyComponent,
    PushWarehouseComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    UiModule,
    SharedComponentModule,
    EditContainerModule,
    LandingPageRoutes,
    SharedPreviewModule,
    // ant design
    NzSelectModule,
    NzInputModule,
    NzButtonModule,
    NzModalModule,
    NzDividerModule,
    NzIconModule,
    NzTabsModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzDrawerModule,
    NzPopconfirmModule,
    NzImageModule,
    DragDropModule,
    QuillModule,
    NzSwitchModule,
    NzSliderModule,
    NzInputNumberModule,
    NzRadioModule,
    NzDropDownModule,
    NzAnchorModule,
    NzSpinModule
  ],
  exports: [
    // ant design
    NzSelectModule,
    NzInputModule,
    NzButtonModule,
    NzModalModule,
    NzDividerModule,
    NzIconModule,
    NzTabsModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzDrawerModule,
    NzPopconfirmModule,
    NzImageModule,
    DragDropModule,
    QuillModule,
    NzSwitchModule,
    NzSliderModule,
    NzInputNumberModule,
    NzRadioModule,
    NzDropDownModule,
    NzAnchorModule,
  ],
  providers: [NzDragService],
})
export class LandingPageModule {}
