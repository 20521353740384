import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { CdnService } from '../../services/cdn.service';
import { NotiService } from '../../services/noti.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-resize-image',
  templateUrl: './resize-image.component.html',
  styleUrls: ['./resize-image.component.scss'],
})
export class ResizeImageComponent implements OnInit {
  @Input() file: any;
  @Input() inputTitle: string = 'Chỉnh sửa ảnh';
  @Input() maintainAspectRatio = false;
  @Input() aspectRatio: number = 1 / 1;
  @Input() inputRoundedCropper: boolean = false; // false -> hình vuông , true -> hình tròn
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  imageTransform: ImageTransform = {};
  uploadImage: any = '';
  disabledBtn: boolean = false;
  constructor(
    private cdnService: CdnService,
    private modal: NzModalRef,
    private noti: NotiService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {}

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.uploadImage = this.base64ToFile(event.base64, 'cv-avatar.png');
  }

  imageLoaded() {
    this.showCropper = true;
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  base64ToFile(data: any, filename: string) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  handleEmitAction(isSave: boolean) {
    if (isSave) {
    }
    this.modal.close(isSave);
  }

  ok() {
    this.disabledBtn = true;
    this.loadingService.openLoadingModal();
    this.cdnService.uploadFile(this.uploadImage, 'avatar').subscribe(
      (data) => {
        this.modal.close(data[0]);
        this.loadingService.closeLoadingModal();
      },
      (err) => {
        this.noti.warning();
        this.loadingService.closeLoadingModal();
      }
    );
  }

  cancel() {
    this.modal.close(false);
  }
}
