import { Pipe, PipeTransform } from '@angular/core';
import { log } from 'console';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {
  constructor(){

  }
  transform(value: unknown, ...args: unknown[]): unknown {
    console.log(value);
    return value;
  }
}
