import { Type } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { TypeUpload } from 'src/app/core/ui/upload-image/upload-image.component';
import { TypeComponent } from '../../../enums/typeComponents.enum';
import { TypeImage } from '../../../models/screenEdit';

@Component({
  selector: 'app-edit-type-image',
  templateUrl: './edit-type-image.component.html',
  styleUrls: ['./edit-type-image.component.scss'],
})
export class EditTypeImageComponent implements OnInit {
  @Input() idSelected: string = ''; // landingID
  @Input() type: TypeComponent = TypeComponent.Create;
  TypeComponent = TypeComponent;
  TypeUpload = TypeUpload;
  item: TypeImage = new TypeImage();
  listImageAdd: string[] = [];
  constructor(
    private drawer: NzDrawerRef,
    private landingService: LandingPageService,
    private noti: NotiService
  ) {}

  ngOnInit(): void {
    if (this.type === TypeComponent.Edit) {
      this.getDetail();
    }
  }

  getDetail() {
    this.landingService.getDetailImage(this.idSelected).subscribe((data) => {
      if (!data) return;
      this.item = {
        listImage: data.map((item: any) => {
          return item.value;
        }),
      };
    });
  }

  handleEmitImageCropped($event: any) {
    // add to listImage
    this.item.listImage?.push($event);
  }

  remove(image: any) {
    // console.log(this.item.listImage?.indexOf(image, 0), 1);
    this.item.listImage?.splice(this.item.listImage?.indexOf(image, 0), 1);
  }

  update() {
    let body = {
      landingID: this.idSelected,
      value: this.item.listImage,
    };
    this.landingService.updateListImage(body).subscribe(
      (data) => {
        if (!data) return;
        this.noti.success();
        this.emitSucess();
      },
      (err) => this.noti.warning()
    );
  }
  create() {}

  cancel() {
    this.drawer.close();
  }

  emitSucess() {
    this.drawer.close(true);
  }
}
