<ng-container
  *ngIf="post.outOfStock; then oosTpl; else nonOosTpl"
></ng-container>
<ng-template #oosTpl>
  <nz-ribbon nzText="Hết hàng" nzColor="red">
    <ng-container [ngTemplateOutlet]="postRibbon"></ng-container>
  </nz-ribbon>
</ng-template>
<ng-template #nonOosTpl>
  <ng-container [ngTemplateOutlet]="postRibbon"></ng-container>
</ng-template>

<ng-template #postRibbon>
  <div
    class="rounded-sm border-[1.5px] border-solid border-gray-200 cursor-pointer"
    [formGroup]="form"
  >
    <div
      width="167px"
      height="157px"
      class="relativee"
      [ngClass]="{ 'opacity-10': post.hidden }"
      (click)="detailPostNavigate(post.id)"
    >
      <img
        style="height: 157px !important"
        nz-image
        class="object-cover w-full relativee z-10 rounded-sm overflow-hidden"
        [nzSrc]="post.thumbnail || errorLoading"
        [nzDisablePreview]="true"
        alt=""
      />
    </div>
    <div class="p-1">
      <div
        class="line-clamp-1 h-[22px]"
        [ngClass]="{ 'opacity-10': post.hidden }"
        (click)="detailPostNavigate(post.id)"
      >
        {{ post.postName }}
      </div>
      <div class="mt-2 flex items-end justify-between">
        <div [ngClass]="{ 'opacity-10': post.hidden }">
          <span class="text-red-text mr-2 font-bold" [ngSwitch]="">
            <span *ngIf="post.discountPrices! < 2 || ''"></span>
            <span *ngIf="post.discountPrices! > 2">{{ post.discountPrices | number }} đ</span>
          </span>
          <span
            class="line-through"
            *ngIf="post.prices && post.discountPrices! > 2"
            >{{ post.prices | number }} đ</span
          >
        </div>
        <!-- <div [ngClass]="{ 'opacity-10': post.hidden }">
          <span class="text-red-text mr-2 font-bold" [ngSwitch]="">
            <span *ngIf="post.prices! < 2 || ''"></span>
            <span *ngIf="post.prices! > 2">{{ post.prices | number }} đ</span>
          </span>
          <span
            class="line-through"
            *ngIf="post.discountPrices && post.prices! > 2"
            >{{ post.discountPrices | number }} đ</span
          >
        </div> -->
        <button
          nz-dropdown
          nzTrigger="click"
          *ngIf="typePreview === TypePreview.Home"
          [nzDropdownMenu]="menu"
          class="button p-0 bg-white text-primary h-8 w-8 rounded-sm"
        >
          <i class="fa-sharp fa-solid fa-ellipsis-vertical"></i>
        </button>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu>
            <li nz-menu-item>
              <label nz-checkbox formControlName="hidden">Ẩn sản phẩm</label>
            </li>
            <li nz-menu-item>
              <label nz-checkbox formControlName="outOfStock">Hết hàng</label>
            </li>
            <li nz-menu-item (click)="update(post)">
              <div>
                <i
                  class="fa-sharp fa-regular fa-pen-to-square mr-2 text-primary"
                ></i>
                <span>Sửa bài đăng</span>
              </div>
            </li>
            <li
              nz-menu-item
              nz-popconfirm
              nzPopconfirmTitle="Xác nhận xóa"
              nzPopconfirmPlacement="bottom"
              [nzCancelText]="'Hủy bỏ'"
              nzOkText="Chấp nhận"
              (nzOnConfirm)="delete(post)"
            >
              <button>
                <i
                  class="fa-sharp fa-regular fa-trash-can mr-2 text-red-text"
                ></i>
                <span>Xóa</span>
              </button>
            </li>
          </ul>
        </nz-dropdown-menu>
      </div>
    </div>
  </div>
</ng-template>
