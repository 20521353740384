import { Component, OnInit } from '@angular/core';
import {
  ItemType,
  listActionsOptionsEnum,
  TypeItem,
} from '../../models/screenEdit';

@Component({
  selector: 'app-detail-sample',
  templateUrl: './detail-sample.component.html',
  styleUrls: ['./detail-sample.component.scss'],
})
export class DetailSampleComponent implements OnInit {
  listAllItems = [
    {
      title: 'Hình ảnh 1',
      type: ItemType.image,
      listImage: [
        './../../../../../assets/images/Rectangle 100.png',
        './../../../../../assets/images/Rectangle 100.png',
        './../../../../../assets/images/Rectangle 100.png',
        './../../../../../assets/images/Rectangle 100.png',
      ],
    },
    {
      title: 'Hình ảnh 1',
      type: ItemType.image,
      listImage: ['./../../../../../assets/images/Rectangle 100.png'],
    },
    {
      title: 'Kêu gọi hành động 1 ',
      content: 'Bạn có thắc mắc?! Đừng lo, hãy gọi ngay cho Khú...',
      btnActionText: 'Gửi thông tin',
      listOptions: [
        listActionsOptionsEnum.Fullname,
        listActionsOptionsEnum.Email,
        listActionsOptionsEnum.Phone,
      ],
      type: ItemType.action,
    },
    {
      title: 'Liên kết 1',
      type: ItemType.connective,
      label: 'Tài liệu tái cấu trúc true',
      value: 'https://www.youtube.com/watch?v=nGD9sCk0EOA',
    },
    {
      title: 'Nội dung 1',
      type: ItemType.content,
      text: '<p>1231231231231</p><h2>1212</h2><ol><li><u>1212121</u></li><li><u>1231241241</u></li><li><u>1241241412</u></li><li><strong><u>u8891joasiuabifbaifbaiqqweq</u></strong></li></ol>',
    },
  ];
  ItemType = ItemType;
  TypeItem = TypeItem;
  constructor() {}

  ngOnInit(): void {}
}
