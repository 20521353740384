import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { log } from 'console';

import { Observable } from 'rxjs';
export class SecretInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('https://provinces.open-api.vn/api/')) {
      return next.handle(request);
    } else {
      const token = localStorage.getItem('access_token');
      if (token) {
        const AuthRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
        return next.handle(AuthRequest);
      } else {
        return next.handle(request);
      }
    }
  }
}
