<div
  style="border-bottom: 1px solid #0eaf8f; border-top: 1px solid #0eaf8f"
  class="flex items-center justify-between px-4 py-2 bg-[#F2FFFC] fixed-center"
>
  <div class="flex items-center">
    <i class="fa-regular fa-eye mr-3"></i>
    <span>{{ title }}</span>
  </div>
  <div class="flex items-center">
    <div (click)="back()">
      <button class="button-success px-3">Trở lại</button>
    </div>
    <button
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="share"
      *ngIf="isShowShare"
      class="border border-solid border-primary rounded-sm text-primary bg-white w-7 h-7 ml-3 flex items-center justify-center cursor-pointer"
    >
      <i class="fa-solid fa-share-nodes"></i>
    </button>
    <nz-dropdown-menu #share="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          style="font-size: 16px; padding: 8px"
          (click)="shareLink()"
        >
          <i class="fa-solid fa-link mr-2"></i>
          <span>Chia sẻ liên kết</span>
        </li>
        <li
          nz-menu-item
          style="font-size: 16px; padding: 8px"
          (click)="shareQr()"
        >
          <i class="fa-solid fa-qrcode mr-2"></i>
          <span>Chia sẻ QR</span>
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>
</div>
