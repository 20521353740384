import { NgModule } from '@angular/core';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { QuillModule } from 'ngx-quill';
import { HtmlSanitizerPipe } from 'src/app/core/pipes/sanitizeHtml.pipe';
import { UiModule } from 'src/app/core/ui/ui.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { TypeActionComponent } from '../components/type-content-edit/type-action/type-action.component';
import { TypeButtonComponent } from '../components/type-content-edit/type-button/type-button.component';
import { TypeContentComponent } from '../components/type-content-edit/type-content/type-content.component';
import { TypeImageComponent } from '../components/type-content-edit/type-image/type-image.component';
import { TypeLinkComponent } from '../components/type-content-edit/type-link/type-link.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';

@NgModule({
  declarations: [
    TypeActionComponent,
    TypeImageComponent,
    TypeLinkComponent,
    TypeContentComponent,
    HtmlSanitizerPipe,
    TypeButtonComponent,
  ],
  imports: [
    QuillModule,
    SharedModule,
    NzCarouselModule,
    NzImageModule,
    UiModule,
    NzSelectModule,
    NzDividerModule,
    NzDatePickerModule,
    NzInputModule,
    SwiperModule,
    NzSkeletonModule,
  ],
  exports: [
    TypeActionComponent,
    TypeImageComponent,
    TypeContentComponent,
    TypeLinkComponent,
    TypeButtonComponent,
  ],
})
export class SharedComponentModule {}
