import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ItemType, TypeImage, TypeItem } from '../../../models/screenEdit';
// import Swiper core and required modules
import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { LandingPageService } from 'src/app/core/services/landingPage.service';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
@Component({
  selector: 'app-type-image',
  templateUrl: './type-image.component.html',
  styleUrls: ['./type-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TypeImageComponent implements OnInit, OnChanges {
  @Input() id: string = '';
  @Input() index: number = -1;
  @Input() isIntersection: boolean = false;
  TypeItem = TypeItem;
  @Input() item: TypeImage = new TypeImage();
  @Input() isCallAPI: boolean = true;
  isLoading: boolean = true;
  constructor(
    private nzMessage: NzMessageService,
    private landingService: LandingPageService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id && this.isIntersection) {
      this.getDetail();
    }
    if (!this.isCallAPI) {
      this.isLoading = false;
    }
  }

  getDetail() {
    this.landingService.getDetailImage(this.id).subscribe((data) => {
      let backgroundImage = '';
      let backgroundColor = '';
      if (data.length > 0) {
        backgroundImage = data[0].backgroundImage;
        backgroundColor = data[0].backgroundColor;
      }

      this.item = {
        type: ItemType.image,
        listImage: data.map((item: any) => {
          return item.value;
        }),
        backgroundColor: backgroundColor,
        backgroundImage: backgroundImage,
        alignmentBottom: data[0]?.alignmentBottom || 0,
        alignmentTop: data[0]?.alignmentTop || 0,
        alignmentRight: data[0]?.alignmentRight || 0,
        alignmentLeft: data[0]?.alignmentLeft || 0,
      };
      this.isLoading = false;
    });
  }

  handleEmitImageCropped($event: any, item: TypeImage) {
    item.listImage?.push($event);
  }

  delete(image: any) {
    this.item.listImage?.splice(this.item.listImage.indexOf(image), 1);
  }
}
