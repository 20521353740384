import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  CPostDataDetail,
  IPostDataDetail,
} from 'src/app/core/interfaces/post.interface';
import { WebsiteService } from 'src/app/core/services/website.service';
import { CommonService } from 'src/app/features/landing-page/services/common.service';
import { KeyStorage } from 'src/app/core/enums';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { ConcernService } from 'src/app/core/services/concern.service';
import { ECategoryType } from 'src/app/core/enums/category.enum';

import { OAuthService } from 'angular-oauth2-oidc';

import { TypePreview } from '../../preview-container/preview-container.component';

@Component({
  selector: 'app-detail-post',
  templateUrl: './detail-post.component.html',
  styleUrls: ['./detail-post.component.scss'],
})
export class DetailPostComponent implements OnInit, OnChanges {
  @Input() typePreview: TypePreview = TypePreview.Viewown;
  id: string = '';
  detailPost: IPostDataDetail = new CPostDataDetail();
  form: FormGroup = this.fb.group({
    phonenumber: ['', [Validators.required]],
    address: [''],
    fullName: ['', [Validators.required]],
    email: ['', [Validators.email]],
    note: [''],
    concernName: [this.detailPost.postName],
    concernImage: [this.detailPost.images],
    status: [0],
    type: ['Post'],
  });
  isLogin: boolean = false;
  memberId: string = '';
  constructor(
    private route: ActivatedRoute,
    private webService: WebsiteService,
    private commonService: CommonService,
    private authService: AuthService,
    private fb: FormBuilder,
    private noti: NotiService,
    private concernService: ConcernService,
    private oAuthService: OAuthService
  ) {}

  ngOnInit(): void {
    this.checkIsLogin();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.typePreview === TypePreview.Viewown) {
      this.id = this.route.snapshot.params['id'];
    } else {
      this.id = localStorage.getItem(KeyStorage.post_detail_id)!;
    }
    this.getDetailPost();
  }

  checkIsLogin() {
    this.authService.isAuthenticated$.subscribe((data) => {
      this.isLogin = data;
    });
  }

  getDetailPost() {
    this.webService.getDetailPost(this.id).subscribe((data) => {
      this.detailPost = data;
      this.form.patchValue({
        concernName: this.detailPost.postName,
        concernImage: this.detailPost.images,
      })
    });
  }

  qrShare() {}

  share() {}

  concern() {
    this.commonService.scrollToBottom();
  }

  async sendConcern() {
    // logged in
    if (this.isLogin) {
      // get memberId
      await this.oAuthService.loadUserProfile().then((profile: any) => {
        this.memberId = profile.info.sub;
      });
      let body: any = this.form.value;
      body.type = ECategoryType.Post;
      body.memberId = this.memberId;
      body.concernId = localStorage.getItem(KeyStorage.post_detail_id);
      this.concernService.updateConcern(body).subscribe(
        (data) => {
          this.noti.success('Gửi quan tâm thành công');
        },
        (error) => {
          this.noti.warning();
        }
      );
    }
    // dont login
    else {
      this.noti.warning('Bạn cần đăng nhập để gửi quan tâm bài viết này');
    }
  }

  login() {
    this.authService.login();
  }
}
