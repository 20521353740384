import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NotiService } from '../../services/noti.service';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit, AfterViewInit {
  @Input() value: string = '';
  @Input() title: string = '';
  linkQr: string = '';
  blobQr!: Blob;
  qrBase64: string = '';
  constructor(private noti: NotiService) {}

  ngOnInit(): void {
    // window.location.href = dataUrl;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let canvasRef: HTMLCanvasElement = document.querySelector('canvas')!;
      this.qrBase64 = canvasRef.toDataURL('image/png');
      this.linkQr = this.qrBase64;
    });
  }
  share() {
    let blob = this.base64ToBlob(this.qrBase64);
    let file: File = new File([blob], 'Qrcode', { type: 'image/png' });
    if (navigator.canShare({ files: [file] })) {
      navigator
        .share({
          files: [file],
        })
        .catch((error) => {
          this.noti.warning(
            'Tính năng chia sẻ file không hỗ trợ trên trình duyệt của bạn'
          );
        });
    }
  }

  base64ToBlob(base64Image: string): Blob {
    // Split the base64-encoded image string into two parts
    const parts = base64Image.split(';base64,');

    // Extract the MIME type and base64-encoded data from the string
    const mimeType = parts[0].split(':')[1];
    const data = parts[1];

    // Convert the base64-encoded data to a binary string
    const binaryString = window.atob(data);

    // Create a new ArrayBuffer object with the binary string data
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    // Create a new Blob object with the ArrayBuffer data and MIME type
    const blob = new Blob([arrayBuffer], { type: 'image/png' });
    return blob;
  }
}
