<nz-skeleton [nzActive]="true" [nzLoading]="isLoading"></nz-skeleton>
<div *ngIf="!isLoading" class="text-center w-full">
  <!------ has many images ------>
  <div
    *ngIf="item.listImage && item.listImage!.length > 1"
    id="image-{{ id }}"
    [ngStyle]="{
      'background-image':
        item.backgroundImage && !item.backgroundColor
          ? 'url(' + item.backgroundImage + ')'
          : '',
      'background-color': item.backgroundColor ? item.backgroundColor : '',
      'padding-top': item.alignmentTop ? item.alignmentTop + 'px' : '0px',
      'padding-bottom': item.alignmentBottom
        ? item.alignmentBottom + 'px'
        : '0px',
      'padding-right': item.alignmentRight ? item.alignmentRight + '%' : '0px',
      'padding-left': item.alignmentLeft ? item.alignmentLeft + '%' : '0px'
    }"
  >
    <!-- <app-carousel
      class="flex justify-center"
      [images]="item.listImage"
      [isDisablePreview]="true"
    ></app-carousel> -->
    <swiper
      direction="horizontal"
      [loop]="true"
      [autoplay]="{ delay: 1000, disableOnInteraction: false }"
      [pagination]="{ clickable: true }"
      [navigation]="true"
    >
      <ng-template *ngFor="let item of item.listImage" swiperSlide>
        <img class="w-full" [src]="item" alt="" />
      </ng-template>
    </swiper>
  </div>
  <!-- has 1 image -->
  <ng-container *ngIf="item.listImage && item.listImage!.length == 1">
    <div
      id="image-{{ id }}"
      [ngStyle]="{
        'background-image':
          item.backgroundImage && !item.backgroundColor
            ? 'url(' + item.backgroundImage + ')'
            : '',
        'background-color': item.backgroundColor ? item.backgroundColor : '',
        'padding-top': item.alignmentTop ? item.alignmentTop + 'px' : '0px',
        'padding-bottom': item.alignmentBottom
          ? item.alignmentBottom + 'px'
          : '0px',
        'padding-right': item.alignmentRight
          ? item.alignmentRight + '%'
          : '0px',
        'padding-left': item.alignmentLeft ? item.alignmentLeft + '%' : '0px'
      }"
    >
      <img
        nz-image
        [nzSrc]="item.listImage![0]"
        nzDisablePreview="true"
        class="w-full"
      />
    </div>
  </ng-container>
</div>
