import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { TypeFooter, TypeFooterString } from '../../../enums/typeFooter.enum';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NotiService } from 'src/app/core/services/noti.service';

@Component({
  selector: 'app-edit-alignment',
  templateUrl: './edit-alignment.component.html',
  styleUrls: ['./edit-alignment.component.scss'],
})
export class EditAlignmentComponent implements OnInit {
  @Input() typeFooter!: TypeFooter;
  @Input() idSelected!: string;
  item: any;
  formatterPercent = (value: number): string => `${value} %`;
  formatterPixel = (value: number): string => `${value} px`;
  left: number = 0;
  right: number = 0;
  top: number = 0;
  bottom: number = 0;
  constructor(
    private commonService: CommonService,
    private drawRef: NzDrawerRef,
    private landingService: LandingPageService,
    private loadingService: LoadingService,
    private noti: NotiService
  ) {}

  ngOnInit(): void {
    switch (this.typeFooter) {
      case TypeFooter.Action: {
        this.getDetailAction();
        break;
      }
      case TypeFooter.Content: {
        this.getDetailContent();
        break;
      }
      case TypeFooter.Link: {
        this.getDetailLink();
        break;
      }
      case TypeFooter.Image: {
        this.getDetailImage();
        break;
      }
      case TypeFooter.Button: {
        this.getDetailButton();
        break;
      }
    }
  }
  // ---- get detail ----
  getDetailAction() {
    this.landingService.getDetailAction(this.idSelected).subscribe((data) => {
      this.patchValue(data, data);
    });
  }

  getDetailContent() {
    this.landingService.getDetailContent(this.idSelected).subscribe((data) => {
      this.patchValue(data, data);
    });
  }

  getDetailLink() {
    this.landingService
      .getDetailConnective(this.idSelected)
      .subscribe((data) => {
        this.patchValue(data, data[0]);
      });
  }

  getDetailImage() {
    this.landingService.getDetailImage(this.idSelected).subscribe((data) => {
      this.patchValue(data, data[0]);
    });
  }

  getDetailButton() {
    this.landingService.getDetailButton(this.idSelected).subscribe((data) => {
      this.patchValue(data, data[0]);
    });
  }
  // ----end get detail ----
  // ----update----
  update() {
    switch (this.typeFooter) {
      case TypeFooter.Action: {
        this.updateAction();
        break;
      }
      case TypeFooter.Content: {
        this.updateContent();
        break;
      }
      case TypeFooter.Link: {
        this.updateLink();
        break;
      }
      case TypeFooter.Image: {
        this.updateImage();
        break;
      }
      case TypeFooter.Button: {
        this.updateButton();
        break;
      }
    }
  }

  updateAction() {
    this.loadingService.openLoadingModal();
    let body = {
      id: this.item.id,
      landingID: this.idSelected,
      alignmentRight: this.right,
      alignmentLeft: this.left,
      alignmentTop: this.top,
      alignmentBottom: this.bottom,
      title: this.item.title,
      actionInfor: this.item.actionInfor,
      background: this.item.background,
      backgroundColor: this.item.backgroundColor,
      isParallax: this.item.isParallax,
      actions: this.item.actions,
    };
    this.landingService.updateAction(body).subscribe(
      (data) => {
        this.successUpdate();
      },
      (error) => {
        this.noti.warning();
      }
    );
  }

  updateContent() {
    this.loadingService.openLoadingModal();
    let body = {
      id: this.item.id,
      landingID: this.idSelected,
      alignmentRight: this.right,
      alignmentLeft: this.left,
      alignmentTop: this.top,
      alignmentBottom: this.bottom,
    };
    this.landingService.updateContent(body).subscribe(
      (data) => {
        this.successUpdate();
      },
      (error) => {
        this.noti.warning();
      }
    );
  }

  updateLink() {
    this.loadingService.openLoadingModal();
    let body = {
      id: this.item[0].id,
      landingID: this.idSelected,
      value: this.item[0].value,
      title: this.item[0].title,
      alignmentRight: this.right,
      alignmentLeft: this.left,
      alignmentTop: this.top,
      alignmentBottom: this.bottom,
    };
    this.landingService.updateConnective(body).subscribe(
      (data) => {
        this.successUpdate();
      },
      (error) => {
        this.noti.warning();
      }
    );
  }

  updateImage() {
    this.loadingService.openLoadingModal();
    let body = {
      landingID: this.idSelected,
      backgroundColor: this.item[0].backgroundColor,
      backgroundImage: this.item[0].backgroundImage,
      value: this.item.map((each: any) => {
        return each.value;
      }),
      alignmentRight: this.right,
      alignmentLeft: this.left,
      alignmentTop: this.top,
      alignmentBottom: this.bottom,
    };
    this.landingService.updateListImage(body).subscribe(
      (data) => {
        this.successUpdate();
      },
      (error) => {
        this.noti.warning();
      }
    );
  }

  updateButton() {
    this.loadingService.openLoadingModal();
    let body = {
      id: this.item[0].id,
      landingID: this.idSelected,
      alignmentRight: this.right,
      alignmentLeft: this.left,
      alignmentTop: this.top,
      alignmentBottom: this.bottom,
      height: this.item[0].height,
    };
    this.landingService.updateButton(body).subscribe(
      (data) => {
        this.successUpdate();
      },
      (error) => {
        this.noti.warning();
      }
    );
  }
  //---end update----
  //---- common ----
  patchValue(item: any, dataPatchValue: any) {
    this.item = item;
    this.top = dataPatchValue.alignmentTop | 0;
    this.bottom = dataPatchValue.alignmentBottom | 0;
    this.right = dataPatchValue.alignmentRight | 0;
    this.left = dataPatchValue.alignmentLeft | 0;
  }

  alignmentRight(event: any) {
    this.alignment('right', event);
  }

  alignmentLeft(event: any) {
    this.alignment('left', event);
  }

  alignmentTop(event: any) {
    this.alignment('top', event);
  }

  alignmentBottom(event: any) {
    this.alignment('bottom', event);
  }

  alignment(side: 'right' | 'left' | 'top' | 'bottom', number: number) {
    let typeItem: string = '';
    switch (this.typeFooter) {
      case TypeFooter.Action: {
        typeItem = TypeFooterString.Action;
        break;
      }
      case TypeFooter.Button: {
        typeItem = TypeFooterString.Button;
        break;
      }
      case TypeFooter.Content: {
        typeItem = TypeFooterString.Content;
        break;
      }
      case TypeFooter.Image: {
        typeItem = TypeFooterString.Image;
        break;
      }
      case TypeFooter.Link: {
        typeItem = TypeFooterString.Link;
        break;
      }
    }
    this.commonService.elementRef$.subscribe((ele: HTMLElement) => {
      let rootElement = ele.querySelector(
        `#${typeItem}-${this.idSelected}`
      ) as HTMLElement;
      if (!rootElement) return;
      switch (side) {
        case 'right': {
          rootElement.style.paddingRight = `${number}%`;
          break;
        }
        case 'left': {
          rootElement.style.paddingLeft = `${number}%`;
          break;
        }
        case 'top': {
          rootElement.style.paddingTop = `${number}px`;
          break;
        }
        case 'bottom': {
          rootElement.style.paddingBottom = `${number}px`;
          break;
        }
      }
    });
  }

  cancel() {
    this.drawRef.close(false);
  }

  successUpdate() {
    // this.noti.success();
    this.drawRef.close(true);
    this.loadingService.closeLoadingModal();
  }
  // ---- end common ----
}
