import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { KeyStorage } from 'src/app/core/enums';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { TypeComponent } from '../../../enums/typeComponents.enum';
import { ItemType, TypeLink } from '../../../models/screenEdit';
import { ConnectiveService } from '../../../services/connective.service';
import { HelperService } from 'src/app/core/services/helper.service';

@Component({
  selector: 'app-edit-type-link',
  templateUrl: './edit-type-link.component.html',
  styleUrls: ['./edit-type-link.component.scss'],
})
export class EditTypeLinkComponent implements OnInit {
  @Input() type: TypeComponent = TypeComponent.Create;
  @Input() idItemSelected: string = '';
  item: TypeLink = new TypeLink();

  TypeComponent = TypeComponent;
  itemEdited: TypeLink = new TypeLink();
  safeSrc: SafeResourceUrl = '';
  form: FormGroup = this.fb.group({
    label: [''],
    value: ['', [Validators.required]],
  });
  constructor(
    private sanitizer: DomSanitizer,
    private draw: NzDrawerRef,
    private fb: FormBuilder,
    private landingService: LandingPageService,
    private noti: NotiService,
    private connectiveService: ConnectiveService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    if (this.type === TypeComponent.Edit && this.idItemSelected) {
      this.getDetail();
    }
  }

  getDetail() {
    this.landingService
      .getDetailConnective(this.idItemSelected)
      .subscribe((data) => {
        if (!data) return;
        this.item = {
          id: data[0].id,
          label: data[0].title,
          value: data[0].value,
        };
        // ---------- patch value ---------
        this.form.patchValue({
          label: this.item.label,
          value: this.item.value,
        });
        // console.log(this.item);
        this.getEmbedSrc();
      });
  }

  getEmbedSrc() {
    // this.safeSrc = this.connectiveService.getSafeSrc(this.item.value!);
    this.safeSrc = this.helperService.getEmbeddedLink(this.item.value!, true);
    console.log(this.safeSrc);
  }

  changeLink() {
    this.safeSrc = this.helperService.getEmbeddedLink(
      this.form.get('value')?.value,
      true
    );
    console.log(this.form.get('value')?.value);
  }

  cancel() {
    this.draw.close();
  }
  update() {
    let body = {
      id: this.item.id,
      landingID: this.idItemSelected,
      value: this.form.get('value')?.value,
      title: this.form.get('label')?.value,
    };
    this.landingService.updateConnective(body).subscribe(
      (next) => {
        if (!next) return;
        this.noti.success();
        this.draw.close(true);
      },
      (error) => {
        this.noti.warning();
      }
    );
  }

  create() {
    this.landingService
      .addItem({
        categoryID: localStorage.getItem(KeyStorage.cetagory_id),
        type: ItemType.connective,
        priority: localStorage.getItem(KeyStorage.priority),
      })
      .subscribe(
        (next) => {
          if (!next) return;
          this.landingService
            .addConnective({
              landingID: next.id,
              value: this.form.get('value')?.value,
              title: this.form.get('label')?.value,
            })
            .subscribe(
              (next) => {
                this.noti.success('Thêm mới liên kết thành công');
                this.emitSuccess();
              },
              (err) => {
                this.noti.warning();
              }
            );
        },
        (error) => {
          this.noti.warning();
        }
      );
  }

  emitSuccess() {
    this.draw.close(true);
  }
}
