import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { KeyStorage } from 'src/app/core/enums';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { TypeComponent } from '../../../enums/typeComponents.enum';
import { ItemType, TypeButton } from '../../../models/screenEdit';
import { ButtonDefaultValue } from 'src/app/core/enums/landing.enum';
@Component({
  selector: 'app-edit-type-button',
  templateUrl: './edit-type-button.component.html',
  styleUrls: ['./edit-type-button.component.scss'],
})
export class EditTypeButtonComponent implements OnInit {
  @Input() type: TypeComponent = TypeComponent.Create;
  @Input() idItemSelected: string = '';
  formatterPercent = (value: number): string => `${value} %`;
  formatterPixel = (value: number): string => `${value} px`;

  TypeComponent = TypeComponent;
  item: TypeButton = new TypeButton();
  form: FormGroup = this.fb.group({
    buttonText: ['Nhập nội dung nút bấm', [Validators.required]],
    bgColor: ['#0eaf8f'],
    textColor: ['#FFFFFF'],
  });
  borderRadius: number = 0;
  height: number = 40;
  fontSize: number = 14;
  constructor(
    private fb: FormBuilder,
    private ref: NzDrawerRef,
    private landingService: LandingPageService,
    private noti: NotiService
  ) {}

  ngOnInit(): void {
    if (this.type === TypeComponent.Edit && this.idItemSelected) {
      this.getDetailAndPatchValue();
    }
  }
  getDetailAndPatchValue() {
    this.landingService
      .getDetailButton(this.idItemSelected)
      .subscribe((data) => {
        if (!data) return;
        let button = data[0];
        this.item = {
          id: button.id,
          buttonText: button.value,
          bgColor: button.backgroundColor,
          textColor: button.textColor,
          borderRadius: button.border,
          type: ItemType.button,
          typeFunctionBtn: Number(button.type),
          link: button.link,
          height: button.height || ButtonDefaultValue.height,
          fontSize: button.fontSize || ButtonDefaultValue.fontSize,
        };
        this.borderRadius = this.item.borderRadius!;
        this.height = this.item.height!;
        this.fontSize = this.item.fontSize!;
        this.form.patchValue({
          buttonText: this.item.buttonText,
          bgColor: this.item.bgColor,
          textColor: this.item.textColor,
        });
      });
  }

  update() {
    let body: any = {
      value: this.form.get('buttonText')?.value,
      border: this.borderRadius,
      textColor: this.form.get('textColor')?.value,
      backgroundColor: this.form.get('bgColor')?.value,
      id: this.item.id,
      landingID: this.idItemSelected,
      height: this.height || ButtonDefaultValue.height,
      fontSize: this.fontSize || ButtonDefaultValue.fontSize,
    };
    this.landingService.updateButton(body).subscribe(
      (data) => {
        this.noti.success();
        this.ref.close(true);
      },
      (error) => {
        this.noti.warning();
      }
    );
  }

  cancel() {
    this.ref.close();
  }

  create() {
    let body: any = {
      value: this.form.get('buttonText')?.value,
      border: this.borderRadius,
      textColor: this.form.get('textColor')?.value,
      backgroundColor: this.form.get('bgColor')?.value,
      alignmentRight: 10, // mặc định
      alignmentLeft: 10, // mặc định
      height: this.height || ButtonDefaultValue.height,
      fontSize: this.fontSize || ButtonDefaultValue.fontSize,
    };
    this.landingService
      .addItem({
        categoryID: localStorage.getItem(KeyStorage.cetagory_id),
        type: ItemType.button,
        priority: localStorage.getItem(KeyStorage.priority),
      })
      .subscribe(
        (next) => {
          if (!next) return;
          body.landingID = next.id;
          this.landingService.addButton(body).subscribe(
            (data) => {
              this.noti.success('Thêm mới nút bấm thành công');
              this.ref.close(true);
            },
            (error) => {
              this.noti.warning();
            }
          );
        },
        (error) => {
          this.noti.warning();
        }
      );
  }

  setDefault() {
    this.form.patchValue({
      bgColor: ButtonDefaultValue.bgColor,
      textColor: ButtonDefaultValue.textColor,
    });
    this.borderRadius = ButtonDefaultValue.borderRadius;
    this.fontSize = ButtonDefaultValue.fontSize;
    this.height = ButtonDefaultValue.height;
  }
}
