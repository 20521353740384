import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { TypeUpload } from 'src/app/core/ui/upload-image/upload-image.component';
import { Position } from '../../enums/changePosition.enum';
import { TypeComponent } from '../../enums/typeComponents.enum';
import { TypeFooter } from '../../enums/typeFooter.enum';
import { TypeImage } from '../../models/screenEdit';
import { CommonService } from '../../services/common.service';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { EditTypeActionComponent } from '../edit-components/edit-type-action/edit-type-action.component';
import { EditTypeContentComponent } from '../edit-components/edit-type-content/edit-type-content.component';
import { EditTypeImageComponent } from '../edit-components/edit-type-image/edit-type-image.component';
import { EditTypeLinkComponent } from '../edit-components/edit-type-link/edit-type-link.component';
import { EditTypeButtonComponent } from '../edit-components/edit-type-button/edit-type-button.component';
import { EditFunctionBtnComponent } from '../edit-components/edit-function-btn/edit-function-btn.component';
import { EditAlignmentComponent } from '../edit-components/edit-alignment/edit-alignment.component';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-edit-footer',
  templateUrl: './edit-footer.component.html',
  styleUrls: ['./edit-footer.component.scss'],
  animations: [
    trigger('slide', [
      transition('void => *', [
        style({
          transform: 'translateY(100%)',
          opacity: 0,
        }),
        animate('500ms ease-in-out'),
      ]),
      transition('* => void', [
        style({
          opacity: 0,
        }),
      ]),
    ]),
  ],
})
export class EditFooterComponent implements OnInit, OnChanges {
  @ViewChild('footerElement') footerElement!: ElementRef;
  @Input() typeFooter: TypeFooter = TypeFooter.None;
  @Input() data: any = '';
  @Input() idSelected: string = '';
  @Output() isSave$: EventEmitter<any> = new EventEmitter(); // Emit item after edit
  @Input()
  @Output()
  isDelete$: EventEmitter<boolean> = new EventEmitter();
  @Output() changePosition$: EventEmitter<Position> = new EventEmitter();
  @Output() saveChangePosition$: EventEmitter<boolean> = new EventEmitter();
  @Output() isSelectParallax$: EventEmitter<boolean> = new EventEmitter();
  TypeFooter = TypeFooter;
  isChangePosition: boolean = false;
  // IMAGE TYPE
  TypeUpload = TypeUpload;
  isVisibleEditImage: boolean = false;
  imageAdd: string = '';
  listImageAdd: any[] = [];
  dataImageCurrent: TypeImage = new TypeImage();
  listImageDelete: any[] = [];
  // CONTENT + ACTION TYPE
  isChangeCover: boolean = false;
  constructor(
    private modal: NzModalService,
    private drawService: NzDrawerService,
    private landingService: LandingPageService,
    private noti: NotiService,
    private commonService: CommonService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (this.typeFooter === TypeFooter.None) {
      this.isChangeCover = false;
      this.isChangePosition = false;
    }
    this.isChangeCover = false;
  }

  ngOnInit(): void {}

  // ---------Common----------
  reset() {
    this.noti.success();
    this.typeFooter = TypeFooter.None;
    this.isChangeCover = false;
    this.refresh();
  }
  removeComponent() {
    this.modal.confirm({
      nzTitle: 'Xóa thành phần landingpage',
      nzContent:
        'Nếu bạn xóa thì thành phần này sẽ biến mất. Bạn có chắc chắn muốn xóa?',
      nzCentered: true,
      nzClassName: 'responsive-modal',
      nzOnOk: () => {
        this.isDelete$.emit(true);
      },
    });
  }
  up() {
    this.changePosition$.emit(Position.Up);
  }
  down() {
    this.changePosition$.emit(Position.Down);
  }
  upToLine() {
    this.changePosition$.emit(Position.Uptoline);
  }
  downToLine() {
    this.changePosition$.emit(Position.Downtoline);
  }
  refresh() {
    this.isSave$.emit(true);
  }
  changePosition() {
    this.isChangePosition = false;
    this.saveChangePosition$.emit(true);
  }
  cancelChangePosition() {
    this.isChangePosition = false;
    this.saveChangePosition$.emit(false);
  }
  parallax() {
    this.isSelectParallax$.emit(true);
  }
  alignment() {
    this.commonService.scrollElementWhenAligment();
    setTimeout(() => {
      let drawer = this.drawService.create({
        nzContent: EditAlignmentComponent,
        nzTitle: 'Căn lề',
        nzFooter: undefined,
        nzWrapClassName: 'responsive-modal',
        nzPlacement: 'bottom',
        nzMask: false,
        nzHeight: '350px',
        nzContentParams: {
          typeFooter: this.typeFooter,
          idSelected: this.idSelected,
        },
      });
      drawer.afterClose.subscribe((data) => {
        if (data) {
          this.reset();
        } else {
          this.typeFooter = TypeFooter.None;
          this.isChangeCover = false;
          this.refresh();
        }
      });
    }, 500);
  }
  changeCover() {
    this.isChangeCover = true;
  }
  scrollToTop() {
    this.commonService.scrollToTop();
  }

  left() {
    if (this.footerElement.nativeElement.scrollLeft > 0) {
      this.footerElement.nativeElement.scrollLeft -= 20;
    }
  }

  right() {
    if (
      this.footerElement.nativeElement.scrollLeft <
      this.footerElement.nativeElement.scrollWidth -
        this.footerElement.nativeElement.clientWidth
    ) {
      this.footerElement.nativeElement.scrollLeft += 20;
    }
  }
  // -------------End Common------------
  // --------Type Action + Type Content-------
  changeCoverImage(e: any, isReset: boolean) {
    let bg = '';
    if (isReset === false) {
      bg = e;
    }
    // console.log(isReset);
    // Xét type
    switch (this.typeFooter) {
      case TypeFooter.Content: {
        this.changeCoverTypeContent(bg);
        break;
      }
      case TypeFooter.Action: {
        this.changeCoverTypeAction(bg);
        break;
      }
      case TypeFooter.Button: {
        this.changeCoverTypeButton(bg);
        break;
      }
      case TypeFooter.Image: {
        this.changeCoverTypeImage(bg);
        break;
      }
      case TypeFooter.Link: {
        this.changeCoverTypeLink(bg);
        break;
      }
    }
  }

  changeCoverTypeContent(bg: string) {
    this.landingService.getDetailContent(this.idSelected).subscribe(
      (next) => {
        let body = {
          id: next.id,
          landingID: this.idSelected,
          background: bg,
          backgroundColor: '',
        };
        this.landingService.updateContent(body).subscribe(
          (next) => {
            this.reset();
          },
          (error) => {
            this.noti.warning();
          }
        );
        return;
      },
      (error) => {
        this.noti.warning();
      }
    );
  }

  changeCoverTypeAction(bg: string) {
    this.landingService.getDetailAction(this.idSelected).subscribe(
      (next) => {
        let body = {
          id: next.id,
          landingID: this.idSelected,
          actions: next.actions,
          title: next.title,
          actionInfor: next.actionInfor,
          background: bg,
          backgroundColor: '',
        };
        this.landingService.updateAction(body).subscribe(
          (next) => {
            this.reset();
          },
          (error) => {
            this.noti.warning();
          }
        );
        return;
      },
      (error) => {
        this.noti.warning();
      }
    );
  }

  changeCoverTypeButton(bg: string) {
    this.landingService.getDetailButton(this.idSelected).subscribe((data) => {
      let body = {
        id: data[0].id,
        landingID: this.idSelected,
        backgroundImageButton: bg,
        backgroundColorButton: '',
        height: data[0].height,
      };
      this.landingService.updateButton(body).subscribe(
        (next) => {
          this.reset();
        },
        (error) => {
          this.noti.warning();
        }
      );
    });
  }

  changeCoverTypeImage(bg: string) {
    this.landingService.getDetailImage(this.idSelected).subscribe((data) => {
      let body = {
        landingID: this.idSelected,
        backgroundImage: bg,
        backgroundColor: '',
        value: data.map((item: any) => {
          return item.value;
        }),
      };
      this.landingService.updateListImage(body).subscribe(
        (data) => {
          this.reset();
        },
        (error) => {
          this.noti.warning();
        }
      );
    });
  }

  changeCoverTypeLink(bg: string) {
    this.landingService
      .getDetailConnective(this.idSelected)
      .subscribe((data) => {
        let body = {
          landingID: this.idSelected,
          backgroundImage: bg,
          backgroundColor: '',
          id: data[0].id,
        };
        this.landingService.updateConnective(body).subscribe(
          (data) => {
            this.reset();
          },
          (error) => {
            this.noti.warning();
          }
        );
      });
  }

  openColorPicker() {
    let draw = this.drawService.create({
      nzTitle: 'Chọn màu nền',
      nzContent: ColorPickerComponent,
      nzHeight: '200px',
      nzPlacement: 'bottom',
      nzContentParams: {
        idSelected: this.idSelected,
        typeFooter: this.typeFooter,
      },
      nzWrapClassName: 'responsive-modal',
    });
    draw.afterClose.subscribe((isSave) => {
      if (!isSave) return;
      this.refresh();
    });
  }

  // -------------End--------------

  // ----------Type Image------------
  editImage() {
    // this.isVisibleEditImage = true;
    let draw = this.drawService.create({
      nzTitle: 'Chỉnh sửa hình ảnh',
      nzContent: EditTypeImageComponent,
      nzHeight: '500px',
      nzPlacement: 'bottom',
      nzWrapClassName: 'responsive-modal',
      nzContentParams: {
        idSelected: this.idSelected,
        type: TypeComponent.Edit,
      },
    });
    draw.afterClose.subscribe((data) => {
      if (!data) return;
      this.refresh();
    });
  }
  addNewImageToList($event: any) {
    let body = {
      landingID: this.idSelected,
      value: $event,
    };
    this.landingService.addOneImage(body).subscribe(
      (next) => {
        this.noti.success();
        this.refresh();
      },
      (error) => {
        this.noti.warning();
      }
    );
    // Đợi api
    // this.landingService.add()
  }
  // ----------End------------

  // ----------Type Content------------
  editContent() {
    let draw = this.drawService.create({
      nzTitle: 'Chỉnh sửa nội dung ',
      nzContent: EditTypeContentComponent,
      nzWrapClassName: 'responsive-modal',
      nzHeight: '500px',
      nzPlacement: 'bottom',
      nzContentParams: {
        type: TypeComponent.Edit,
        // item: this.data,
        idSelected: this.idSelected,
      },
    });
    draw.afterClose.subscribe((isSave: boolean) => {
      if (!isSave) return;
      // this.data.text = data.content;
      this.refresh();
    });
  }

  // ----------End------------

  // ----------Type Action------------
  editAction() {
    let draw = this.drawService.create({
      nzTitle: 'Chỉnh sửa nội dung ',
      nzContent: EditTypeActionComponent,
      nzWrapClassName: 'responsive-modal',
      nzHeight: '500px',
      nzPlacement: 'bottom',
      nzContentParams: {
        idItemSelected: this.idSelected,
        type: TypeComponent.Edit,
      },
    });
    draw.afterClose.subscribe((data: boolean) => {
      if (!data) return;
      this.refresh();
    });
  }
  // ----------End------------

  //  ----------Type Link------------
  editLink() {
    let draw = this.drawService.create({
      nzTitle: 'Chỉnh sửa video ',
      nzContent: EditTypeLinkComponent,
      nzWrapClassName: 'responsive-modal',
      nzHeight: '500px',
      nzPlacement: 'bottom',
      nzContentParams: {
        idItemSelected: this.idSelected,
        type: TypeComponent.Edit,
      },
    });
    draw.afterClose.subscribe((data: boolean) => {
      if (!data) return;
      this.refresh();
    });
  }
  // ----------End------------

  //  ----------Type Button------------
  editButton() {
    let draw = this.drawService.create({
      nzTitle: 'Chỉnh sửa nút bấm ',
      nzContent: EditTypeButtonComponent,
      nzWrapClassName: 'responsive-modal',
      nzHeight: '600px',
      nzPlacement: 'bottom',
      nzContentParams: {
        idItemSelected: this.idSelected,
        type: TypeComponent.Edit,
      },
    });
    draw.afterClose.subscribe((data: boolean) => {
      if (!data) return;
      this.refresh();
    });
  }

  editFeatureBtn() {
    let draw = this.drawService.create({
      nzTitle: 'Chức năng của nút bấm',
      nzContent: EditFunctionBtnComponent,
      nzWrapClassName: 'responsive-modal',
      nzHeight: '550px',
      nzPlacement: 'bottom',
      nzContentParams: {
        idItemSelected: this.idSelected,
        type: TypeComponent.Edit,
      },
    });
    draw.afterClose.subscribe((data: boolean) => {
      if (!data) return;
      this.refresh();
    });
  }

  // ----------End------------
}

export class IsSaveTypeImage {
  isSave?: boolean;
  add: any;
  remove: any;
  type?: TypeFooter;
}
