import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import {
  listActionsOptionsEnum,
  ResponseAction,
  TypeAction,
  TypeItem,
} from '../../../models/screenEdit';
import { ActionService } from '../../../services/action.service';
import { ConcernService } from 'src/app/core/services/concern.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { KeyStorage } from 'src/app/core/enums';

@Component({
  selector: 'app-type-action',
  templateUrl: './type-action.component.html',
  styleUrls: ['./type-action.component.scss'],
})
export class TypeActionComponent implements OnInit, OnChanges {
  @Input() id: string = '';
  @Input() isIntersection: boolean = false;
  @Input() isFromButtonDialog: boolean = false;
  @Input() dataFromButtonDialog: any;
  isLoading: boolean = true;
  item: TypeAction = new TypeAction();
  TypeItem = TypeItem;
  formSelect: FormGroup = this.fb.group({
    listOption: [],
  });
  isDropdownOpen: boolean = false;
  listActionsOptionsEnum = listActionsOptionsEnum;
  bg: string = '';

  public form = new FormGroup({
    memberId: new FormControl(
      localStorage.getItem(KeyStorage.memberId_view)
        ? localStorage.getItem(KeyStorage.memberId_view)
        : localStorage.getItem(KeyStorage.userId)
    ),
    status: new FormControl(0),
    concernImage: new FormControl(),
    fullName: new FormControl(null, Validators.required),
    phonenumber: new FormControl(null, Validators.required),
    address: new FormControl(),
    email: new FormControl(),
    note: new FormControl(),
  });

  constructor(
    private fb: FormBuilder,
    private landingService: LandingPageService,
    private actionService: ActionService,
    private concernService: ConcernService,
    private noti: NotiService
  ) {}

  ngOnInit(): void {
    // nếu từ button dialog
    if (this.isFromButtonDialog) {
      this.patchValueFromButtonDialog();
      this.isLoading = false;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    // nếu kp từ button -> call api bình thường
    if (this.id && this.isIntersection && !this.isFromButtonDialog) {
      this.getDetail();
    }
  }

  getDetail() {
    this.landingService
      .getDetailAction(this.id)
      .subscribe((next: ResponseAction) => {
        if (!next) return;
        this.item = this.actionService.mapResponseToView(next);
        this.form.patchValue({
          concernImage: (this.item.bgImage !== null && this.item.bgImage !== undefined ) ? [this.item.bgImage] : [],
        });
        this.isLoading = false;
      });
  }

  ok() {
    this.isDropdownOpen = false;
  }
  cancel() {}

  patchValueFromButtonDialog() {
    this.dataFromButtonDialog.alignmentRight = 0;
    this.dataFromButtonDialog.alignmentLeft = 0;
    this.dataFromButtonDialog.alignmentBottom = 0;
    this.dataFromButtonDialog.alignmentTop = 0;
    this.item = this.actionService.mapResponseToView(this.dataFromButtonDialog);
  }

  createConcern(concern: any) {
    if (this.form.valid) {
      this.concernService.updateConcern(concern).subscribe((data) => {
        this.noti.success('Đã gửi thông tin');
      }),
        () => {
          this.noti.error('Gửi thông tin thất bại!');
        };
    } else {
      this.noti.warning('Để lại thông tin bắt buộc!');
    }
  }
}
