<div class="grid grid-cols-2 gap-3 mt-2 px-3">
  <ng-container *ngFor="let post of detailPost">
    <ng-container *ngIf="typePreview === TypePreview.Home">
      <app-box-view-post
        [post]="post"
        [typePreview]="typePreview"
        [keyWebsite]="keyWebsite"
      >
      </app-box-view-post>
    </ng-container>
    <ng-container *ngIf="typePreview != TypePreview.Home">
      <app-box-view-post
        *ngIf="!post.hidden"
        [post]="post"
        [typePreview]="typePreview"
        [keyWebsite]="keyWebsite"
      >
      </app-box-view-post>
    </ng-container>
  </ng-container>
</div>
