export enum ItemType {
  image,
  content,
  connective,
  action,
  button,
}

export enum TypeItem {
  EDIT,
  VIEW,
}

export class TypeImage {
  listImage?: any[] = [];
  type?: ItemType = ItemType.image;
  typeItem?: TypeItem = TypeItem.VIEW;
  backgroundImage?: string;
  backgroundColor?: string = '#00000';
  alignmentRight?: number;
  alignmentLeft?: number;
  alignmentTop?: number;
  alignmentBottom?: number;
}
export class TypeButton {
  id?: string;
  buttonText?: string;
  bgColor?: string = '##0eaf8f';
  textColor?: string = '#FFFFFF';
  borderRadius?: number;
  type?: ItemType = ItemType.button;
  landingID?: string;
  actionID?: string;
  typeFunctionBtn?: ButtonType | null;
  height?: number;
  fontSize?: number;
  font?: number;
  backgroundImageButton?: string;
  backgroundColorButton?: string;
  alignmentRight?: number;
  alignmentLeft?: number;
  alignmentTop?: number;
  alignmentBottom?: number;
  // --- link extra ---
  link?: string;
  // ---dialog---
  title?: string;
  actionInfor?: string;
  actions?: string[] = [];
  backgroundAction?: string;
  backgroundColorAction?: string;
}
export class TypeContent {
  id?: string;
  title?: string;
  type?: ItemType = ItemType.content;
  text?: any;
  typeItem?: TypeItem = TypeItem.VIEW;
  bgImage?: string;
  backgroundColor?: string;
  alignmentRight?: number;
  alignmentLeft?: number;
  alignmentTop?: number;
  alignmentBottom?: number;
}
export class TypeAction {
  id?: string;
  content?: string;
  type?: ItemType = ItemType.action;
  btnActionText?: string;
  bgImage?: string;
  name?: OptionAction;
  email?: OptionAction;
  phone?: OptionAction;
  address?: OptionAction;
  birthday?: OptionAction;
  note?: OptionAction;
  backgroundColor?: string;
  alignmentRight?: number;
  alignmentLeft?: number;
  alignmentTop?: number;
  alignmentBottom?: number;
  boderadious?: number;
  isParallax?: boolean;
}

export class OptionAction {
  isShow?: boolean;
  value?: string;
}

export class TypeLink {
  id?: string;
  label?: string;
  type?: ItemType = ItemType.connective;
  value?: string;
  backgroundColor?: string;
  backgroundImage?: string;
  alignmentRight?: number;
  alignmentLeft?: number;
  alignmentTop?: number;
  alignmentBottom?: number;
}

export enum listActionsOptionsEnum {
  Fullname,
  Email,
  Phone,
  Address,
  Birthday,
  Note,
}

export class ResponseAction {
  id?: string;
  actionInfor?: string;
  title?: string;
  actions?: string[];
  background?: string;
  backgroundColor?: string;
  alignmentRight?: number;
  alignmentLeft?: number;
  alignmentTop?: number;
  alignmentBottom?: number;
}

export class BodyUpdateAction {
  id?: string;
  landingID?: string;
  title?: string;
  actionInfor?: string;
  actions?: string[];
}

export class Option {
  label!: string;
  value!: listActionsOptionsEnum;
}

export enum ButtonType {
  scrolltocta,
  opendialog,
  linkextra,
}
