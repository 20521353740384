<form [formGroup]="form" (ngSubmit)="submit()">
  <div>
    <label for="name">* Tên danh mục</label>
    <input
      nz-input
      type="text"
      name=""
      id="name"
      formControlName="categoryName"
      maxlength="50"
      placeholder="Nhập tên danh mục"
    />
    <ng-container
      *ngIf="categoryNameControl.invalid && categoryNameControl.touched"
    >
      <div class="text-red-text">
        <p *ngIf="categoryNameControl?.errors?.['maxlength']">
          Tên danh mục tối đa 50 ký tự
        </p>
      </div>
    </ng-container>
  </div>
  <div class="mt-3 w-full">
    <label for="">* Loại danh mục</label>
    <nz-select class="w-full" formControlName="type">
      <nz-option
        *ngFor="let item of categoryTypes"
        [nzValue]="item.value"
        [nzLabel]="item.label"
      ></nz-option>
    </nz-select>
  </div>
  <div class="flex mt-3">
    <label class="mr-2">Hiển thị: </label>
    <app-switch (toggleSwitch)="toggleSwitch($event)"></app-switch>
  </div>
  <div class="grid grid-cols-2 gap-x-5 font-semibold mt-4">
    <button
      type="button"
      class="button button-outline-disable"
      (click)="close()"
    >
      Hủy
    </button>
    <button type="submit" class="button button-success">Lưu</button>
  </div>
</form>
