import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { TypeItem, TypeLink } from '../../../models/screenEdit';
import { HelperService } from 'src/app/core/services/helper.service';

@Component({
  selector: 'app-type-link',
  templateUrl: './type-link.component.html',
  styleUrls: ['./type-link.component.scss'],
})
export class TypeLinkComponent implements OnInit, OnChanges {
  @Input() id: string = '';
  @Input() isIntersection: boolean = false;
  isLoading: boolean = true;
  item: TypeLink = new TypeLink();
  TypeItem = TypeItem;
  safeSrc!: SafeResourceUrl;
  constructor(
    private sanitizer: DomSanitizer,
    private landingService: LandingPageService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    if (this.item.value) {
      this.getSafeSrc();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id && this.isIntersection) {
      this.getDetail();
    }
  }

  getDetail() {
    this.landingService.getDetailConnective(this.id).subscribe((data) => {
      if (!data) return;
      let item = data[0];
      let backgroundColor = item.backgroundColor ? item.backgroundColor : '';
      let backgroundImage = item.backgroundImage ? item.backgroundImage : '';
      this.item = {
        id: item.id,
        label: item.title,
        value: item.value,
        backgroundColor: backgroundColor,
        backgroundImage: backgroundImage,
        alignmentBottom: item.alignmentBottom || 0,
        alignmentTop: item.alignmentTop || 0,
        alignmentRight: item.alignmentRight || 0,
        alignmentLeft: item.alignmentLeft || 0,
      };
      this.getSafeSrc();
      this.isLoading = false;
    });
  }
  getId(url: any) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  getSafeSrc() {
    this.safeSrc = this.helperService.getEmbeddedLink(this.item.value, true);
    // this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
    //   '//www.youtube.com/embed/' + this.getId(this.item.value)
    // );
  }

  changeLink() {
    this.getSafeSrc();
  }
}
