import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export interface IDataRoute {
  navbar: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  public isShowNavbar$ = new BehaviorSubject<boolean>(true);
  public isShowNavbar_current = this.isShowNavbar$.asObservable();

  constructor(private route: ActivatedRoute, private router: Router) {}

  public setStatusShowNavbar(navbar: IDataRoute) {
    if (navbar) {
      this.isShowNavbar$.next(navbar?.navbar);
    } else {
      this.isShowNavbar$.next(true);
    }
  }

  public hideNavbar() {
    this.isShowNavbar$.next(false);
  }
}
