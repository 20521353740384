import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { ItemType, TypeContent, TypeItem } from '../../../models/screenEdit';
@Component({
  selector: 'app-type-content',
  templateUrl: './type-content.component.html',
  styleUrls: ['./type-content.component.scss'],
})
export class TypeContentComponent implements OnInit, OnChanges {
  @Input() id: string = '';
  @Input() isIntersection: boolean = false;
  JSON = JSON;
  @Input() item: TypeContent = new TypeContent();
  @Input() isCallAPI: boolean = true;
  TypeItem = TypeItem;
  editorForm: FormGroup = this.fb.group({
    quill: [''],
  });
  isLoading: boolean = true;
  content: any = '';
  whiteListFont = ['TimesNewRoman', 'CourierNew', 'Caveat'];
  editorModule = {
    toolbar: [
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: 1 }, { header: 2 }],
      ['bold', 'italic', 'underline'],
      [{ align: [] }],
      ['image'],
      [{ color: [] }],
      [
        {
          font: this.whiteListFont,
        },
      ],
    ],
  };
  constructor(
    private fb: FormBuilder,
    private landingService: LandingPageService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id && this.isIntersection) {
      this.getDetail();
    }
    if (!this.isCallAPI) {
      this.isLoading = false;
    }
  }

  getDetail() {
    this.landingService.getDetailContent(this.id).subscribe((data) => {
      if (!data) return;
      this.item = {
        id: data.id,
        type: ItemType.content,
        text: data.value,
        bgImage: data.background,
        backgroundColor: data.backgroundColor,
        alignmentBottom: data.alignmentBottom || 0,
        alignmentTop: data.alignmentTop || 0,
        alignmentRight: data.alignmentRight || 0,
        alignmentLeft: data.alignmentLeft || 0,
      };
      this.isLoading = false;
    });
  }

  changedEditor($event: EditorChangeContent | EditorChangeSelection | null) {}
}
