import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './page-header/page-header.component';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ResizeImageComponent } from './resize-image/resize-image.component';
import { NotiService } from '../services/noti.service';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FooterButtonComponent } from './footer-button/footer-button.component';
import { SwitchComponent } from './switch/switch.component';
import { EditorComponent } from './editor/editor.component';
import { QuillModule } from 'ngx-quill';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { CarouselComponent } from './carousel/carousel.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzImageModule } from 'ng-zorro-antd/image';
import { VideoComponent } from './video/video.component';
import { TagsComponent } from './tags/tags.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { SafePipe } from '../pipes/safe.pipe';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { QrCodeModule } from 'ng-qrcode';
import { LoadingComponent } from './loading/loading.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { PageHeaderWebminiComponent } from './webmini/page-header-webmini/page-header-webmini.component';
import { TitleHeaderWebminiComponent } from './webmini/title-header-webmini/title-header-webmini.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    PageHeaderComponent,
    UploadImageComponent,
    ResizeImageComponent,
    FooterButtonComponent,
    SwitchComponent,
    EditorComponent,
    CarouselComponent,
    VideoComponent,
    TagsComponent,
    SafePipe,
    QrCodeComponent,
    LoadingComponent,
    SkeletonComponent,
    PageHeaderWebminiComponent,
    TitleHeaderWebminiComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NzPageHeaderModule,
    NzUploadModule,
    NzIconModule,
    NzModalModule,
    ImageCropperModule,
    NzMessageModule,
    QuillModule.forRoot(),
    NzSwitchModule,
    NzCarouselModule,
    NzImageModule,
    NzTagModule,
    QrCodeModule,
    NzSpinModule,
    NzSkeletonModule,
    NzDropDownModule,
    SwiperModule,
  ],
  exports: [
    PageHeaderComponent,
    UploadImageComponent,
    ResizeImageComponent,
    FooterButtonComponent,
    SwitchComponent,
    EditorComponent,
    CarouselComponent,
    LoadingComponent,
    VideoComponent,
    TagsComponent,
    QrCodeModule,
    NzSkeletonModule,
    SkeletonComponent,
    PageHeaderWebminiComponent,
    SafePipe,
    TitleHeaderWebminiComponent,
    SwiperModule,
  ],
  providers: [NotiService],
})
export class UiModule {}
