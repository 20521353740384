import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {

  @Input() size: 'small' | 'default' = 'small'
  @Input() switch = true;
  @Input() disable = false;
  @Input() loading = false;
  @Output() toggleSwitch =  new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  changeSwitch(e:any){
    this.toggleSwitch.emit(e);
  }

}
