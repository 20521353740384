export enum ActionKey {
  save = 'save',
  view = 'view',
  share = 'share',
  shareQr = 'shareQr',
  arrange = 'arrange',
  push = 'push'
}

export enum KeyInfoUser {
  nbf = 'nbf',
  exp = 'exp',
  iss = 'iss',
  aud = 'aud',
  iat = 'iat',
  at_hash = 'at_hash',
  sub = 'sub',
  auth_time = 'auth_time',
  idp = 'idp',
  preferred_username = 'preferred_username',
  name = 'name',
  email = 'email',
  email_verified = 'email_verified',
  user_name = 'name',
}

export enum KeyStorage {
  // -----------User Info------------
  username_view = 'username_view',
  memberId_view = 'memberId_view',
  username = 'username',
  userId = 'userId',
  id_token_claims_obj = 'id_token_claims_obj',
  user_info_obj_tc = 'user_info_obj_tc',
  //------------End User Info------------
  //------------Post-----------------
  post_detail_id = 'post_detail_id',
  //------------End post--------------
  //------------Landing Page-------------
  website_id = 'website_id',
  subTab_id = 'subTab_id',
  landingpage_type = 'landingpage_type',
  cetagory_id = 'cetagory_id',
  priority = 'priority',
  from = 'from',
  //------------End Landing Page---------
  // ---------- License ---------
  license_id = 'license_id',
  // ----------End License ---------
  //------------Common----------------
  category_code = 'category_code',
  is_login = 'is_login',
  //------------End Common----------------
  //----------Website------------
  link_view_website = 'link_view_website',
  website_code = 'website_code',
  //----------End Website------------
  //----------Home Screen--------------
  index_tab_slide_to = 'index_tab_slide_to',
  //----------End Home Screen--------------
}

export enum EActionRoute {
  VIEW = 'view',
  UPDATE = 'update',
  CREATE = 'create',
}
