import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { KeyStorage } from 'src/app/core/enums';
import { tenantAgency } from 'src/app/core/enums/landing.enum';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { TypeCol } from 'src/app/core/ui/upload-image/upload-image.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-push-warehouse',
  templateUrl: './push-warehouse.component.html',
  styleUrls: ['./push-warehouse.component.scss'],
})
export class PushWarehouseComponent implements OnInit {
  public typeCol = TypeCol.col1;
  public tenantAgency = tenantAgency;
  public listCate = [];

  public form = new FormGroup({
    cateId: new FormControl(''),
    createdBy: new FormControl(''),
    name: new FormControl(''),
    agency: new FormControl(''),
    image: new FormControl(''),
    category: new FormControl(''),
    ctaLink: new FormControl(''),
    description: new FormControl(''),
  });

  constructor(
    private landingPageService: LandingPageService,
    private notiService: NotiService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private modalRef: NzModalRef
  ) {}

  ngOnInit() {
    this.getAllCate();
    this.form.patchValue({
      cateId: localStorage.getItem(KeyStorage.cetagory_id),
      createdBy: localStorage.getItem(KeyStorage.username),
      ctaLink:
        environment.CTA_URL + localStorage.getItem(KeyStorage.category_code),
    });
  }

  getAllCate() {
    this.landingPageService.getAllCate('').subscribe((data) => {
      this.listCate = data.listCate;
    });
  }

  uploadImages($event: any) {
    this.form.patchValue({
      image: $event[0],
    });
  }

  cancel() {
    this.modalRef.close();
  }

  push() {
    this.landingPageService.createLibrary(this.form.getRawValue()).subscribe(
      (data) => {
        this.notiService.success('Tải lên thành công');
        this.modalRef.close();
        this.cdr.detectChanges();
      },
      (err) => {
        this.notiService.error('Tải lên thất bại');
      }
    );
  }
}
