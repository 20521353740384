import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { KeyStorage } from 'src/app/core/enums';
import { RoleCopy } from 'src/app/core/enums/landing.enum';
import { ICategoryDetail } from 'src/app/core/interfaces';
import {
  IMemberCopy,
  IReqSearchMemberLicence,
} from 'src/app/core/interfaces/landingPage.interface';
import { CategoryService } from 'src/app/core/services/category.service';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { NotiService } from 'src/app/core/services/noti.service';

@Component({
  selector: 'app-set-role-copy',
  templateUrl: './set-role-copy.component.html',
  styleUrls: ['./set-role-copy.component.scss'],
})
export class SetRoleCopyComponent implements OnInit {
  public radioValue: any;
  public val1 = RoleCopy.anyOne;
  public val2 = RoleCopy.onlyMe;
  public val3 = RoleCopy.custom;
  public cateDetail: any = {};
  public form = new FormGroup({
    username: new FormControl(),
  });
  public listMember: any = [];
  public listMemberCopy: any = [];
  public isLoadingMemberSearch: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private categoryService: CategoryService,
    private landingPageService: LandingPageService,
    private notiService: NotiService,
    private modalRef: NzModalRef
  ) {}

  ngOnInit() {
    this.getCateDetail();
  }

  getCateDetail() {
    const cateId = localStorage.getItem(KeyStorage.cetagory_id)!;
    this.categoryService.detail(cateId).subscribe(
      (data) => {
        this.cateDetail = data;
        this.radioValue = data.status;
        if (data.memberCoppy !== null) {
          this.listMemberCopy = data.memberCoppy;
        }
        this.cdr.detectChanges();
      },
      (err) => {
        this.notiService.error();
      }
    );
  }

  updateRole() {
    const body: ICategoryDetail = this.cateDetail;
    body.status = this.radioValue;
    if (this.radioValue === this.val3) {
      body.memberCoppy = this.listMemberCopy;
    }
    this.categoryService.update(body).subscribe(
      (data) => {
        this.notiService.success();
        this.cdr.detectChanges();
        this.modalRef.close();
      },
      (err) => {
        this.notiService.error();
      }
    );
  }

  cancel() {
    this.modalRef.close();
  }

  searchMember() {
    this.isLoadingMemberSearch = true;
    const body: IReqSearchMemberLicence = {
      search: {
        username: this.form.get('username')?.value,
      },
      page: 1,
      pageSize: 9999,
    };
    this.landingPageService.searchMemberLicense(body).subscribe(
      (data) => {
        this.listMember = data.data;
        this.isLoadingMemberSearch = false;
        this.cdr.detectChanges();
      },
      (err) => {
        this.notiService.error();
        this.isLoadingMemberSearch = false;
      }
    );
  }

  addMemberCopy(userId: string, username: string) {
    const memberInfo: IMemberCopy = {
      userId: userId,
      username: username,
    };
    const isUserInclude = this.listMemberCopy?.some(
      (itemJSON: any) => itemJSON === JSON.stringify(memberInfo)
    );

    if (isUserInclude === true) {
      this.notiService.error('Username đã tồn tại');
      return;
    }
    this.listMemberCopy.push(JSON.stringify(memberInfo));
  }

  JSONparse(obj: any): any {
    return JSON.parse(obj);
  }

  deleleMemberCopy(index: number) {
    this.listMemberCopy.splice(index, 1);
  }
}
