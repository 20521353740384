import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ActionKey } from 'src/app/core/enums';
import { IActionList } from 'src/app/core/interfaces';

@Component({
  selector: 'app-title-header-webmini',
  templateUrl: './title-header-webmini.component.html',
  styleUrls: ['./title-header-webmini.component.scss']
})
export class TitleHeaderWebminiComponent implements OnInit {
  @Input() title!: string;
  @Input() labelActionHeader!: string;
  @Input() backRoute = '';
  @Input() back = true;
  @Input() showButton = true;
  @Input() isShowActionList = true;
  @Input() actionList: IActionList[] = [
    {
      key: ActionKey.view,
      name: 'Xem chi tiết',
      condition: true,
      icon: 'fa-regular fa-eye',
    },
    {
      key: ActionKey.share,
      name: 'Chia sẻ',
      condition: true,
      icon: 'fa-solid fa-share-nodes',
    },
  ];
  @Output() actionClick = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onBack(): void {
    if (this.backRoute) {
      this.router.navigate([this.backRoute]);
    } else {
      window.history.back();
    }
  }

  emitKeyAction(action: IActionList) {
    this.actionClick.emit(action.key);
  }
}