<div [ngSwitch]="typeHeader">
  <ng-container *ngSwitchCase="ETypeHeader.view">
    <div
      class="w-full justify-between items-center bg-white text-primary font-semibold px-0 py-2 border-b border-solid border-primary relative grid grid-cols-5"
    >
      <div
        [ngStyle]="{
          'background-image':
            'url(' + 'assets/images/trueconnect-transparent.png' + ')'
        }"
        class="w-full rounded-sm aspect-video bg-center bg-cover flex-center"
      ></div>
      <p class="col-span-3 text-center overflow-hidden font-semibold text-base">
        {{ title }}
      </p>
      <div *ngIf="isShowActionList" class="flex justify-end gap-x-1 mr-1">
        <ng-container *ngFor="let action of actionList">
          <ng-container
            *ngIf="action?.dropdown; then dropdownTpl; else defaultTpl"
          ></ng-container>
          <ng-template #dropdownTpl>
            <button
              class="aspect-square w-[32px] button-outline-success flex-center"
              (click)="emitKeyAction(action)"
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="menu"
            >
              <span
                nz-icon
                [nzType]="action?.icon || ''"
                class=""
                nzTheme="outline"
              ></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li
                  nz-menu-item
                  *ngFor="let i of action?.dropdownList"
                  (click)="actionDropdown.emit(i)"
                >
                  {{ i?.nameDropdown }}
                </li>
              </ul>
            </nz-dropdown-menu>
          </ng-template>
          <ng-template #defaultTpl>
            <button
              class="aspect-square w-[32px] button-outline-success flex-center"
              (click)="emitKeyAction(action)"
            >
              <span
                nz-icon
                [nzType]="action?.icon || ''"
                class=""
                nzTheme="outline"
              ></span>
            </button>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ETypeHeader.custom">
    <div
      class="w-full justify-between items-center bg-white text-primary font-semibold px-0 py-2 border-b border-solid border-primary relative flex"
    >
      <div class="flex items-center">
        <img
          *ngIf="logoWebsite"
          [src]="logoWebsite"
          alt=""
          class="w-[45px] h-[45px] mr-3"
        />
        <div class="text-xl font-bold text-primary">{{ nameWebsite }}</div>
      </div>
      <div *ngIf="isShowActionList" class="flex justify-end gap-x-1 mr-1">
        <ng-container *ngFor="let action of actionList">
          <ng-container
            *ngIf="action?.dropdown; then dropdownTpl; else defaultTpl"
          ></ng-container>
          <ng-template #dropdownTpl>
            <button
              class="aspect-square w-[32px] button-outline-success flex-center"
              (click)="emitKeyAction(action)"
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="menu"
            >
              <span
                nz-icon
                [nzType]="action?.icon || ''"
                class=""
                nzTheme="outline"
              ></span>
            </button>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li
                  nz-menu-item
                  *ngFor="let i of action?.dropdownList"
                  (click)="actionDropdown.emit(i)"
                >
                  {{ i?.nameDropdown }}
                </li>
              </ul>
            </nz-dropdown-menu>
          </ng-template>
          <ng-template #defaultTpl>
            <button
              class="aspect-square w-[32px] button-outline-success flex-center"
              (click)="emitKeyAction(action)"
            >
              <span
                nz-icon
                [nzType]="action?.icon || ''"
                class=""
                nzTheme="outline"
              ></span>
            </button>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div
      class="w-full justify-between items-center bg-white text-white px-0 py-2 border-b border-solid border-primary"
    >
      <img
        src="assets/images/trueconnect-transparent.png"
        class="h-10"
        alt=""
      />
    </div>
  </ng-container>
  <div
    *ngIf="isShowSubHeader"
    class="flex items-center justify-between py-2 px-3 border-b-gray-300 border-b-[1px] border-solid"
  >
    <i (click)="onBack()" class="fa-solid fa-arrow-left text-lg"></i>
    <div class="text-lg font-semibold w-full text-center">
      {{ subHeaderTitle }}
    </div>
  </div>
</div>
