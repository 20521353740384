<div class="mb-10">
  <div>Căn lề phải</div>
  <div class="flex items-center justify-between">
    <nz-slider
      class="slider grow"
      [nzMin]="0"
      [nzMax]="100"
      [ngModel]="right"
      (ngModelChange)="right = $event; alignmentRight($event)"
    ></nz-slider>
    <nz-input-number
      [nzMin]="0"
      [nzMax]="100"
      [ngStyle]="{ marginLeft: '16px' }"
      [nzFormatter]="formatterPercent"
      [ngModel]="right"
      (ngModelChange)="right = $event; alignmentRight($event)"
    ></nz-input-number>
  </div>
  <div>Căn lề trái</div>
  <div class="flex items-center justify-between">
    <nz-slider
      class="slider grow"
      [nzMin]="0"
      [nzMax]="100"
      [ngModel]="left"
      (ngModelChange)="left = $event; alignmentLeft($event)"
    ></nz-slider>
    <nz-input-number
      [nzMin]="0"
      [nzMax]="100"
      [ngStyle]="{ marginLeft: '16px' }"
      [nzFormatter]="formatterPercent"
      [ngModel]="left"
      (ngModelChange)="left = $event; alignmentLeft($event)"
    ></nz-input-number>
  </div>
  <div>Căn lề trên</div>
  <div class="flex items-center justify-between">
    <nz-slider
      class="slider grow"
      [nzMin]="0"
      [nzMax]="100"
      [ngModel]="top"
      (ngModelChange)="top = $event; alignmentTop($event)"
    ></nz-slider>
    <nz-input-number
      [nzMin]="0"
      [nzMax]="100"
      [ngStyle]="{ marginLeft: '16px' }"
      [nzFormatter]="formatterPixel"
      [ngModel]="top"
      (ngModelChange)="top = $event; alignmentTop($event)"
    ></nz-input-number>
  </div>
  <div>Căn lề dưới</div>
  <div class="flex items-center justify-between">
    <nz-slider
      class="slider grow"
      [nzMin]="0"
      [nzMax]="100"
      [ngModel]="bottom"
      (ngModelChange)="bottom = $event; alignmentBottom($event)"
    ></nz-slider>
    <nz-input-number
      [nzMin]="0"
      [nzMax]="100"
      [ngStyle]="{ marginLeft: '16px' }"
      [nzFormatter]="formatterPixel"
      [ngModel]="bottom"
      (ngModelChange)="bottom = $event; alignmentBottom($event)"
    ></nz-input-number>
  </div>
</div>
<!-- footer -->
<div class="absolute bottom-0 left-0 right-0 bg-white">
  <div class="flex items-center justify-between px-5 py-2">
    <button
      class="button-outline-default grow hover:opacity-70 mr-4"
      (click)="cancel()"
    >
      Hủy
    </button>

    <button class="button-success grow hover:opacity-70" (click)="update()">
      Cập nhật
    </button>
  </div>
</div>
