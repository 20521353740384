import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ConnectiveService {
  constructor(private sanitizer: DomSanitizer) {}
  getSafeSrc(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      '//www.youtube.com/embed/' + this.getId(url)
    );
  }

  getId(url: any) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  }
}
