import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { BehaviorSubject } from 'rxjs';
import { ActionKey, KeyStorage } from 'src/app/core/enums';
import { LandingScreenType } from 'src/app/core/enums/landing.enum';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { QrService } from 'src/app/core/services/qr.service';
import { ShareLinkService } from 'src/app/core/services/shareLink.service';
import { TypeUpload } from 'src/app/core/ui/upload-image/upload-image.component';
import { Position } from '../../enums/changePosition.enum';
import { TypeComponent } from '../../enums/typeComponents.enum';
import { TypeFooter } from '../../enums/typeFooter.enum';
import { ItemType } from '../../models/screenEdit';
import { CommonService } from '../../services/common.service';
import {
  EditTypeActionComponent,
  IsSaveAction,
} from '../edit-components/edit-type-action/edit-type-action.component';
import { EditTypeButtonComponent } from '../edit-components/edit-type-button/edit-type-button.component';
import { EditTypeContentComponent } from '../edit-components/edit-type-content/edit-type-content.component';
import { EditTypeLinkComponent } from '../edit-components/edit-type-link/edit-type-link.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-container',
  templateUrl: './edit-container.component.html',
  styleUrls: ['./edit-container.component.scss'],
})
export class EditContainerComponent implements OnInit, AfterViewInit {
  @ViewChild('CTAElement', { read: ElementRef, static: false })
  ctaElement!: ElementRef;
  @ViewChild('elementTop', { read: ElementRef, static: false })
  elementTop!: HTMLDivElement;
  cateId: string = '';
  pageHeaderIcons = [
    {
      key: ActionKey.push,
      name: 'Chia sẻ',
      condition: true,
      icon: 'fa-solid fa-paint-roller',
    },
    {
      key: ActionKey.view,
      name: 'Xem chi tiết',
      condition: true,
      icon: 'fa-regular fa-eye',
    },
    {
      key: ActionKey.share,
      name: 'Chia sẻ',
      condition: true,
      icon: 'fa-solid fa-share-nodes',
    },
    {
      key: ActionKey.shareQr,
      name: 'Chia sẻ Qr',
      condition: true,
      icon: 'fa-solid fa-qrcode',
    },
  ];
  isScrollTop: boolean = false;
  // ---- Observer item to call api -------
  listObserver: any;
  isCompleteCallAPISource = new BehaviorSubject<boolean>(false);
  isCompleteCallAPI$ = this.isCompleteCallAPISource.asObservable();
  // --------------------------------------
  // ----- Screen type : Edit or Create -----
  screenType: LandingScreenType = LandingScreenType.CREATE;
  createType = LandingScreenType.CREATE;
  LandingScreenType = LandingScreenType;
  // ---------------------------------------
  // ----- Item Type: image, content, action, connective, button ----
  ItemType = ItemType;
  // --------------------------------------------------------
  // ----- modal add component -----
  isVisible: boolean = false;
  modalAdd = {
    position: 'fixed',
    left: '50%',
    top: '70%',
    transform: 'translate(-50%, -50%)',
  };
  modalBody = {
    height: '250px',
  };
  isHasCTA: boolean = false;
  // ------------------------------------------
  // ---- footer  ----
  TypeFooter = TypeFooter;
  data: any; // data pass to footer
  idSelected: string = ''; // id landingID pass to footer
  currentTypeFooter: TypeFooter = TypeFooter.None;
  // -------------------------------------------
  listAllItems: any[] = [];
  isNewComplete: boolean = false;
  TypeUpload = TypeUpload;
  constructor(
    private router: Router,
    private drawerService: NzDrawerService,
    private landingService: LandingPageService,
    private noti: NotiService,
    private commonService: CommonService,
    private shareLinkSer: ShareLinkService,
    private qrService: QrService,
    private navigationService: NavigationService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    // hide navbar
    this.navigationService.hideNavbar();
    // lấy ra xem là đang tạo hay đang edit
    this.screenType = JSON.parse(
      localStorage.getItem(KeyStorage.landingpage_type)!
    );
    // lấy ra cateID
    this.cateId = localStorage.getItem(KeyStorage.cetagory_id)!;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isCompleteCallAPI$.subscribe((data) => {
        if (!data) return;
        // handle
        this.handleIntersection();
      });
      // nếu là type edit -> patch value
      if (this.screenType === LandingScreenType.EDIT) {
        this.getDetail();
      }
    }, 0);
  }

  // ---------------- COMMON ----------------
  shareLink() {
    this.shareLinkSer.shareLink(
      environment.CTA_URL + localStorage.getItem(KeyStorage.category_code)!
    );
  }

  shareQr() {
    this.qrService.openQr(
      environment.CTA_URL + localStorage.getItem(KeyStorage.category_code)!,
      'QR Code Landing Page'
    );
  }

  openDrawerAdd() {
    this.isHasCTA = this.checkIsHasCTA();
    this.isVisible = true;
  }

  checkIsHasCTA(): boolean {
    let isHas: boolean = false;
    if (!this.listAllItems) return false;
    this.listAllItems.forEach((item: any) => {
      if (item.type === ItemType.action) {
        isHas = true;
      }
    });
    return isHas;
  }

  handleIntersection() {
    setTimeout(() => {
      this.listObserver = document.querySelectorAll('.container');
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let itemId = entry.target.id;
            let item = this.listAllItems.find((item) => item.id === itemId);
            let index = this.listAllItems.indexOf(item);
            item.isIntersection = true;
            if (index > 0) {
              let itemPrevious = this.listAllItems[index - 1];
              if (!itemPrevious) return;
              itemPrevious.isIntersection = true;
            }
            if (index < this.listAllItems.length) {
              let itemNext = this.listAllItems[index + 1];
              if (!itemNext) return;
              itemNext.isIntersection = true;
            }
          }
        });
      });
      this.listObserver.forEach((item: any) => {
        observer.observe(item);
      });
    });
  }

  previewLandingPage() {
    this.router.navigate(['/pages/landing-page/preview']);
  }

  getDetail() {
    if (!this.cateId) return;
    this.loadingService.openLoadingModal();
    return new Promise((resolve, reject) => {
      this.landingService.getDetail(this.cateId).subscribe((data) => {
        if (data) {
          // Set để không hiển thị ảnh
          this.listAllItems = data;
          // set để ẩn hết button add
          if (!this.listAllItems) return;
          this.listAllItems.forEach((item) => {
            item.isHideBtnAdd = true;
          });
          this.isNewComplete = this.listAllItems.length === 0 ? true : false;
          // emit complete = true to push list item to observer
          this.isCompleteCallAPISource.next(true);
          this.currentTypeFooter = TypeFooter.None;
          // loading
          this.loadingService.closeLoadingModal();
          // detectChanges
          this.cdr.detectChanges();
          // resolve
          resolve(true);
        }
      });
    });
  }

  setPriority(currentIndex: number) {
    // if has any item
    if (currentIndex != undefined) {
      localStorage.setItem(KeyStorage.priority, String(currentIndex + 2));
      return;
    }
    // if dont have any item
    localStorage.setItem(KeyStorage.priority, String(1));
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  hideDrawerAdd() {
    this.isVisible = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listAllItems, event.previousIndex, event.currentIndex);
  }

  changeTypeFooter(type: TypeFooter, item: any, $event: any, index: number) {
    this.currentTypeFooter = type;
    this.idSelected = item.id;
    this.focus($event, index, item);
    let elementClicked = $event.target.closest('.container');
    if (!elementClicked) return;
    this.commonService.setElementRefSource(elementClicked); // set element ref source
  }

  focus($event: any, index: number, item: any) {
    // change outline
    let containerList = document.querySelectorAll('.container');
    let container = $event.target.closest('.container');
    containerList.forEach((element) => {
      element.classList.remove('focus');
      element.classList.add('normal');
    });
    container.classList.remove('normal');
    container.classList.add('focus');
    // toggle button add
    this.listAllItems.forEach((item) => (item.isHideBtnAdd = true));
    item.isHideBtnAdd = false;
    if (index === 0) {
      return;
    }
    let previousItem = this.listAllItems[index - 1];
    previousItem.isHideBtnAdd = false;
  }

  removeFocus() {
    let containerList = document.querySelectorAll('.container');
    containerList.forEach((element) => {
      element.classList.remove('focus');
      element.classList.add('normal');
    });
  }

  deleteComponent(isDelete: any) {
    if (isDelete) {
      // this.listAllItems.splice(this.listAllItems.indexOf(this.data), 1);
      this.landingService.delete(this.idSelected).subscribe(
        (next) => {
          this.noti.success('Xóa thành công');
          // -------- set type footer = null to hide this ------
          this.currentTypeFooter = TypeFooter.None;
          this.getDetail();
        },
        (error) => {
          this.noti.warning();
        }
      );
    }
  }

  emitKey(key: ActionKey) {
    switch (key) {
      case ActionKey.view: {
        this.router.navigate(['/pages/landing-page/preview', this.cateId]);
        break;
      }
      case ActionKey.share: {
        this.shareLink();
        break;
      }
      case ActionKey.shareQr: {
        this.shareQr();
        break;
      }
    }
  }

  scrollToTop() {
    this.commonService.scrollToTop();
  }
  // ---------------- END COMMON --------------

  // ---------------- ADD TYPE -------------
  addContent() {
    this.isVisible = false;
    let draw = this.drawerService.create({
      nzTitle: 'Thêm mới nội dung',
      nzContent: EditTypeContentComponent,
      nzWrapClassName: 'responsive-modal',
      nzHeight: '500px',
      nzPlacement: 'bottom',
    });
    draw.afterClose.subscribe((isSave: boolean) => {
      if (!isSave) return;
      this.focusAfterAdd();
    });
  }

  addImage(e: any) {
    this.isNewComplete = false;
    let imageUpload = e;
    // ------- add landing item -------
    this.landingService
      .addItem({
        categoryID: localStorage.getItem(KeyStorage.cetagory_id),
        type: ItemType.image,
        priority: localStorage.getItem(KeyStorage.priority),
      })
      .subscribe(
        (data) => {
          if (!data) return;
          this.landingService
            .addListImage({
              landingID: data.id,
              value: [imageUpload],
            })
            .subscribe(
              (data) => {
                if (!data) return;
                this.noti.success('Thêm mới danh sách hình ảnh thành công');
                this.hideDrawerAdd();
                this.focusAfterAdd();
              },
              (err) => {
                this.noti.warning();
              }
            );
        },
        (err) => {
          this.noti.warning();
        }
      );
  }

  addAction() {
    this.isVisible = false;
    let draw = this.drawerService.create({
      nzTitle: 'Thêm mới lời kêu gọi hành động',
      nzContent: EditTypeActionComponent,
      nzWrapClassName: 'responsive-modal',
      nzHeight: '500px',
      nzPlacement: 'bottom',
      nzContentParams: {
        type: TypeComponent.Create,
      },
    });
    draw.afterClose.subscribe((data: IsSaveAction) => {
      if (!data) {
        return;
      }
      this.focusAfterAdd();
    });
  }

  addLink() {
    this.isVisible = false;
    let draw = this.drawerService.create({
      nzTitle: 'Thêm mới liên kết',
      nzContent: EditTypeLinkComponent,
      nzWrapClassName: 'responsive-modal',
      nzHeight: '500px',
      nzPlacement: 'bottom',
    });
    draw.afterClose.subscribe((data: boolean) => {
      if (!data) return;
      this.focusAfterAdd();
    });
  }

  addButton() {
    this.isVisible = false;
    let draw = this.drawerService.create({
      nzTitle: 'Thêm mới nút bấm',
      nzContent: EditTypeButtonComponent,
      nzWrapClassName: 'responsive-modal',
      nzHeight: '600px',
      nzPlacement: 'bottom',
      nzContentParams: {
        type: TypeComponent.Create,
      },
    });
    draw.afterClose.subscribe((data: boolean) => {
      if (!data) return;
      this.focusAfterAdd();
    });
  }

  selectParallax($event: any) {
    console.log($event);
  }

  async focusAfterAdd() {
    const value = await this.getDetail();
    if (!localStorage.getItem(KeyStorage.priority)) return;
    let index = Number(localStorage.getItem(KeyStorage.priority)) - 2;
    let containerList =
      this.elementRef.nativeElement.querySelectorAll('.container');
    let container = containerList[index];
    if (!container) return;
    container.click();
  }
  // ---------------- END ADD TYPE -------------

  // --------------- UPDATE ---------------

  // handle update or add
  handleEmitIsSave(isSave: any) {
    this.currentTypeFooter = TypeFooter.None;
    if (!isSave) return;
    this.getDetail(); // Save Success -> Call api
    // this.commonService.scrollIntoViewWhenUp();
  }
  //---------------End UPDATE --------------

  // --------Change position (swap)---------
  handleChangePosition(position: Position) {
    switch (position) {
      case Position.Up: {
        this.up();
        break;
      }
      case Position.Down: {
        this.down();
        break;
      }
      case Position.Uptoline: {
        this.upToLine();
        break;
      }
      case Position.Downtoline: {
        this.downToLine();
        break;
      }
    }
  }
  up() {
    let item = this.listAllItems.find((item) => item.id === this.idSelected);
    let index = this.listAllItems.indexOf(item);
    if (index === 0) return;
    this.listAllItems.splice(
      index - 1,
      2,
      this.listAllItems[index],
      this.listAllItems[index - 1]
    );
    this.commonService.scrollIntoViewWhenUp();
  }
  down() {
    let item = this.listAllItems.find((item) => item.id === this.idSelected);
    let index = this.listAllItems.indexOf(item);
    if (index === this.listAllItems.length - 1) return;
    this.listAllItems.splice(
      index,
      2,
      this.listAllItems[index + 1],
      this.listAllItems[index]
    );
    this.commonService.scrollIntoViewWhenDown();
  }
  upToLine() {
    let item = this.listAllItems.find((item) => item.id === this.idSelected);
    this.listAllItems.splice(this.listAllItems.indexOf(item), 1);
    this.listAllItems.unshift(item);
    this.commonService.scrollToTop();
  }
  downToLine() {
    let item = this.listAllItems.find((item) => item.id === this.idSelected);
    this.listAllItems.splice(this.listAllItems.indexOf(item), 1);
    this.listAllItems.push(item);
    this.commonService.scrollToBottom();
  }
  callApiChangePosition(isSave: any) {
    if (!isSave) {
      this.getDetail();
      return;
    }
    let body = {
      cateId: localStorage.getItem(KeyStorage.cetagory_id),
      idList: this.listAllItems.map((item: any) => {
        return item.id;
      }),
    };
    this.landingService.swapLandingItem(body).subscribe(
      (data) => {
        this.noti.success();
        this.getDetail();
      },
      (error) => {
        this.noti.warning();
      }
    );
  }
  // --------- End Change position ---------
}
