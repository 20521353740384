import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnboardingComponent } from './component/onboarding/onboarding.component';
import { authAppInitializerFactory } from './auth/auth-app-initializer.factory';
import { AuthService } from './auth/auth.service';
import {
  AuthConfig,
  OAuthModule,
  OAuthModuleConfig,
  OAuthStorage,
} from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './auth/auth-config';
import { authModuleConfig } from './auth/auth-module-config';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { UnsafePipe } from './pipes/unsafe.pipe';
import { PriceDirective } from './directives/price.directive';
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { PricePipe } from './pipes/price.pipe';

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

export function storageFactory(): OAuthStorage {
  return localStorage;
}
@NgModule({
  declarations: [
    UnsafePipe,
    PhoneNumberDirective,
    NumberOnlyDirective,
    PricePipe,
  ],
  imports: [BrowserModule, CommonModule, OAuthModule.forRoot(), SharedModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: authAppInitializerFactory,
          deps: [AuthService],
          multi: true,
        },
        { provide: AuthConfig, useValue: authCodeFlowConfig },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }
}
