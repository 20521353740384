import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  @Input() srcVideo:any = "";
  @Input() titleVideo:string | any = ''; // tiêu đề video
  @Input() descVideo:string | any = "";  // mô tả video
  @Input() videoObject:any;
  public srcVideoSafe:any = "";
  constructor( private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

}