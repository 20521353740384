export enum EndPoint {
  category = '/gateway/category',
  post = '/gateway/post',
  business = '/gateway/website',
  subtab = '/gateway/sub-tab',
  concern = '/gateway/concern',
  // ---------landing page---------------
  landingpage = '/gateway/landingpage',
  content = '/gateway/content',
  connective = '/gateway/connective',
  action = '/gateway/action',
  image = '/gateway/image',
  button = '/gateway/button',
  //----license--------
  license = '/gateway/licensing',
  payment = '/gateway/cards/payments',
  //-----member--------
  member = '/gateway/member',
  //-----library-------
  library = '/gateway/landingPage/library'
}
