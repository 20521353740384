<div
  class="w-full justify-between items-center text-primary relative grid grid-cols-5"
>
  <div>
    <button class="p-0 w-8 aspect-square rounded-sm bg-secondary-bg" *ngIf="back" (click)="onBack()">
      <i class="fa-solid fa-angle-left"></i>
    </button>
  </div>
  <p class="col-span-3 text-center overflow-hidden font-bold text-base">{{ title }}</p>
  <div *ngIf="isShowActionList" class="flex justify-end gap-x-1">
    <ng-container *ngFor="let action of actionList">
      <button class="button px-1 flex items-center button-outline-success" (click)="emitKeyAction(action)">
        <i [ngClass]="action?.icon"></i>
        <span class="text-xs" [ngClass]="{'ml-1':action?.icon}" *ngIf="action?.name">{{action?.name}}</span>
      </button>
    </ng-container>
  </div>
</div>
