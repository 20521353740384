<div>
  <div *ngIf="item && item.listImage" class="grid grid-cols-2 gap-3">
    <div
      *ngFor="let image of item.listImage"
      class="relative h-[140px] bg-gray-50"
    >
      <div class="h-[140px] w-[158px] flex items-center justify-center">
        <img [src]="image" alt="" class="h-[140px] object-contain" />
      </div>
      <i
        (click)="remove(image)"
        class="fa-solid fa-circle-minus absolute right-1 top-1 text-red-500 text-xl hover:opacity-70 cursor-pointer"
      ></i>
    </div>
    <!-- <div *ngFor="let image of listImageAdd" class="relative">
      <img [src]="image" alt="" width="158px" />
      <i
        class="fa-solid fa-circle-minus absolute right-1 top-1 text-red-500 text-lg hover:opacity-70 cursor-pointer"
      ></i>
    </div> -->
    <app-upload-image
      [type]="TypeUpload.Custom"
      [index]="2"
      (imageAfterCropped)="handleEmitImageCropped($event)"
      class="h-full"
    >
      <div class="w-full h-full flex justify-center items-center">
        <i class="fa-solid fa-upload text-5xl"></i>
      </div>
    </app-upload-image>
  </div>
</div>

<!-- footer -->
<div class="absolute bottom-0 left-0 right-0 bg-white">
  <div class="flex items-center justify-between px-5 py-2">
    <button
      class="button-outline-default grow hover:opacity-70 mr-4"
      (click)="cancel()"
    >
      Hủy
    </button>
    <button
      *ngIf="type === TypeComponent.Edit"
      class="button-success grow hover:opacity-70"
      (click)="update()"
    >
      Cập nhật
    </button>
    <button
      *ngIf="type === TypeComponent.Create"
      class="button-success grow hover:opacity-70"
      (click)="create()"
    >
      Thêm mới
    </button>
  </div>
</div>
