<form [formGroup]="editorForm" class="mb-12">
  <!-- <quill-editor
    formControlName="value"
    (onContentChanged)="changeContent($event)"
    class="w-full"
    format="json"
    [modules]="editorModule"
  >
  </quill-editor> -->
  <app-editor
    [htmlText]="text"
    (changeContent)="changeText($event)"
  ></app-editor>
</form>
<!-- footer -->
<div class="absolute bottom-0 left-0 right-0 bg-white">
  <div class="flex items-center justify-between px-5 py-2">
    <button
      class="button-outline-default grow hover:opacity-70 mr-4"
      (click)="cancel()"
    >
      Hủy
    </button>
    <button
      [disabled]="editorForm.invalid"
      *ngIf="type === TypeComponent.Edit"
      class="button-success grow hover:opacity-70"
      (click)="update()"
    >
      Cập nhật
    </button>
    <button
      [disabled]="text.trim() === ''"
      *ngIf="type === TypeComponent.Create"
      class="button-success grow hover:opacity-70"
      (click)="create()"
    >
      Thêm mới
    </button>
  </div>
</div>
