import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Quill from 'quill';
import { font, fontSize } from '../../enums/quills.enum';
const FontSize = Quill.import('attributors/class/size');
const Font = Quill.import('attributors/class/font');
FontSize.whitelist = fontSize;
Font.whitelist = font;
Quill.register(FontSize, true);
Quill.register(Font, true);

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  @Input() htmlText = '...';
  @Output() changeContent: EventEmitter<any> = new EventEmitter<any>();

  public hasFocus = false;
  public subject!: string;

  atValues = [
    { id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com' },
    { id: 2, value: 'Patrik Sjölin' },
  ];
  hashValues = [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' },
  ];
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [
          {
            font: font,
          },
        ],
        [
          {
            size: fontSize,
          },
        ],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }],
        [{ align: [] }],
        [{ indent: '-1' }, { indent: '+1' }],
        // ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // ['code-block'],
        // [{ header: 1 }, { header: 2 }], // custom button values
        // [{ list: 'ordered' }, { list: 'bullet' }],
        // //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        // //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        // //[{ 'direction': 'rtl' }],                         // text direction

        // //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        // //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        // //[{ 'font': [] }],
        // //[{ 'align': [] }],

        // ['clean'], // remove formatting button

        // ['link'],
        // //['link', 'image', 'video']
      ],
      theme: 'snow',
    },

    // mention: {
    //   allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    //   mentionDenotationChars: ["@", "#"],
    //   source: (searchTerm:string, renderList:any, mentionChar:any) => {
    //     let values;

    //     if (mentionChar === "@") {
    //       values = this.atValues;
    //     } else {
    //       values = this.hashValues;
    //     }

    //     if (searchTerm.length === 0) {
    //       renderList(values, searchTerm);
    //     } else {
    //       const matches = [];
    //       for (var i = 0; i < values.length; i++)
    //         if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
    //       renderList(matches, searchTerm);
    //     }
    //   },
    // },
    // "emoji-toolbar": true,
    // "emoji-textarea": false,
    // "emoji-shortname": true,
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            // console.log("enter");
            return true;
          },
        },
      },
    },
  };

  constructor() {}
  ngOnInit(): void {}
  test = (event: any) => {
    console.log(event.keyCode);
  };

  onSelectionChanged = (event: any) => {
    if (event.oldRange == null) {
      this.onFocus();
    }
    if (event.range == null) {
      this.onBlur();
    }
  };

  onContentChanged = (event: any) => {
    this.changeContent.emit(JSON.stringify(event.content));
  };

  onFocus = () => {
    // console.log("On Focus");
  };
  onBlur = () => {
    // console.log("Blurred");
  };
}
