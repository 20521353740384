import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeyStorage } from 'src/app/core/enums/common.enum';
import { QrService } from 'src/app/core/services/qr.service';
import { ShareLinkService } from 'src/app/core/services/shareLink.service';

@Component({
  selector: 'app-preview-heading',
  templateUrl: './preview-heading.component.html',
  styleUrls: ['./preview-heading.component.scss'],
})
export class PreviewHeadingComponent implements OnInit {
  @Input() title!: string;
  @Input() routingBack!: string;
  @Input() isShowShare: boolean = false;
  @Input() link: string = '';
  constructor(
    private router: Router,
    private shareLinkSer: ShareLinkService,
    private qrService: QrService
  ) {}

  ngOnInit(): void {}

  back() {

    window.history.back();
  }

  shareLink() {
    this.shareLinkSer.shareLink(this.link);
  }
  shareQr() {
    this.qrService.openQr(this.link, 'QR Code');
  }
}
