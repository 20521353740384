import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { QuillModule } from 'ngx-quill';
import { UiModule } from 'src/app/core/ui/ui.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { SharedComponentModule } from '../../landing-page/shared/shared-component.module';
import { LandingPageViewComponent } from '../components/landing-page-view/LandingPageViewComponent';
import { BoxViewPostComponent } from '../components/post-view/box-view-post/box-view-post.component';
import { DetailPostComponent } from '../components/post-view/detail-post/detail-post.component';
import { PostViewComponent } from '../components/post-view/post-view.component';
import { PreviewContainerComponent } from '../components/preview-container/preview-container.component';
import { PreviewHeadingComponent } from '../components/preview-heading/preview-heading.component';

@NgModule({
  declarations: [
    LandingPageViewComponent,
    PreviewHeadingComponent,
    PreviewContainerComponent,
    PostViewComponent,
    BoxViewPostComponent,
    DetailPostComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedComponentModule,
    UiModule,
    // Ant design
    NzCarouselModule,
    NzImageModule,
    NzTabsModule,
    SwiperModule,
    NzSpaceModule,
    NzIconModule,
    NzInputModule,
    RouterModule,
    NzBadgeModule,
    NzDropDownModule,
    NzPopconfirmModule,
    NzCheckboxModule,
    QuillModule,
  ],
  exports: [
    LandingPageViewComponent,
    PreviewHeadingComponent,
    PreviewContainerComponent,
    PostViewComponent,
    BoxViewPostComponent,
    DetailPostComponent,
  ],
})
export class SharedPreviewModule {}
