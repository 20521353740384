import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AuthService } from 'src/app/core/auth/auth.service';
import { KeyStorage } from 'src/app/core/enums';
import { ECategoryType } from 'src/app/core/enums/category.enum';
import {
  ActionCopyLandingPage,
  CreateLandingFrom,
  LandingScreenType,
  LibraryShow,
  LibraryStatus,
} from 'src/app/core/enums/landing.enum';
import { Category } from 'src/app/core/models/Category';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { WebsiteService } from 'src/app/core/services/website.service';
import { CategoryModalCreateComponent } from 'src/app/features/category/category-modal-create/category-modal-create.component';
import {
  ILandingPageItem,
  ILibraryGetAllReq,
  ILibraryGetAllRes,
  IMemberCopy,
  IReqClone,
} from 'src/app/core/interfaces/landingPage.interface';
import { TypeLandingPage } from '../../enums/typeFooter.enum';
import { LoadingService } from 'src/app/core/services/loading.service';
import { Observable, forkJoin, switchMap, tap } from 'rxjs';
import { CategoryService } from 'src/app/core/services/category.service';
import { CommonService } from '../../services/common.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-add-landing-page',
  templateUrl: './add-landing-page.component.html',
  styleUrls: ['./add-landing-page.component.scss'],
})
export class AddLandingPageComponent implements OnInit, AfterViewInit {
  templateList: string[] = [
    './../../../../assets/images/Rectangle 1041.png',
    './../../../../assets/images/Rectangle 1042.png',
    './../../../../assets/images/Rectangle 1042.png',
    './../../../../assets/images/Rectangle 1042.png',
  ];
  userId: string = '';
  username: string = '';
  website_id: string = '';
  subTab_id: string = '';
  categoryList: Category[] = [];
  form: FormGroup = this.fb.group({
    categoryId: [undefined, [Validators.required]],
  });
  form1: FormGroup = this.fb.group({
    ctaLink: [undefined],
  });
  listTemplate: ILibraryGetAllRes[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private websiteService: WebsiteService,
    private fb: FormBuilder,
    private notiService: NotiService,
    private nzDrawerService: NzDrawerService,
    private landingPageService: LandingPageService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef,
    private categoryService: CategoryService,
    private commonService: CommonService,
    private storageService: StorageService,

  ) {}

  ngOnInit(): void {
    this.userId = localStorage.getItem(KeyStorage.userId)!;
    this.username = localStorage.getItem(KeyStorage.username)!;
    this.website_id = localStorage.getItem(KeyStorage.website_id)!;
    this.subTab_id = localStorage.getItem(KeyStorage.subTab_id)!;
    localStorage.setItem(KeyStorage.cetagory_id, '');
    this.getAllTemplate();
  }

  ngAfterViewInit(): void {
    this.getListCategoryUninitialized(this.subTab_id);
  }

  createCategory() {
    const create = this.nzDrawerService.create({
      nzContent: CategoryModalCreateComponent,
      nzPlacement: 'bottom',
      nzHeight: 300,
      nzWrapClassName: 'responsive-modal',
      nzContentParams: {
        default: ECategoryType.LandingPage,
      },
    });
    create.afterClose.subscribe((data) => {
      if (data.isSucess) {
        localStorage.setItem(KeyStorage.cetagory_id, data.addedCategoryId);
        this.getListCategoryUninitialized(this.subTab_id);
      }
    });
  }

  getListCategoryUninitialized(subtabId: string) {
    this.websiteService
      .getListCategoryUninitialized(subtabId, localStorage.getItem('username')!)
      .subscribe((data) => {
        // console.log(data);
        this.categoryList = data;
        // Patch default category
        if (localStorage.getItem(KeyStorage.cetagory_id)) {
          this.form.patchValue({
            categoryId: localStorage.getItem(KeyStorage.cetagory_id),
          });
        }
      });
  }

  detail() {
    // this.modalRef.close();
    this.router.navigate(['pages/landing-page/preview-sample']);
  }

  createNewComplete() {
    // if (this.categoryList.length === 0) {
    //   this.notiService.warning('Danh sách danh mục trống !');
    //   return;
    // }
    // if (this.form.invalid || this.form.get('categoryId')?.value === '') {
    //   this.notiService.warning('Bạn phải chọn danh mục trước khi tạo mới !');
    //   return;
    // }
     let  body= {
      subTabId: this.storageService.getLocal(KeyStorage.subTab_id),
      categoryName:  this.form.get('categoryId')?.value,
      type: "LandingPage",
      enable: true,
    }
    let categoryId = this.form.get('categoryId')?.value;
    if(!categoryId.trim()){
      return
    }
    // let category = this.categoryList.find((c) => c.id === categoryId);
    this.categoryService.create(body).subscribe({
      next: (resp: any) => {
        this.notiService.success('Tạo danh mục thành công');
       localStorage.setItem(KeyStorage.cetagory_id, resp.id);
        this.router.navigate(['/pages/landing-page/edit']);
      },
      error: (err) => {
        this.notiService.warning(err.error);
      },
    });



    // localStorage.setItem(KeyStorage.category_code, category.code);
   
  }
  apply() {
    // this.modalRef.close();
    this.router.navigate(['/pages/landing-page']);
    localStorage.setItem(
      'LandingScreenType',
      JSON.stringify(LandingScreenType.APPLY)
    );
  }

  logout() {
    this.authService.logout();
  }

  getAllTemplate() {
    const body: ILibraryGetAllReq = {
      isApprove: LibraryStatus.Approve,
      isShow: LibraryShow.Show,
    };
    this.landingPageService.getAllLibrary(body).subscribe(
      (data) => {
        this.listTemplate = data;
        this.cdr.detectChanges();
      },
      (err) => {
        this.notiService.error('');
      }
    );
  }

  getAnoId(url: string): string {
    var regex = /\/([^\/]+)$/;
    var match = url.match(regex);
    if (match) {
      return match[1];
    } else {
      return '';
    }
  }

  pasteLandingPage() {
    if (!this.form1.get('ctaLink')?.value) {
      this.notiService.error('Vui lòng nhập liên kết');
      return;
    }

    const anoId = this.getAnoId(this.form1.get('ctaLink')?.value);
    if (!anoId) {
      this.notiService.error('Vui lòng nhập liên kết hợp lệ');
      return;
    }

    const action = ActionCopyLandingPage.pasteLink;
    this.cloneLandingPage('', anoId, action);
  }

  applyTemplate(categoryCloneName: string, ctaLink: string) {
    if (!ctaLink) {
      this.notiService.error('Landingpage không tồn tại');
      return;
    }

    const anoId = this.getAnoId(ctaLink);
    if (!anoId) {
      this.notiService.error('Landingpage không tồn tại');
      return;
    }
    const action = ActionCopyLandingPage.applyTemplate;
    this.cloneLandingPage(categoryCloneName, anoId, action);
  }

  cloneLandingPage(
    categoryCloneName: string,
    categoryCode: string,
    action: number
  ) {
    this.loadingService.openLoadingModal();

    const reqClone: IReqClone = {
      category: {},
      landingPage: [],
    };
    
    reqClone.category = {
      description: '',
      categoryName: categoryCloneName,
      type: 'LandingPage',
      enable: true,
      subTabId: localStorage.getItem(KeyStorage.subTab_id) as string,
      memberCoppy: [],
    };

    this.landingPageService
      .getDetailAno(categoryCode)
      .pipe(
        switchMap((data) => {
          const observables: Observable<any>[] = [];

          if (action !== 1) {
            observables.push(
              this.categoryService.detail(data[0]?.categoryID).pipe(
                tap(
                  (cateData) => {
                    if (cateData.status === 0 || cateData.status === null) {
                      this.loadingService.closeLoadingModal();
                      throw new Error(
                        'Bạn không có quyền sao chép LandingPage này'
                      ); // Stop execution
                    } else if (cateData.status === 2) {
                      if (cateData.memberCoppy !== null) {
                        const userInfo: IMemberCopy = {
                          userId: localStorage.getItem(KeyStorage.userId)!,
                          username: localStorage.getItem(KeyStorage.username)!,
                        };
                        const isUserInclude = cateData.memberCoppy.some(
                          (itemJSON: any) =>
                            itemJSON === JSON.stringify(userInfo)
                        );

                        // console.log('userInfo', JSON.stringify(userInfo));
                        // console.log('isUserInclude', isUserInclude);
                        if (isUserInclude === false) {
                          this.loadingService.closeLoadingModal();
                          throw new Error(
                            'Bạn không có quyền sao chép LandingPage này'
                          ); // Stop execution
                        } else {
                          reqClone.category.categoryName =
                            cateData.categoryName;
                        }
                      } else {
                        throw new Error(
                          'Bạn không có quyền sao chép LandingPage này'
                        ); // Stop execution
                      }
                    } else {
                      reqClone.category.categoryName = cateData.categoryName;
                    }
                  },
                  (err) => {
                    this.loadingService.closeLoadingModal();
                    throw new Error('Sao chép landingPage thất bại'); // Stop execution
                  }
                )
              )
            );
          }

          data?.forEach((item: any, index: number) => {
            const landingPageItem: ILandingPageItem = {
              landingPage: {
                type: item.type,
                priority: index,
                title: '',
              },
            };

            switch (item.type) {
              case TypeLandingPage.Image:
                observables.push(
                  this.landingPageService.getDetailImage(item.id).pipe(
                    tap((imageData) => {
                      landingPageItem.image = imageData;
                      landingPageItem.image?.forEach((imageItem: any) => {
                        delete imageItem.id;
                      });
                    })
                  )
                );
                break;

              case TypeLandingPage.Content:
                observables.push(
                  this.landingPageService.getDetailContent(item.id).pipe(
                    tap((contentData) => {
                      landingPageItem.content = contentData;
                      delete landingPageItem.content?.id;
                    })
                  )
                );
                break;

              case TypeLandingPage.Action:
                observables.push(
                  this.landingPageService.getDetailAction(item.id).pipe(
                    tap((actionData) => {
                      landingPageItem.action = actionData;
                      delete landingPageItem.action?.id;
                    })
                  )
                );
                break;

              case TypeLandingPage.Connective:
                observables.push(
                  this.landingPageService.getDetailConnective(item.id).pipe(
                    tap((actionConnective) => {
                      landingPageItem.connective = actionConnective;
                      landingPageItem.connective?.forEach(
                        (connectiveItem: any) => {
                          delete connectiveItem.id;
                        }
                      );
                    })
                  )
                );
                break;

              case TypeLandingPage.Button:
                observables.push(
                  this.landingPageService.getDetailButton(item.id).pipe(
                    tap((buttonData) => {
                      landingPageItem.button = buttonData;
                      delete landingPageItem.button?.id;
                    })
                  )
                );
                break;
            }

            reqClone.landingPage.push(landingPageItem);
          });

          return forkJoin(observables);
        })
      )
      .subscribe(
        () => {
          this.landingPageService.cloneLandingPage(reqClone).subscribe(
            (data) => {
              this.commonService.goToEditLandingComponent(data.id);
              this.notiService.success('Sao chép landingPage thành công');
              this.loadingService.closeLoadingModal();
              this.router.navigate(['/pages/landing-page/edit']);
            },
            (err) => {
              this.notiService.error('Sao chép landingPage thất bại');
              this.loadingService.closeLoadingModal();
            }
          );
        },
        (err) => {
          this.notiService.error(err.message);
          this.loadingService.closeLoadingModal();
        }
      );
  }
}
