export enum LandingScreenType {
  CREATE,
  APPLY,
  EDIT,
}

export enum CreateLandingFrom {
  Home,
  CategoryManagement,
}

export enum ButtonDefaultValue {
  fontSize = 14,
  alignmentLeft = 10,
  alignmentRight = 10,
  height = 30,
  bgColor = '#0eaf8f',
  textColor = '#FFFFFF',
  borderRadius = 0,
}

export const tenantAgency = [
  {
    label: 'TrueConnect',
    value: 'trueconnect',
  },
  {
    label: 'An Việt Homie',
    value: 'homie',
  },
  {
    label: 'Tân Việt Books',
    value: 'tanvietbook',
  },
];

export enum ActionCopyLandingPage {
  pasteLink,
  applyTemplate,
}

export enum RoleCopy {
  onlyMe,
  anyOne,
  custom,
}

export enum LibraryStatus {
  Pending,
  Approve,
  Reject,
}

export enum LibraryShow {
  Show,
  Hidden,
}
