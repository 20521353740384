import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { KeyStorage } from 'src/app/core/enums';
import { LandingScreenType } from 'src/app/core/enums/landing.enum';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private elementRefSource = new BehaviorSubject<any>('');
  elementRef$ = this.elementRefSource.asObservable();
  constructor() {}

  goToEditLandingComponent(cateId: string) {
    localStorage.setItem(KeyStorage.cetagory_id, cateId);
    localStorage.setItem(
      KeyStorage.landingpage_type,
      JSON.stringify(LandingScreenType.EDIT)
    );
  }

  setElementRefSource(ele: any) {
    this.elementRefSource.next(ele);
  }
  scrollIntoViewWhenUp() {
    this.elementRef$.subscribe((ele) => {
      if (!ele) return;
      ele.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    });
  }
  scrollIntoViewWhenDown() {
    this.elementRef$.subscribe((ele) => {
      if (!ele) return;

      ele.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  scrollToBottom() {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  }
  scrollElementWhenAligment() {
    this.elementRef$.subscribe((ele) => {
      if (!ele) return;
      ele.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  }
}
