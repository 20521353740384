<form [formGroup]="form">
  <div class="mb-3">
    <p class="!mb-1">Tên giao diện <span class="text-danger">*</span></p>
    <input nz-input placeholder="Nhập tên giao diện" formControlName="name" />
  </div>
  <div class="mb-3">
    <p class="!mb-1">Danh mục <span class="text-danger">*</span></p>
    <nz-select formControlName="category" class="w-full" nzShowSearch nzAllowClear nzPlaceHolder="Chọn danh mục">
      <nz-option *ngFor="let item of listCate" [nzLabel]="item" [nzValue]="item"></nz-option>
    </nz-select>
  </div>
  <div class="mb-3">
    <p class="!mb-1">Tên agency <span class="text-danger">*</span></p>
    <nz-select formControlName="agency" class="w-full" nzShowSearch nzAllowClear nzPlaceHolder="Chọn agency">
      <nz-option *ngFor="let item of tenantAgency" [nzLabel]="item.label" [nzValue]="item.value"></nz-option>
    </nz-select>
  </div>
  <div class="mb-3">
    <p class="!mb-1">Mô tả <span class="text-danger">*</span></p>
    <textarea formControlName="description" nz-input placeholder="Nhập mô tả" rows="3"></textarea>
  </div>
  <div class="mb-5">
    <p class="!mb-1">Ảnh bìa <span class="text-danger">*</span></p>
    <div class="mx-auto">
      <app-upload-image [maxNumberImage]="1" [typeCol]="typeCol"
        (imageAfterCropped)="uploadImages($event)"></app-upload-image>
    </div>
  </div>
  <div class="flex items-center justify-between">
    <button (click)="cancel()" class="w-[47%] border-[1px] border-[#000] !text-[#000] text-[13px]">Hủy</button>
    <button (click)="push()" class="bg-primary text-white w-[47%] border-[1px] border-primary">Gửi</button>
  </div>
</form>