import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { TypeComponent } from '../../../enums/typeComponents.enum';
import { ButtonType, ItemType, TypeButton } from '../../../models/screenEdit';
import { TypeActionComponent } from '../../type-content-edit/type-action/type-action.component';
import { EditTypeActionComponent } from '../edit-type-action/edit-type-action.component';

@Component({
  selector: 'app-edit-function-btn',
  templateUrl: './edit-function-btn.component.html',
  styleUrls: ['./edit-function-btn.component.scss'],
})
export class EditFunctionBtnComponent implements OnInit {
  @Input() type: TypeComponent = TypeComponent.Create;
  @Input() idItemSelected: string = ''; // landingID
  ButtonType = ButtonType;
  item: TypeButton = new TypeButton();
  constructor(
    private landingService: LandingPageService,
    private noti: NotiService,
    private ref: NzDrawerRef,
    private drawService: NzDrawerService
  ) {}

  ngOnInit(): void {
    this.landingService
      .getDetailButton(this.idItemSelected)
      .subscribe((data) => {
        if (!data) return;
        let button = data[0];
        this.item = {
          id: button.id,
          buttonText: button.value,
          bgColor: button.backgroundColor,
          textColor: button.textColor,
          borderRadius: button.border,
          type: ItemType.button,
          typeFunctionBtn: button.type != null ? Number(button.type) : null,
          link: button.link,
          actionInfor: button.actionInfor,
          actions: button.actions,
          title: button.title,
          height: button.height,
        };
      });
  }

  cancel() {
    this.ref.close(true);
  }
  update() {
    let body: any = this.getBodyToCallAPI();
    console.log(body);
    this.landingService.updateButton(body).subscribe(
      (data) => {
        this.noti.success();
        this.ref.close(true);
      },
      (error) => {
        this.noti.warning();
      }
    );
  }

  editDialogAction() {
    let draw = this.drawService.create({
      nzTitle: 'Chỉnh sửa nội dung ',
      nzContent: EditTypeActionComponent,
      nzWidth: '375px',
      nzHeight: '550px',
      nzPlacement: 'bottom',
      nzContentParams: {
        // idItemSelected: this.idSelected,
        type: TypeComponent.Edit,
        isFromButtonDialog: true,
        dataFromButtonDialog: this.item,
        idItemSelected: 'Cái này bừa',
      },
    });
    draw.afterClose.subscribe((data: any) => {
      if (!data) return;
      this.item.actionInfor = data.actionInfor;
      this.item.actions = data.actions;
      this.item.title = data.title;
      this.landingService.updateButton(this.getBodyToCallAPI()).subscribe(
        (data) => {
          this.noti.success();
        },
        (error) => {
          this.noti.warning();
        }
      );
    });
  }

  getBodyToCallAPI() {
    return {
      id: this.item.id,
      landingID: this.idItemSelected,
      type: this.item.typeFunctionBtn,
      link: this.item.link,
      title: this.item.title,
      actionInfor: this.item.actionInfor,
      actions: this.item.actions,
      height: this.item.height,
    };
  }

  openDialogAction() {
    let draw = this.drawService.create({
      nzTitle: 'Xem hộp thông tin',
      nzContent: TypeActionComponent,
      nzHeight: '550px',
      nzPlacement: 'bottom',
      nzContentParams: {
        isFromButtonDialog: true,
        dataFromButtonDialog: this.item,
      },
      nzWrapClassName: 'responsive-modal',
    });
  }
}
