import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthForceLoginGuard } from './core/guards/auth-force-login.guard';
import { NotFoundComponent } from './core/component/not-found/not-found.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'pages',
    pathMatch: 'full',
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./layout/main/main.module').then((m) => m.MainModule),
    canActivate: [AuthForceLoginGuard],
  },
  // view website
  {
    path: ':key-website',
    loadChildren: () =>
      import('./features/view/view.module').then((m) => m.ViewModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
