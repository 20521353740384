import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PreviewLogicService {
  private isLoadingAgainSource = new Subject<boolean>();
  private isAddPostSuccessSource = new Subject<StatusPost>();
  private currentIndexTabSource = new Subject<number>();
  public isAddPostSuccess$ = this.isAddPostSuccessSource.asObservable();
  public isLoadingAgain$ = this.isLoadingAgainSource.asObservable();
  public currenIndexTab$ = this.currentIndexTabSource.asObservable();
  constructor() {}
  public setIsAddPostSuccessSource(value: StatusPost) {
    this.isAddPostSuccessSource.next(value);
  }
  public setIsLoadingAgainSource(value: boolean) {
    this.isLoadingAgainSource.next(value);
  }
  public setCurrentIndexTabSource(value: number) {
    this.currentIndexTabSource.next(value);
  }
}

export class StatusPost {
  isSuccess?: boolean;
  postCode?: string;
  index?: number;
}
