import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from 'src/environments/environment';
import { EndPoint } from '../enums';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  private url = `${ENV.API_URL}${EndPoint.category}`;

  constructor(
    private http:HttpClient,
    private helperService: HelperService
    ) { }

  public create(formValue:any){
    return this.http.post(`${this.url}/create`, formValue);
  }

  public list(params:any):Observable<any>{
    const qr = this.helperService.generateQueryString(params);
    return this.http.get(`${this.url}/list?${qr}`)
  }

  // delete
  public delete(id:string):Observable<any>{
    return this.http.delete(`${this.url}/${id}`)
  }

  // cập nhật
  public update(formValue:any):Observable<any>{
    return this.http.post(`${this.url}/update`, formValue)
  }

  // swap
  public swap(formValue:any):Observable<any>{
    return this.http.post(`${this.url}/swap`, formValue)
  }

  //detail
  public detail(id: string):Observable<any>{
    return this.http.get(`${this.url}/${id}`)
  }
}
