import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IActionList } from '../../interfaces';
import { ActionKey } from '../../enums';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SetRoleCopyComponent } from 'src/app/features/landing-page/components/set-role-copy/set-role-copy.component';
import { PushWarehouseComponent } from 'src/app/features/landing-page/components/push-warehouse/push-warehouse.component';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() title!: string;
  @Input() labelActionHeader!: string;
  @Input() backRoute = '';
  @Input() back = true;
  @Input() showButton = true;
  @Input() isShowActionList = true;
  @Input() actionList: IActionList[] = [
    {
      key: ActionKey.view,
      name: 'Xem chi tiết',
      condition: true,
      icon: 'fa-regular fa-eye',
    },
    {
      key: ActionKey.share,
      name: 'Chia sẻ',
      condition: true,
      icon: 'fa-solid fa-share-nodes',
    },
  ];
  @Output() actionClick = new EventEmitter();

  constructor(private router: Router, private nzModalService: NzModalService) {}

  ngOnInit(): void {}

  onBack(): void {
    window.history.back();

  }

  emitKeyAction(action: IActionList) {
    this.actionClick.emit(action.key);
  }

  openPopupRole() {
    this.nzModalService.create({
      nzTitle: 'Cho phép sao chép',
      nzContent: SetRoleCopyComponent,
      nzWidth: '450px',
      nzFooter: null,
      nzCentered: true,
    });
  }

  openPopupPushWarehouse() {
    this.nzModalService.create({
      nzTitle: 'Tải lên kho giao diện',
      nzContent: PushWarehouseComponent,
      nzWidth: '450px',
      nzFooter: null,
      nzCentered: true,
    });
  }
}
