import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import vi from '@angular/common/locales/vi';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER } from '@angular/core';

import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';

import { NZ_I18N, vi_VN } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';

import { QuillModule } from 'ngx-quill';

import { NZ_CONFIG, NzConfig } from 'ng-zorro-antd/core/config';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { PricePipe } from './core/pipes';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';
import { ScrollIntoViewDirective } from './core/directives/scroll-into-view.directive';
import { ResponseInterceptor } from './core/interceptor/response.interceptor';
import { SecretInterceptor } from './core/interceptor/secret.interceptor';
import { SafePipe } from './core/pipes/safe.pipe';
import { SignalrService } from './core/services/signalr.service';

registerLocaleData(vi);

const ngZorroConfig: NzConfig = {
  carousel: {},
};

@NgModule({
  declarations: [AppComponent, ScrollIntoViewDirective],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    FeaturesModule,
    QuillModule.forRoot(),
    NzIconModule,
    CoreModule.forRoot(),
  ],
  exports: [ReactiveFormsModule, FormsModule],
  providers: [
    { provide: NZ_I18N, useValue: vi_VN },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    DatePipe,
    SignalrService,
    {
      provide: APP_INITIALIZER,
      useFactory: (signalrService: SignalrService) => () => signalrService.init(),
      deps: [SignalrService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: SecretInterceptor, multi: true },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
    // { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
