import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment as ENV } from 'src/environments/environment';
import { EndPoint } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class ConcernService {

  private url = `${ENV.API_URL}${EndPoint.concern}`;

  constructor(private http: HttpClient) { }

  getConcern(body: any): Observable<any> {
    return this.http
      .post(`${this.url}/get-all`, body)
      .pipe(map((response) => response));
  }

  updateConcern(body: any): Observable<any> {
    return this.http
      .post(`${this.url}/create`, body)
      .pipe(map((response) => response));
  }
}
