import { ECategoryType } from '../enums/category.enum';

export const CATEGORY_TYPE = [
  {
    label: 'Nhóm bài đăng',
    value: ECategoryType.Post,
  },
  {
    label: 'Landing page',
    value: ECategoryType.LandingPage,
  },
];
