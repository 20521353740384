<nz-skeleton [nzActive]="true" [nzLoading]="isLoading"></nz-skeleton>
<div
  *ngIf="!isLoading"
  id="content-{{ id }}"
  class="ql-container ql-snow bg-no-repeat bg-cover"
  style="border-width: 0"
  [ngStyle]="{
    'background-image':
      item.bgImage && !item.backgroundColor ? 'url(' + item.bgImage + ')' : '',
    'background-color': item.backgroundColor ? item.backgroundColor : '',
    'padding-top': item.alignmentTop ? item.alignmentTop + 'px' : '0px',
    'padding-bottom': item.alignmentBottom
      ? item.alignmentBottom + 'px'
      : '0px',
    'padding-right': item.alignmentRight ? item.alignmentRight + '%' : '0px',
    'padding-left': item.alignmentLeft ? item.alignmentLeft + '%' : '0px'
  }"
>
  <quill-view [content]="item.text" format="json" theme="snow"></quill-view>
</div>
