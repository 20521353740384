import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MAX_NUMBER_IMAGE } from '../../const';
import { HelperService } from '../../services/helper.service';
import { NotiService } from '../../services/noti.service';
import { ResizeImageComponent } from '../resize-image/resize-image.component';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {
  public previewImage: string | undefined = '';
  public previewVisible = false;
  @Input() listImage: any[] = [];
  @Input() multiImage = true;
  @Input() maxNumberImage = MAX_NUMBER_IMAGE;
  @Input() images = [];
  @Input() roundedCropper: boolean = false;
  @Input() titleDialog: string = 'Chỉnh sửa ảnh';
  @Input() index: number = -1;
  @Input() isView: boolean = true;
  @Input() maintainAspectRatio = false;
  @Input() type: TypeUpload = TypeUpload.Default;
  @Input() aspectRatio: number = 16 / 9;
  @Input() typeCol: TypeCol = TypeCol.col4;
  @Output() imageAfterCropped: EventEmitter<any> = new EventEmitter();
  @ViewChild('uploadImageButton') uploadImageButton!: ElementRef;
  public TypeUpload = TypeUpload;
  public aspectButtonAddImage = '1/1';
  constructor(
    private modalService: NzModalService,
    private notiService: NotiService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.aspectButtonAddImage = this.helperService.decimalToFraction(
      this.aspectRatio
    );
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if(changes['aspectRatio']){
  //     this.aspectButtonAddImage = this.helperService.decimalToFraction(changes['aspectRatio'].currentValue);
  //     console.log(this.aspectButtonAddImage);

  //   }
  // }

  handlePreview() {
    console.log('preview');
  }
  uploadImage(e: any) {
    if (this.listImage.length >= this.maxNumberImage) {
      this.notiService.warning(`Được nhập tối đa ${this.maxNumberImage} ảnh`);
      return;
    }

    const modal = this.modalService.create({
      nzContent: ResizeImageComponent,
      nzCentered: true,
      nzClosable: false,
      nzComponentParams: {
        file: e,
        inputTitle: this.titleDialog,
        maintainAspectRatio: this.maintainAspectRatio,
        inputRoundedCropper: this.roundedCropper,
      },
      nzClassName: 'responsive-modal',
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.listImage.push(data);
      }
      this.imageAfterCropped.emit(this.listImage);
    });
  }

  uploadImageCustom(e: any) {
    const modal = this.modalService.create({
      nzContent: ResizeImageComponent,
      nzCentered: true,
      nzClosable: false,
      nzComponentParams: {
        file: e,
        inputTitle: this.titleDialog,
        inputRoundedCropper: this.roundedCropper,
      },
      nzClassName: 'responsive-modal',
    });
    modal.afterClose.subscribe((data) => {
      if (!data) return;
      this.imageAfterCropped.emit(data);
    });
  }

  setFocus() {
    console.log([this.uploadImageButton.nativeElement]);
    this.uploadImageButton.nativeElement.focus();
  }
  test() {}

  removeBanner(image: string) {
    // remove 1 banner
    console.log(this.listImage);
    this.listImage = this.listImage.filter((item) => item !== image);
    console.log(this.listImage);
    this.imageAfterCropped.emit(this.listImage);
  }

  getAspectButtonAdd() {
    return `aspect-${this.aspectButtonAddImage}`;
  }
}

export enum TypeUpload {
  Default,
  Custom,
  Only,
}

export enum TypeCol {
  col1 = 'grid-cols-1',
  col2 = 'grid-cols-2',
  col3 = 'grid-cols-3',
  col4 = 'grid-cols-4',
}