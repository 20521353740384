import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment as ENV } from 'src/environments/environment';
import { EndPoint } from '../enums';
import { HelperService } from './helper.service';
@Injectable({
  providedIn: 'root',
})
export class BusinessService {
  private url = `${ENV.API_URL}${EndPoint.business}`;
  public updatePost$ = new Subject();
  public managePost$ = new Subject();
  constructor(private http: HttpClient, private helperService: HelperService) {}

  public listSubTab(params: any) {
    const qr = this.helperService.generateQueryString(params);
    return this.http.get(`${ENV.API_URL}/gateway/sub-tab/list-all?${qr}`);
  }

  // Xem danh sách website thuộc quyền sở hữu người dùng
  public listAllWebsite(params: any): Observable<any> {
    const qr = this.helperService.generateQueryString(params);
    // console.log(params);
    // return this.http.get(`${this.url}/list-all?${qr}`);
    return this.http.get(`${this.url}/list-all?username=${params.username}`);
  }

  // Xem chi tiết website dành cho khác hàng - api này không có authen
  public websiteDetail(websiteId: string, params: any): Observable<any> {
    const qr = this.helperService.generateQueryString(params);
    return this.http.get(`${this.url}/${websiteId}?${qr}`);
  }

  // xem chi tiết trang website mà mình sỡ hữu
  public websiteOwnDetail(websiteId: string): Observable<any> {
    return this.http.get(`${this.url}/own/${websiteId}`);
  }

  public update(formData: any): Observable<any> {
    return this.http.post(`${this.url}/update`, formData);
  }

  public overviewById(id: string): Observable<any> {
    return this.http.get(`${this.url}/over-view/${id}`);
  }

  public updatePost(status: boolean) {
    this.updatePost$.next(status);
  }

  public managePost(code: string) {
    this.managePost$.next(code);
  }
}
