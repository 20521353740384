import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { URL_CDN_UPLOAD } from '../const/url';

@Injectable({
  providedIn: 'root',
})
export class CdnService {
  constructor(private http: HttpClient) {}
  uploadFile(file: any, type: string): Observable<any> {
    const formData: FormData = new FormData();
    let fileName = new Date().getTime() + file.name;
    formData.append('file', file, fileName);
    formData.append('type', type);
    formData.append('source', 'webmini');
    return this.http
      .put(URL_CDN_UPLOAD, formData)
      .pipe(map((reponse: any) => reponse));
  }
}
