import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ButtonType,
  ItemType,
  TypeButton,
  TypeItem,
} from '../../../models/screenEdit';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { DrawerService } from 'src/app/core/services/drawer.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { EditTypeActionComponent } from '../../edit-components/edit-type-action/edit-type-action.component';
import { TypeComponent } from '../../../enums/typeComponents.enum';
import { TypeActionComponent } from '../type-action/type-action.component';

@Component({
  selector: 'app-type-button',
  templateUrl: './type-button.component.html',
  styleUrls: ['./type-button.component.scss'],
})
export class TypeButtonComponent implements OnInit, OnChanges {
  @Input() isIntersection: boolean = true; // fake = true
  @Input() id: string = '';
  @Input() ctaElement!: ElementRef;
  isLoading: boolean = true;
  item: TypeButton = new TypeButton();
  TypeItem = TypeItem;
  constructor(
    private landingService: LandingPageService,
    private drawService: NzDrawerService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id && this.isIntersection) {
      this.getDetail();
    }
  }

  getDetail() {
    this.landingService.getDetailButton(this.id).subscribe((data) => {
      if (!data) return;
      let button = data[0];
      let backgroundColorButton = button.backgroundColorButton
        ? button.backgroundColorButton
        : '';
      let backgroundImageButton = button.backgroundImageButton
        ? button.backgroundImageButton
        : '';
      this.item = {
        id: button.id,
        buttonText: button.value,
        bgColor: button.backgroundColor,
        textColor: button.textColor,
        borderRadius: button.border,
        type: ItemType.button,
        typeFunctionBtn: Number(button.type),
        link: button.link,
        actionInfor: button.actionInfor,
        actions: button.actions,
        title: button.title,
        // button
        backgroundColorButton: backgroundColorButton,
        backgroundImageButton: backgroundImageButton,
        alignmentBottom: button.alignmentBottom || 0,
        alignmentTop: button.alignmentTop || 0,
        alignmentRight: button.alignmentRight || 0,
        alignmentLeft: button.alignmentLeft || 0,
        height: button.height,
        fontSize: button.fontSize,
      };
      this.isLoading = false;
    });
  }

  doBtnFunction() {
    switch (this.item.typeFunctionBtn) {
      case ButtonType.linkextra: {
        const win = window.open(this.item.link);
        win!.focus();
        break;
      }
      case ButtonType.opendialog: {
        let draw = this.drawService.create({
          nzTitle: 'Xem hộp thông tin',
          nzContent: TypeActionComponent,
          nzHeight: '550px',
          nzPlacement: 'bottom',
          nzContentParams: {
            isFromButtonDialog: true,
            dataFromButtonDialog: JSON.parse(JSON.stringify(this.item)),
          },
          nzWrapClassName: 'responsive-modal',
        });
        break;
      }
      case ButtonType.scrolltocta: {
        this.ctaElement.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        break;
      }
    }
  }
}
