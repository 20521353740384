<app-page-header [title]="'Mẫu nội dung danh mục'"></app-page-header>
<div>
  <div *ngFor="let item of listAllItems; index as i">
    <div [ngSwitch]="item.type">
      <app-type-image
        *ngSwitchCase="ItemType.image"
        [index]="i"
      ></app-type-image>
      <app-type-content *ngSwitchCase="ItemType.content"></app-type-content>
      <!-- <app-type-link
        *ngSwitchCase="ItemType.LINK"
       
      ></app-type-link>
      <app-type-action
        *ngSwitchCase="ItemType.ACTION"
       
      ></app-type-action> -->
    </div>
  </div>
</div>
