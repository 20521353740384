<section>
  <nz-radio-group class="!flex !flex-col" [(ngModel)]="radioValue">
    <label class="!my-[5px]" nz-radio [nzValue]="val1">Bất kỳ ai</label>
    <label class="!my-[5px]" nz-radio [nzValue]="val2">Chỉ mình tôi</label>
    <label class="!my-[5px]" nz-radio [nzValue]="val3">Tùy chỉnh</label>
  </nz-radio-group>
  <div *ngIf="radioValue === val3">
    <div class="flex items-center justify-between mt-1">
      <form [formGroup]="form" class="w-full">
        <input formControlName="username" placeholder="Nhập username" nz-input />
      </form>
      <button nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" (click)="searchMember()" nz-button nzType="primary"
        class="!bg-primary ml-2">Tìm kiếm</button>
    </div>
    <div class="max-h-[245px] overflow-y-auto px-1">
      <div *ngFor="let item of listMemberCopy; let i = index" class="w-full flex items-center justify-between my-2">
        <span>{{JSONparse(item)?.username}}</span>
        <button (click)="deleleMemberCopy(i)" nz-button nzType="default">Xóa</button>
      </div>
    </div>
  </div>
  <div class="flex justify-between mt-4">
    <div></div>
    <div class="flex">
      <button (click)="cancel()" nz-button nzType="default">Hủy</button>
      <button (click)="updateRole()" nz-button nzType="primary" class="!bg-primary !ml-2">Lưu</button>
    </div>
  </div>
</section>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <div *ngIf="isLoadingMemberSearch === true" class="!w-[58vw] min-[480px]:!w-[303px] !max-h-[200px] overflow-y-auto !mr-[98px] lg:!ml-[-312px] flex justify-center" nz-menu>
    <div class="flex items-center">
      <nz-spin class="p-2" nzSimple></nz-spin>
      <span class="ml-1 mb-1">Đang tải...</span>
    </div>
  </div>
  <ul *ngIf="isLoadingMemberSearch === false && listMember?.length > 0" class="!w-[58vw] min-[480px]:!w-[303px] !max-h-[200px] overflow-y-auto !mr-[98px] lg:!ml-[-312px]" nz-menu>
    <li (click)="addMemberCopy(item.id, item.login)" *ngFor="let item of listMember" nz-menu-item>
      <div class="w-full flex items-center justify-between">
        <span>{{item.login}}</span>
        <button nz-button nzType="default">+</button>
      </div>
    </li>
  </ul>
  <div *ngIf="isLoadingMemberSearch === false && listMember?.length === 0" class="!w-[58vw] min-[480px]:!w-[303px] !max-h-[200px] overflow-y-auto !mr-[98px] lg:!ml-[-312px] flex justify-center" nz-menu>
    <div class="p-2">Không tìm thấy kết quả</div>
  </div>
</nz-dropdown-menu>