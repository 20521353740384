import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from 'src/environments/environment';
import { KeyStorage } from '../enums';
import { EndPoint } from '../enums/endpoint.enum';

@Injectable({
  providedIn: 'root',
})
export class LandingPageService {
  private url_landing = `${ENV.API_URL}${EndPoint.landingpage}`;
  private url_content = `${ENV.API_URL}${EndPoint.content}`;
  private url_connective = `${ENV.API_URL}${EndPoint.connective}`;
  private url_action = `${ENV.API_URL}${EndPoint.action}`;
  private url_image = `${ENV.API_URL}${EndPoint.image}`;
  private url_button = `${ENV.API_URL}${EndPoint.button}`;
  private url_library = `${ENV.API_URL}${EndPoint.library}`;
  private member_url = `${ENV.API_URL}${EndPoint.member}`;
  constructor(private http: HttpClient) {}
  // ------- LandingPage --------
  addItem(body: any): Observable<any> {
    return this.http.post(this.url_landing + '/create', body);
  }
  getDetail(cateId: string): Observable<any> {
    return this.http.get(
      this.url_landing +
        '/get-all?CateId=' +
        cateId +
        '&username=' +
        localStorage.getItem(KeyStorage.username)
    );
  }
  delete(landingId: string): Observable<any> {
    return this.http.delete(this.url_landing + '/' + landingId);
  }
  swapLandingItem(body: any): Observable<any> {
    return this.http.post(this.url_landing + '/swap-item', body);
  }

  getDetailAno(anoId: string): Observable<any> {
    return this.http.get(this.url_landing + '/get-all/ano/' + anoId);
  }

  // -------- End Landing page -------
  // --------Content-------------
  addContent(body: any): Observable<any> {
    return this.http.post(this.url_content + '/add', body);
  }
  updateContent(body: any): Observable<any> {
    return this.http.patch(this.url_content + '/update', body);
  }
  removeContent(id: string): Observable<any> {
    return this.http.delete(this.url_content + '/' + id);
  }
  getDetailContent(landingId: string): Observable<any> {
    return this.http.get(
      this.url_content +
        '?LandingId=' +
        landingId +
        '&username=' +
        localStorage.getItem(KeyStorage.username)!
    );
  }
  // --------Connective-------------
  addConnective(body: any): Observable<any> {
    return this.http.post(this.url_connective + '/add', body);
  }
  updateConnective(body: any): Observable<any> {
    return this.http.patch(this.url_connective + '/update', body);
  }
  removeConnective(id: string): Observable<any> {
    return this.http.delete(this.url_connective + '/' + id);
  }
  getDetailConnective(landingId: string): Observable<any> {
    return this.http.get(
      this.url_connective + '/get-list?LandingId=' + landingId
    );
  }
  // --------Action-------------
  addAction(body: any): Observable<any> {
    return this.http.post(this.url_action + '/add', body);
  }
  updateAction(body: any): Observable<any> {
    return this.http.patch(this.url_action + '/update', body);
  }
  removeAction(id: string): Observable<any> {
    return this.http.delete(this.url_action + '/' + id);
  }
  getDetailAction(landingId: string): Observable<any> {
    return this.http.get(this.url_action + '?LandingId=' + landingId);
  }
  // --------Image-------------
  addListImage(body: any): Observable<any> {
    return this.http.post(this.url_image + '/add-list', body);
  }
  updateListImage(body: any): Observable<any> {
    return this.http.patch(this.url_image + '/update-list', body);
  }
  addOneImage(body: any): Observable<any> {
    return this.http.post(this.url_image + '/add', body);
  }
  removeImage(id: string): Observable<any> {
    return this.http.delete(this.url_image + '/' + id);
  }
  getDetailImage(landingId: string): Observable<any> {
    return this.http.get(this.url_image + '/get-list?LandingId=' + landingId);
  }
  // -------------Button--------------
  addButton(body: any): Observable<any> {
    return this.http.post(this.url_button + '/add', body);
  }
  updateButton(body: any): Observable<any> {
    return this.http.patch(this.url_button + '/update', body);
  }
  getDetailButton(landingId: string): Observable<any> {
    return this.http.get(this.url_button + 's?LandingId=' + landingId);
  }

  //copy landingpage
  cloneLandingPage(body: any): Observable<any> {
    return this.http.post(this.url_landing + '/Clone', body);
  }

  //library
  createLibrary(body: any): Observable<any> {
    return this.http.post(this.url_library + '/create', body);
  }

  getAllLibrary(body: any): Observable<any> {
    return this.http.post(this.url_library + '/get-all', body);
  }

  getAllCate(body: any): Observable<any> {
    return this.http.post(this.url_library + '/view-cate', body);
  }

  //search member
  searchMemberLicense(body: any): Observable<any> {
    return this.http.post(this.member_url + '/searchLicense', body);
  }
}
