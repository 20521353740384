import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KeyStorage } from 'src/app/core/enums';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-preview-landing',
  templateUrl: './preview-landing.component.html',
  styleUrls: ['./preview-landing.component.scss'],
})
export class PreviewLandingComponent implements OnInit {
  cateId: string = '';
  linkLanding: string =
    environment.CTA_URL + localStorage.getItem(KeyStorage.category_code)!;
  constructor(
    private route: ActivatedRoute,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.navigationService.hideNavbar();
    this.cateId = this.route.snapshot.params['id'];
  }
}
