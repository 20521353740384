<div
  class="background flex items-center justify-center w-full h-[300px] relative rounded-sm"
>
  <qr-code
    class="m-auto"
    [value]="value"
    size="200"
    centerImageSrc="./../../../../assets/images/icon-qr.svg"
    centerImageSize="45"
    [margin]="3"
    errorCorrectionLevel="H"
  ></qr-code>
  <!-- <div
    class="absolute w-[83px] h-[83px] top-[-40px] left-1/2 translate-x-[-50%]"
  >
    <img
      class="m-auto"
      width="80"
      height="80"
      src="./../../../../assets/images/icon-qr.svg"
      alt=""
    />
  </div> -->
</div>
<div class="text-center font-semibold mb-3 mt-2">Quét QR để xem chi tiết</div>
<div class="flex items-center gap-x-2 pb-3 justify-center">
  <a
    [download]="title"
    [href]="linkQr"
    class="px-4 py-1 rounded-sm button-success hover:text-white"
  >
    Tải xuống
  </a>
  <button (click)="share()" class="px-4 py-1 rounded-sm button-default">
    Chia sẻ
  </button>
</div>
