export const fontSize = [
  '8px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '28px',
  '32px',
  '36px',
  '48px',
  '56px',
  '72px',
];

export const font = [
  'TimesNewRoman',
  'CourierNew',
  'Caveat',
  'GreatVibes',
  'DancingScript',
  'PlayBall',
  'Montserrat',
  'BonaNova',
  'Inter',
  'Mali',
  'Raleway',
  'Charm',
  'Itim',
];
