import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[scrollIntoView]',
})
export class ScrollIntoViewDirective {
  constructor(private elementRef: ElementRef) {}

  public scrollToElement(): void {
    this.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
