import { CategoryType } from 'src/app/core/enums/categoryType.enum';
import { TypeContent } from '../../landing-page/models/screenEdit';

export class Preview {
  logo?: string;
  listBanner?: string[] = [];
  highLights?: TypeContent = new TypeContent();
  websiteName?: string;
  contactArray?: Contact = new Contact();
  tabs?: Tabs[] = [];
}

export class Contact {
  email?: ContactDetail = new ContactDetail();
  phone?: ContactDetail = new ContactDetail();
  messenger?: ContactDetail = new ContactDetail();
  zalo?: ContactDetail = new ContactDetail();
  linkProfile?: ContactDetail = new ContactDetail();
}

export class ContactDetail {
  icon?: string = '';
  isShow?: boolean = false;
  href?: string = '#';
}

export class ResponseViewOwn {
  id?: string;
  avatar?: string;
  memberId?: string;
  websiteName?: string;
  logo?: string;
  email?: string;
  emailEnable?: boolean;
  phoneNumber?: string;
  phoneNumberEnable?: boolean;
  messenger?: string;
  messengerEnable?: boolean;
  zalo?: string;
  zaloEnable?: boolean;
  description?: string;
  banners?: Banner[];
  error?: any;
  profilelink?: string;
  cardEnable?: boolean;
}

export class Banner {
  id?: string;
  websiteId?: any;
  value?: string;
  redirect?: any;
  type?: any;
}

export class Tabs {
  title?: string;
  type?: CategoryType;
  id!: string;
  isSelected?: boolean = false;
  code!: string;
}

export class BoxInPost {
  postName?: string;
  isHidden?: boolean = true;
  prices?: number = 0;
  discountPrices?: number = 0;
  images?: string = '';
  id?: string;
  outOfStock?: boolean;
  categoryID?: string;
  thumbnail?: string;
  hidden?: boolean;
}
