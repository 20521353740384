import { Injectable } from '@angular/core';
import { KeyStorage } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public setLocal(key:KeyStorage, value:string = ""){
    window.localStorage.setItem(key, value);
  }

  public setSession(key:KeyStorage, value:string = ""){
    window.sessionStorage.setItem(key, value);
  }

  public getLocal(key:KeyStorage){
    return window.localStorage.getItem(key);
  }

  public getSession(key:KeyStorage){
    return window.sessionStorage.getItem(key);
  }

  public removeLocal(key:KeyStorage){
    window.localStorage.removeItem(key);
  }

  public removeSession(key:KeyStorage){
    window.sessionStorage.removeItem(key);
  }

  public resetLocal(){
    window.localStorage.clear();
  }

  public resetSession(){
    window.sessionStorage.clear();
  }
}
