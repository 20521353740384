<nz-skeleton [nzActive]="true" [nzLoading]="isLoading"></nz-skeleton>
<div
  *ngIf="!isLoading"
  class="w-full"
  id="link-{{ id }}"
  [ngStyle]="{
    'background-image':
      item.backgroundImage && !item.backgroundColor
        ? 'url(' + item.backgroundImage + ')'
        : '',
    'background-color': item.backgroundColor ? item.backgroundColor : '',
    'padding-top': item.alignmentTop ? item.alignmentTop + 'px' : '0px',
    'padding-bottom': item.alignmentBottom
      ? item.alignmentBottom + 'px'
      : '0px',
    'padding-right': item.alignmentRight ? item.alignmentRight + '%' : '0px',
    'padding-left': item.alignmentLeft ? item.alignmentLeft + '%' : '0px'
  }"
>
  <div
    *ngIf="
      item.value?.includes('www.youtube.com') ||
      item.value?.includes('youtu.be')
    "
  >
    <iframe
      class="w-full"
      webkitallowfullscreen
      mozallowfullscreen
      allowfullscreen
      [src]="safeSrc | safe"
      frameborder="3"
    ></iframe>
    <div class="text-center italic mt-2 w-full">
      {{ item.label }}
    </div>
  </div>
  <div
    *ngIf="
      !item.value?.includes('www.youtube.com') &&
      !item.value?.includes('youtu.be')
    "
    class="flex items-center"
  >
    <i class="fa-solid fa-circle text-[5px] mr-2"></i>
    <span>{{ item.label }}</span>
  </div>
  <a
    *ngIf="
      !item.value?.includes('www.youtube.com') &&
      !item.value?.includes('youtu.be')
    "
    class="underline text-blue-400 mt-2 ml-3 fit-word"
  >
    {{ item.value }}
  </a>
</div>
