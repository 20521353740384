export enum TypeFooter {
  None,
  Save,
  Image,
  Content,
  Action,
  Link,
  Button,
}

export enum TypeFooterString {
  Image = 'image',
  Content = 'content',
  Action = 'action',
  Link = 'link',
  Button = 'button',
}

export enum TypeLandingPage {
  Image,
  Content,
  Connective,
  Action,
  Button,
}
