// export interface IPostData {
//   id: string;
//   postName?: any;
//   categoryName: string;
//   prices: number;
//   discountPrices: number;
//   hidden: boolean;
//   images?: any;
//   error?: any;
// }

export interface IPostData extends IPostDataExtend{
  id: string;
  postName: string;
  categoryName: string;
  prices: number;
  discountPrices: number;
  hidden: boolean;
  outOfStock: boolean;
  images?: any | any[];
  error?: any;
  categoryID?:string;
}

interface IPostDataExtend {
  postImage:string;
}


export interface IPostDataDetail {
  id: string;
  categoryID: string;
  postName: string;
  description1: string;
  titleLink1: string;
  link1: string;
  description2: string;
  titleLink2: string;
  link2: string;
  categoryName: string;
  prices: number;
  discountPrices: number;
  link: string;
  note: string;
  memberId: string;
  hidden: boolean;
  outOfStock: boolean;
  video: string;
  images?: any;
  error?: any;
  actionInfor?:any;
  linkVideo?:string;
}

export class CPostDataDetail {
  id!: string;
  categoryID!: string;
  postName!: string;
  description1!: string;
  titleLink1!: string;
  link1!: string;
  description2!: string;
  titleLink2!: string;
  link2!: string;
  categoryName!: string;
  prices!: number;
  discountPrices!: number;
  link!: string;
  note!: string;
  memberId!: string;
  hidden!: boolean;
  outOfStock!: boolean;
  video!: string;
  images?: any;
  error?: any;
}