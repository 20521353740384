<app-preview-heading
  title="Đang xem ở chế độ khách"
  [isShowShare]="true"
  [link]="linkLanding"
></app-preview-heading>
<div class="mt-12">
  <app-landing-page-view
    [id]="cateId"
    [isSelected]="true"
    [isShowCoppyBar]="false"
  ></app-landing-page-view>
</div>
