import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { QrCodeComponent } from '../ui/qr-code/qr-code.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';

@Injectable({
  providedIn: 'root',
})
export class QrService {
  constructor(
    private modal: NzModalService,
    private nzDrawerService: NzDrawerService
  ) {}
  openQr(link: string, title: string) {
    this.modal.create({
      nzContent: QrCodeComponent,
      nzFooter: null,
      nzTitle: undefined,
      nzWidth: '300px',
      nzCentered: true,
      nzCloseOnNavigation: false,
      nzCloseIcon: undefined,
      nzClassName: 'qr-modal',
      nzComponentParams: {
        value: link,
        title: title,
      },
      nzBodyStyle: {
        padding: '0px',
      },
    });
  }
}
