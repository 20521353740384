import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ShareLinkService {
  constructor() {}
  shareLink(url: string) {
    navigator.share({ url: url });
  }
}
