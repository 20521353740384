import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailSampleComponent } from './components/detail-sample/detail-sample.component';
import { LandingPageComponent } from './landing-page.component';
import { PreviewLandingComponent } from './components/preview-landing/preview-landing.component';

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    children: [
      {
        path: 'preview-sample',
        component: DetailSampleComponent,
      },
      {
        path: '',
        loadChildren: () =>
          import('./components/edit-container/edit-container.module').then(
            (m) => m.EditContainerModule
          ),
      },
      {
        path: 'preview/:id',
        component: PreviewLandingComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingPageRoutes {}
