import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IActionList } from '../../../interfaces';
import { ActionKey } from '../../../enums';
import { Router } from '@angular/router';

export enum ETypeHeader {
  home,
  view,
  custom,
}
@Component({
  selector: 'app-page-header-webmini',
  templateUrl: './page-header-webmini.component.html',
  styleUrls: ['./page-header-webmini.component.scss'],
})
export class PageHeaderWebminiComponent implements OnInit {
  @Input() typeHeader: ETypeHeader = ETypeHeader.home;
  @Input() title!: string;
  @Input() labelActionHeader!: string;
  @Input() backRoute = '';
  @Input() back = true;
  @Input() showButton = true;
  @Input() isShowActionList = true;
  @Input() isShowSubHeader: boolean = false;
  @Input() subHeaderTitle: string = '';
  @Input() actionList: IActionList[] = [
    {
      key: ActionKey.view,
      name: 'Xem chi tiết',
      condition: true,
      icon: 'eye',
    },
    {
      key: ActionKey.share,
      name: 'Chia sẻ',
      condition: true,
      icon: 'share-alt',
    },
  ];
  @Input() logoWebsite: string = '';
  @Input() nameWebsite: string = '';
  @Output() actionClick = new EventEmitter();
  @Output() actionDropdown = new EventEmitter();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onBack(): void {
    if (this.backRoute) {
      this.router.navigate([this.backRoute]);
    } else {
      window.history.back();
    }
  }

  emitKeyAction(action: IActionList) {
    this.actionClick.emit(action.key);
  }

  protected readonly ETypeHeader = ETypeHeader;
}
