import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { WebsiteService } from 'src/app/core/services/website.service';
import { BoxInPost } from '../../models/preview';
import { TypePreview } from '../preview-container/preview-container.component';
import { DetailPostComponent } from './detail-post/detail-post.component';
import { KeyInfoUser, KeyStorage } from 'src/app/core/enums';
import { log } from 'console';
import { HelperService } from 'src/app/core/services/helper.service';

@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.scss'],
})
export class PostViewComponent implements OnInit, OnChanges {
  @Input() id: string = '';
  @Input() isSelected: boolean = false;
  @Input() typePreview: TypePreview = TypePreview.Viewown;
  @Input() keyWebsite: string = '';
  detailPost: BoxInPost[] = [];
  TypePreview = TypePreview;
  constructor(
    private websiteService: WebsiteService,
    private nzModal: NzModalService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    // console.log(this.id);
    // console.log(this.isSelected);
    // console.log(this.typePreview);
    // console.log(this.keyWebsite);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id && this.isSelected) {
      this.getDetailPost();
    }
  }

  getDetailPost() {
    this.detailPost = [];
    this.websiteService.getListPost(this.id, 1, 1000, localStorage.getItem(KeyStorage.username_view)!).subscribe((data) => {
      if (!data) return;
      if (!data.data) return;
      this.detailPost = data?.data.map((item: BoxInPost) => {
        return {
          ...item,
          thumbnail: item?.images ? item?.images[0] : '',
        };
      });

      // for (var i = 0; i < data.data.length; i++) {
      //   if (!data.data[i].hidden) {
      //     this.detailPost.push({
      //       postName: data.data[i].postName,
      //       isHidden: data.data[i].hidden ? data.data[i].hidden : false,
      //       prices: data.data[i].prices,
      //       discountPrices: data.data[i].discountPrices,
      //       images: data.data[i].images ? data.data[i].images[0] : '',
      //       id: data.data[i].id,
      //       outOfStock: data.data[i].outOfStock,

      //     });
      //   }
      // }
    });
  }

  openDetailPost() {
    this.nzModal.create({
      nzTitle: 'Chi tiết bài đăng',
      nzContent: DetailPostComponent,
    });
  }

  detailPostNavigate(postId: string) {
    if (
      this.typePreview === TypePreview.Viewown ||
      this.typePreview === TypePreview.Home
    ) {
      // this.router.navigate(['pages/preview/details', postId]);
      this.router.navigate(['/view/post', postId as string], {
        queryParams: {
          username: this.helperService.getInfoUserByKey(KeyInfoUser.user_name),
        },
      });
    } else {
      localStorage.setItem(KeyStorage.post_detail_id, postId);
      this.router.navigate([`${this.keyWebsite}/detail-post`]);
    }
  }
}
