<div
  class="text-primary rounded-full text-lg w-12 h-12 border-[1px] border-primary border-solid flex items-center justify-center ml-auto mr-3 mb-3 bg-slate-200 fixed bottom-0 right-0 z-10"
  [ngStyle]="{
    bottom: typeFooter === TypeFooter.None ? '0px' : '90px',
    right: 0
  }"
  (click)="scrollToTop()"
>
  <i class="fa-solid fa-up-long"></i>
</div>
<div
  class="fixed left-1/2 translate-x-[-50%] bottom-0 w-full sm:w-responsivelarge z-10"
>
  <!-- ----- edit area ----- -->
  <div
    *ngIf="typeFooter != TypeFooter.None"
    [ngSwitch]="typeFooter"
    @slide
    #footerElement
    class="footer-container relative"
  >
    <div class="footer-content">
      <div
        (click)="left()"
        class="fixed text-primary p-2 left-1 flex items-center justify-center z-10"
      >
        <i class="fa-solid text-lg fa-chevron-left"></i>
      </div>
      <ng-container *ngSwitchCase="TypeFooter.None"></ng-container>
      <ng-container
        *ngSwitchCase="TypeFooter.Image"
        [ngTemplateOutlet]="footerImage"
      ></ng-container>
      <ng-container
        *ngSwitchCase="TypeFooter.Content"
        [ngTemplateOutlet]="footerContent"
      ></ng-container>
      <ng-container
        *ngSwitchCase="TypeFooter.Action"
        [ngTemplateOutlet]="footerAction"
      ></ng-container>
      <ng-container
        *ngSwitchCase="TypeFooter.Link"
        [ngTemplateOutlet]="footerLink"
      ></ng-container>
      <ng-container
        *ngSwitchCase="TypeFooter.Button"
        [ngTemplateOutlet]="footerButton"
      >
      </ng-container>
      <div
        (click)="right()"
        class="fixed text-primary p-2 right-1 text-lg flex items-center justify-center z-10"
      >
        <i class="fa-solid fa-chevron-right"></i>
      </div>
    </div>
  </div>
  <!-- ----- end edit area ----- -->
</div>
<!-- Change positon -->
<div
  @slide
  *ngIf="isChangePosition"
  class="fixed left-1/2 translate-x-[-50%] bottom-0 w-full sm:w-responsivelarge bg-white z-20"
>
  <div class="grid grid-cols-4 gap-4 px-4 pt-3">
    <div
      (click)="down()"
      class="text-center button-outline-success flex justify-center items-center py-2 cursor-pointer active:bg-primary active:text-white rounded-sm"
      style="border: 1px solid rgb(14 175 143 / var(--tw-text-opacity))"
    >
      <span
        nz-icon
        nzType="arrow-down"
        nzTheme="outline"
        class="font-medium"
      ></span>
    </div>
    <div
      (click)="up()"
      class="text-center button-outline-success flex justify-center items-center py-2 cursor-pointer active:bg-primary active:text-white rounded-sm"
      style="border: 1px solid rgb(14 175 143 / var(--tw-text-opacity))"
    >
      <span
        nz-icon
        nzType="arrow-up"
        nzTheme="outline"
        class="font-medium"
      ></span>
    </div>
    <div
      (click)="upToLine()"
      class="text-center button-outline-success flex justify-center items-center py-2 cursor-pointer active:bg-primary active:text-white rounded-sm"
      style="border: 1px solid rgb(14 175 143 / var(--tw-text-opacity))"
    >
      <img
        class="active:bg-primary active:text-white"
        src="./../../../../../assets/images/Arrow_up.svg"
        alt=""
      />
    </div>
    <div
      (click)="downToLine()"
      class="text-center button-outline-success flex justify-center items-center py-2 cursor-pointer active:bg-primary active:text-white rounded-sm"
      style="border: 1px solid rgb(14 175 143 / var(--tw-text-opacity))"
    >
      <img
        class="active:bg-primary active:text-white"
        src="./../../../../../assets/images/Arrow_down.svg"
        alt=""
      />
    </div>
  </div>
  <div class="flex items-center justify-between py-3">
    <button
      class="button-outline-default grow hover:opacity-70 mr-4"
      (click)="cancelChangePosition()"
    >
      Hủy
    </button>
    <button
      class="button-success grow hover:opacity-70"
      (click)="changePosition()"
    >
      Lưu
    </button>
  </div>
</div>
<!-- Change cover -->
<div
  @slide
  *ngIf="isChangeCover"
  style="z-index: 100"
  class="fixed left-1/2 translate-x-[-50%] bottom-0 w-full sm:w-responsivelarge bg-white"
>
  <div
    class="gap-4 px-4 pt-3 pb-3 grid grid-cols-3 space-x-2 items-center relative bg-white"
  >
    <div (click)="openColorPicker()" class="box flex flex-col cursor-pointer">
      <i class="fa-solid fa-palette"></i>
      <span class="text-xs">Màu nền</span>
    </div>
    <app-upload-image
      [type]="TypeUpload.Custom"
      (imageAfterCropped)="changeCoverImage($event, false)"
    >
      <div class="box flex flex-col">
        <i class="fa-regular fa-images"></i>
        <span class="text-xs">Ảnh</span>
      </div>
    </app-upload-image>
    <div
      (click)="changeCoverImage(1, true)"
      class="box flex flex-col cursor-pointer"
    >
      <i class="fa-solid fa-rotate"></i>
      <span class="text-xs">Xóa</span>
    </div>
    <div
      (click)="isChangeCover = false"
      class="w-20 rounded-t-full h-20 bg-danger cursor-pointer text-danger absolute top-[-25px] left-1/2 translate-x-[-50%] flex items-start justify-center z-[-1]"
    >
      <i class="fa-solid fa-xmark text-xl text-white"></i>
    </div>
  </div>
</div>
<ng-template #footerImage>
  <app-upload-image
    @slide
    [type]="TypeUpload.Custom"
    [index]="1"
    (imageAfterCropped)="addNewImageToList($event)"
  >
    <div class="box">
      <i class="fa-solid fa-plus"></i>
      <span>Thêm mới</span>
    </div>
  </app-upload-image>
  <button @slide class="box" (click)="editImage()">
    <i class="fa-solid fa-edit"></i>
    <span>Chỉnh sửa</span>
  </button>
  <button @slide class="box" (click)="isChangePosition = true">
    <i class="fa-solid fa-up-down-left-right"></i>
    <span>Đổi vị trí</span>
  </button>
  <button @slide class="box" (click)="changeCover()">
    <i class="fa-solid fa-paint-roller"></i>
    <span>Hình nền</span>
  </button>
  <button @slide class="box" (click)="alignment()">
    <img src="./../../../../../assets/icons/ic-alignment.svg" alt="" />
    <span>Căn lề</span>
  </button>
  <button @slide class="box" (click)="removeComponent()">
    <i class="fa-solid fa-trash"></i>
    <span>Xóa</span>
  </button>
</ng-template>
<ng-template #footerButton>
  <!-- --------------Type Button-------------------- -->
  <button @slide class="box" (click)="editButton()">
    <i class="fa-solid fa-paint-roller"></i>
    <span>Giao diện</span>
  </button>
  <button @slide class="box" (click)="editFeatureBtn()">
    <i class="fa-solid fa-hand-pointer"></i>
    <span>Chức năng</span>
  </button>
  <button @slide class="box" (click)="isChangePosition = true">
    <i class="fa-solid fa-up-down-left-right"></i>
    <span>Đổi vị trí</span>
  </button>
  <button @slide class="box" (click)="changeCover()">
    <i class="fa-solid fa-paint-roller"></i>
    <span>Hình nền</span>
  </button>
  <button @slide class="box" (click)="alignment()">
    <img src="./../../../../../assets/icons/ic-alignment.svg" alt="" />
    <span>Căn lề</span>
  </button>
  <button @slide class="box" (click)="removeComponent()">
    <i class="fa-solid fa-trash"></i>
    <span>Xóa</span>
  </button>
</ng-template>
<ng-template #footerLink>
  <button @slide class="box" (click)="editLink()">
    <i class="fa-solid fa-edit"></i>
    <span>Chỉnh sửa</span>
  </button>
  <button @slide class="box" (click)="isChangePosition = true">
    <i class="fa-solid fa-up-down-left-right"></i>
    <span>Đổi vị trí</span>
  </button>
  <button @slide class="box" (click)="changeCover()">
    <i class="fa-solid fa-paint-roller"></i>
    <span>Hình nền</span>
  </button>
  <button @slide class="box" (click)="alignment()">
    <img src="./../../../../../assets/icons/ic-alignment.svg" alt="" />
    <span>Căn lề</span>
  </button>
  <button @slide class="box" (click)="removeComponent()">
    <i class="fa-solid fa-trash"></i>
    <span>Xóa</span>
  </button>
</ng-template>
<ng-template #footerContent>
  <!-- --------------Type Content--------------- -->
  <button @slide class="box" (click)="editContent()">
    <i class="fa-solid fa-edit"></i>
    <span>Chỉnh sửa</span>
  </button>
  <button @slide class="box" (click)="isChangePosition = true">
    <i class="fa-solid fa-up-down-left-right"></i>
    <span>Đổi vị trí</span>
  </button>
  <button @slide class="box" (click)="changeCover()">
    <i class="fa-solid fa-paint-roller"></i>
    <span>Hình nền</span>
  </button>
  <button @slide class="box" (click)="alignment()">
    <img src="./../../../../../assets/icons/ic-alignment.svg" alt="" />
    <span>Căn lề</span>
  </button>
  <button @slide class="box" (click)="removeComponent()">
    <i class="fa-solid fa-trash"></i>
    <span>Xóa</span>
  </button>
</ng-template>
<ng-template #footerAction>
  <button @slide class="box" (click)="editAction()">
    <i class="fa-solid fa-edit"></i>
    <span>Chỉnh sửa</span>
  </button>
  <button @slide class="box" (click)="isChangePosition = true">
    <i class="fa-solid fa-up-down-left-right"></i>
    <span>Đổi vị trí</span>
  </button>
  <button @slide class="box" (click)="changeCover()">
    <i class="fa-solid fa-paint-roller"></i>
    <span>Hình nền</span>
  </button>
  <button @slide class="box" (click)="alignment()">
    <img src="./../../../../../assets/icons/ic-alignment.svg" alt="" />
    <span>Căn lề</span>
  </button>
  <button @slide class="box" (click)="removeComponent()">
    <i class="fa-solid fa-trash"></i>
    <span>Xóa</span>
  </button>
</ng-template>
