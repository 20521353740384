<!-- <div
  *ngIf="isShowCoppyBar"
  class="bg-secondary-bg px-4 py-2 flex items-center justify-between"
>
  <div>
    <div>Sao chép giao diện</div>
  </div>
  <div class="flex items-center">
    <div (click)="shareQR()" class="text-xl text-primary mr-3">
      <i class="fa-solid fa-qrcode"></i>
    </div>
    <div class="text-xl text-primary">
      <i (click)="shareLink()" class="fa-regular fa-share-from-square"></i>
    </div>
  </div>
</div> -->
<div
  *ngIf="isShowCoppyBar"
  class="bg-[#fff] px-4 py-2 flex items-center justify-between"
>
</div>
<div>
  <div *ngFor="let item of listAllItems; index as i" [id]="item.id">
    <div [ngSwitch]="item.type">
      <div
        *ngSwitchCase="ItemType.image"
        class="container normal"
        [id]="item.id"
      >
        <app-type-image
          class="item"
          [index]="i"
          [id]="item.id"
          [isIntersection]="item.isIntersection"
        ></app-type-image>
      </div>
      <div
        *ngSwitchCase="ItemType.content"
        class="container normal"
        [id]="item.id"
      >
        <app-type-content
          class="item"
          [id]="item.id"
          [isIntersection]="item.isIntersection"
        ></app-type-content>
      </div>
      <div
        *ngSwitchCase="ItemType.connective"
        class="container normal"
        [id]="item.id"
      >
        <app-type-link
          class="item"
          [id]="item.id"
          [isIntersection]="item.isIntersection"
        ></app-type-link>
      </div>
      <div
        #CTAElement
        *ngSwitchCase="ItemType.action"
        class="container normal"
        [id]="item.id"
      >
        <app-type-action
          class="item"
          [id]="item.id"
          [isIntersection]="item.isIntersection"
        ></app-type-action>
      </div>
      <div
        *ngSwitchCase="ItemType.button"
        class="container normal"
        [id]="item.id"
      >
        <app-type-button
          class="item"
          [id]="item.id"
          [isIntersection]="item.isIntersection"
          [ctaElement]="ctaElement"
        ></app-type-button>
      </div>
    </div>
  </div>
</div>
