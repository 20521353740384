import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IActionFooter } from '../../interfaces';

@Component({
  selector: 'app-footer-button',
  templateUrl: './footer-button.component.html',
  styleUrls: ['./footer-button.component.scss']
})
export class FooterButtonComponent implements OnInit {

  @Input() labelAction:string = 'Lưu'
  @Input() actionFooter!:IActionFooter[];
  @Output() clickAction = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  submit(){
    this.clickAction.emit();
  }

}
