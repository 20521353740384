<div class="w-full sm:w-responsivelarge m-auto">
  <div class="flex items-center justify-between">
    <app-page-header
      [title]="'Chi tiết bài đăng'"
      class="w-full"
    ></app-page-header>
    <button
      *ngIf="!isLogin"
      (click)="login()"
      class="py-2 button-success grow w-[150px]"
    >
      Đăng nhập
    </button>
  </div>
  <div class="w-full">
    <nz-space [nzDirection]="'vertical'" [nzSize]="'middle'" class="w-full">
      <div *nzSpaceItem>
        <app-carousel [images]="detailPost.images"></app-carousel>
      </div>
      <p *nzSpaceItem class="font-semibold">
        {{ detailPost.postName }}
      </p>
      <div *nzSpaceItem class="text-base">
        <span class="text-red-text font-bold mr-3">{{
          detailPost.discountPrices | number
        }}</span>
        <span class="text-disable line-through">{{
          detailPost.prices | number
        }}</span>
      </div>
      <div *nzSpaceItem class="flex space-x-3">
        <button
          (click)="concern()"
          class="button button-success flex items-center px-2"
        >
          <span nz-icon nzType="fire" nzTheme="outline"></span>
          <span class="ml-2">Quan tâm</span>
        </button>
        <!-- <button
          class="button button-outline-success flex items-center px-2"
          (click)="share()"
        >
          <span nz-icon nzType="share-alt" nzTheme="outline"></span>
          <span class="ml-2">Chia sẻ</span>
        </button>
        <button
          class="button button-outline-success flex-center text-primary aspect-square w-[30px]"
          (click)="qrShare()"
        >
          <span nz-icon nzType="qrcode" nzTheme="outline"></span>
        </button> -->
      </div>
      <div *nzSpaceItem class="card">
        <quill-view
          [content]="detailPost.description1"
          format="json"
          theme="snow"
        ></quill-view>
      </div>
      <!-- <button *nzSpaceItem class="text-sky-500 font-bold">Xem thêm</button> -->
      <div *nzSpaceItem>
        <app-video
          [srcVideo]="detailPost.video"
          [titleVideo]="detailPost.titleLink1"
          [descVideo]="detailPost.description2"
        ></app-video>
      </div>
      <div *nzSpaceItem>
        <p class="font-bold">{{ detailPost.titleLink2 }}</p>
        <a
          [href]="detailPost.link2"
          target="_blank"
          class="text-sky-600 underline underline-offset-1"
        >
          {{ detailPost.link2 }}
        </a>
      </div>
      <form
        [formGroup]="form"
        *nzSpaceItem
        class="p-3 bg-primary-bg rounded-sm space-y-3"
      >
        <p class="font-bold">{{ detailPost.actionInfor }}</p>
        <div class="">
          <ng-container
            [ngTemplateOutlet]="labelTpl"
            [ngTemplateOutletContext]="{ required: true, label: 'Họ tên' }"
          ></ng-container>
          <input
            formControlName="fullName"
            nz-input
            placeholder="Nhập họ tên"
          />
          <div
            *ngIf="
              form.get('fullName')!.invalid &&
              (form.get('fullName')!.dirty || form.get('fullName')!.touched)
            "
            class="invalid-feedback"
          >
            <div
              class="mt-1 text-danger"
              *ngIf="form.get('fullName')!.errors?.['required']"
            >
              Trường này là bắt buộc
            </div>
          </div>
        </div>
        <div class="">
          <ng-container
            [ngTemplateOutlet]="labelTpl"
            [ngTemplateOutletContext]="{ required: false, label: 'Email' }"
          ></ng-container>
          <input formControlName="email" nz-input placeholder="Nhập email" />
          <div
            *ngIf="
              form.get('email')!.invalid &&
              (form.get('email')!.dirty || form.get('email')!.touched)
            "
            class="invalid-feedback"
          >
            <div
              class="mt-1 text-danger"
              *ngIf="form.get('email')!.errors?.['email']"
            >
              Không đúng định dạng email
            </div>
          </div>
        </div>
        <div class="">
          <ng-container
            [ngTemplateOutlet]="labelTpl"
            [ngTemplateOutletContext]="{
              required: true,
              label: 'Số điện thoại'
            }"
          ></ng-container>
          <input
            formControlName="phonenumber"
            nz-input
            placeholder="Nhập SĐT"
          />
          <div
            *ngIf="
              form.get('phonenumber')!.invalid &&
              (form.get('phonenumber')!.dirty ||
                form.get('phonenumber')!.touched)
            "
            class="invalid-feedback"
          >
            <div
              class="mt-1 text-danger"
              *ngIf="form.get('phonenumber')!.errors?.['required']"
            >
              Trường này là bắt buộc
            </div>
          </div>
        </div>
        <div class="">
          <ng-container
            [ngTemplateOutlet]="labelTpl"
            [ngTemplateOutletContext]="{ required: false, label: 'Địa chỉ' }"
          ></ng-container>
          <input
            formControlName="address"
            nz-input
            placeholder="Nhập địa chỉ"
          />
        </div>
        <div class="">
          <ng-container
            [ngTemplateOutlet]="labelTpl"
            [ngTemplateOutletContext]="{ required: false, label: 'Lời nhắn' }"
          ></ng-container>
          <textarea formControlName="note" rows="3" nz-input></textarea>
        </div>
        <button
          [disabled]="form.invalid"
          (click)="sendConcern()"
          class="button button-success w-full"
        >
          Gửi thông tin
        </button>
      </form>
    </nz-space>
  </div>

  <ng-template #labelTpl let-rq="required" let-label="label" let-for="for">
    <div class="mb-1">
      <span class="text-red-text mr-1" *ngIf="rq">*</span>
      <label [for]="for">{{ label }}</label>
    </div>
  </ng-template>
</div>
