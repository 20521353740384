<div class="w-full rounded-sm relative" *ngIf="images.length">
  <!-- <nz-carousel
    #nzCarousel
    [nzDotPosition]="'bottom'"
    [nzDots]="true"
    [nzEnableSwipe]="true"
    [nzAutoPlaySpeed]="5000"
    class="w-full"
  >
    <div nz-carousel-content *ngFor="let url of images">
      <div class="flex justify-center">
        <img
          nz-image
          [nzSrc]="url"
          [nzDisablePreview]="isDisablePreview"
          class="w-full rounded-sm aspect-video"
          alt=""
        />
        <div
          [ngStyle]="{ 'background-image': 'url(' + url + ')' }"
          class="w-full rounded-sm aspect-video bg-center bg-cover"
        ></div>
      </div>
    </div>
  </nz-carousel> -->
  <swiper
    *ngIf="images && images.length > 1"
    direction="horizontal"
    [loop]="true"
    [autoplay]="{ delay: 1000, disableOnInteraction: false }"
    [pagination]="{ clickable: true }"
    [navigation]="true"
  >
    <ng-template *ngFor="let item of images" swiperSlide>
      <img class="w-full" [src]="item" alt="" />
    </ng-template>
  </swiper>
  <img
    *ngIf="images && images.length === 1"
    nz-image
    [nzSrc]="images[0]"
    nzDisablePreview="true"
    class="w-full"
  />
  <!-- <button
    type="button"
    class="w-8 h-8 hover:bg-disable/50 bg-disable/40 duration-300 flex-center absolute left-3 top-1/2 -translate-y-1/2 p-0"
    (click)="switchImage('prev')"
  >
    <i class="fa-solid fa-angle-left text-white/80 p-0"></i>
  </button>
  <button
    type="button"
    class="w-8 h-8 hover:bg-disable/50 bg-disable/40 duration-300 bg-disable flex-center absolute right-3 top-1/2 -translate-y-1/2 p-0"
    (click)="switchImage('next')"
  >
    <i class="fa-solid fa-angle-right text-white/80 p-0"></i>
  </button> -->
</div>
