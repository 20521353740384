<div
  class="w-full justify-between items-center bg-primary text-white px-3 py-2 relative grid grid-cols-5"
>
  <div>
    <button class="p-0 w-5" *ngIf="back" (click)="onBack()">
      <i class="fa-solid fa-angle-left"></i>
    </button>
  </div>
  <p class="col-span-3 text-center overflow-hidden">{{ title }}</p>
  <div *ngIf="isShowActionList" class="flex justify-end">
    <ng-container *ngFor="let action of actionList">
      <button *ngIf="action?.key !== 'push'" class="w-1/2 p-0" (click)="emitKeyAction(action)">
        <i [ngClass]="action?.icon"></i>
      </button>
      <button nz-dropdown [nzDropdownMenu]="menu" *ngIf="action?.key === 'push'" class="w-1/2 p-0" (click)="emitKeyAction(action)">
        <i [ngClass]="action?.icon"></i>
      </button>
    </ng-container>
  </div>
</div>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu>
    <li (click)="openPopupRole()" nz-menu-item>Quyền sao chép</li>
    <li (click)="openPopupPushWarehouse()" nz-menu-item>Tải lên kho</li>
  </ul>
</nz-dropdown-menu>