import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { KeyStorage } from 'src/app/core/enums';
import { CategoryType } from 'src/app/core/enums/categoryType.enum';
import { CreateLandingFrom } from 'src/app/core/enums/landing.enum';
import { UserInfo } from 'src/app/core/models/UserInfo';
import { DrawerService } from 'src/app/core/services/drawer.service';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { PostService } from 'src/app/core/services/post.service';
import { QrService } from 'src/app/core/services/qr.service';
import { WebsiteService } from 'src/app/core/services/website.service';
import { CategoryModalCreateComponent } from 'src/app/features/category/category-modal-create/category-modal-create.component';
import {
  ItemType,
  TypeItem,
} from 'src/app/features/landing-page/models/screenEdit';
import { CommonService } from 'src/app/features/landing-page/services/common.service';
import { environment } from 'src/environments/environment';
import { BusinessService } from '../../../../core/services/business.service';
import { Preview, ResponseViewOwn } from '../../models/preview';
import { PreviewLogicService } from '../../services/preview-logic.service';
import { NavigationService } from 'src/app/core/services/navigation.service';
@Component({
  selector: 'app-preview-container',
  templateUrl: './preview-container.component.html',
  styleUrls: ['./preview-container.component.scss'],
})
export class PreviewContainerComponent implements OnInit, AfterViewInit {
  @Input() typePreview: TypePreview = TypePreview.Viewown;
  @Input() isShowCoppyBar: boolean = true;
  currentIndexTab$ = this.previewLogicSer.currenIndexTab$;
  TypePreview = TypePreview;
  TypeItem = TypeItem;
  previewContainer: Preview = new Preview();
  path = './../../../../../assets/images/';
  CategoryType = CategoryType;
  selectedIndex = 0;
  codeTabSelected: string = '';
  isHasCategory: boolean = true;
  isEmptyPost: boolean = false;
  isEmptyLanding: boolean = false;
  isDoneLoadingDetail: boolean = false;
  userInfor: UserInfo = new UserInfo();
  userId: string = '';
  // --- ano view ---
  keyWebsite = '';
  linkViewWebsite = '';
  isValidWebsite: boolean = true;
  // --- end ano view ---
  cta_url = '';

  constructor(
    private router: Router,
    private websiteService: WebsiteService,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private qrService: QrService,
    private commonService: CommonService,
    private drawerService: DrawerService,
    private loadingService: LoadingService,
    private postService: PostService,
    private landingService: LandingPageService,
    private businessService: BusinessService,
    private previewLogicSer: PreviewLogicService,
    private navigationService: NavigationService
  ) {}

  async ngOnInit() {
    this.cta_url = environment.CTA_URL;
    if (this.typePreview != TypePreview.Home) {
      this.navigationService.hideNavbar();
    }
    switch (this.typePreview) {
      // Nếu là view-own hoặc home -> call api
      case TypePreview.Viewown: {
        this.getPreviewContainerWebsite();
        break;
      }
      case TypePreview.Home: {
        this.handleLoadingAgain();
        this.getPreviewContainerWebsite();
        break;
      }
      // another view
      default: {
        this.getKeyWebsite();
        this.getKeyTab();
        this.checkIsLogin();
      }
    }
    // if (
    //   this.typePreview === TypePreview.Viewown ||
    //   this.typePreview === TypePreview.Home
    // ) {
    //   this.getPreviewContainerWebsite();
    // }
    // // Another view
    // else {
    //   this.getKeyWebsite();
    //   this.getKeyTab();
    //   this.checkIsLogin();
    // }
  }

  ngAfterViewInit(): void {
    if (this.typePreview === TypePreview.Home) {
      this.handleUpdatePost();
      setTimeout(() => {
        this.handleSlideToPostTab();
      }, 500);
    }
  }

  getKeyWebsite() {
    // --- get key website ---
    this.activeRoute.paramMap.subscribe((params) => {
      this.keyWebsite = params.get('key-website')!;
      localStorage.setItem(KeyStorage.website_code, this.keyWebsite);
      this.linkViewWebsite = environment.URL_ENV + '/' + this.keyWebsite;
      this.getPreviewContainerWebsiteAnotherView();
    });
  }

  getKeyTab() {
    this.activeRoute.queryParamMap.subscribe((queryParam) => {
      this.codeTabSelected = queryParam.get('t')!;
    });
  }

  back() {
    this.router.navigate(['/pages/home']);
  }

  renderContactArray(data: ResponseViewOwn) {
    // --- contact icon ---
    return {
      email: {
        icon: this.path + 'mail.svg',
        isShow:
          data.emailEnable && data.email?.trim() != '' && data.email != null,
        href: data.email,
      },
      phone: {
        icon: this.path + 'phone.svg',
        isShow:
          data.phoneNumberEnable &&
          data.phoneNumber?.trim() != '' &&
          data.phoneNumber != null,
        href: data.phoneNumber,
      },
      zalo: {
        icon: this.path + 'zalo.svg',
        isShow: data.zaloEnable && data.zalo?.trim() != '' && data.zalo != null,
        href: data.zalo,
      },
      messenger: {
        icon: this.path + 'messenger.svg',
        isShow:
          data.messengerEnable &&
          data.messenger?.trim() != '' &&
          data.messenger != null,
        href: data.messenger,
      },
      linkProfile: {
        isShow:
          data.cardEnable &&
          data.profilelink?.trim() != '' &&
          data.profilelink != null,
        href: data.profilelink,
        icon: data.avatar,
      },
      // --- end contact icon ---
    };
  }

  // ---- view own + home ----
  async getPreviewContainerWebsite() {
    if (!localStorage.getItem(KeyStorage.website_id)) return;
    this.websiteService.viewOwnWebsite().subscribe((data: ResponseViewOwn) => {
      if (!data) return;
      this.previewContainer = {
        websiteName: data.websiteName,
        logo: data.logo,
        listBanner: data.banners?.map((banner: any) => {
          return banner.value;
        }),
        highLights: {
          type: ItemType.content,
          text: data.description,
          bgImage: '',
          backgroundColor: '',
        },
        // --- contact icon ---
        contactArray: this.renderContactArray(data),
        // --- end contact icon ---
      };
      this.getListCate();
    });
  }

  getListCate() {
    this.websiteService
      .getListCategory(
        localStorage.getItem(KeyStorage.subTab_id)!,
        localStorage.getItem(KeyStorage.username)!,
        1,
        1000
      )
      .subscribe((data) => {
        // Has no category
        if (data.totalCount === 0) {
          this.isHasCategory = false;
          this.loadingService.closeLoadingModal();
          return;
        }
        this.isHasCategory = true;
        this.previewContainer.tabs = [];
        data.data.forEach((tab: any) => {
          if (tab.enable) {
            this.previewContainer.tabs?.push({
              title: tab.categoryName,
              type:
                tab.type === CategoryType.LandingPage
                  ? CategoryType.LandingPage
                  : CategoryType.Post,
              id: tab.id,
              isSelected: false,
              code: tab.code,
            });
          }
        });
        this.loadingService.closeLoadingModal();
      });
  }

  // ---- end view own ----

  // ---- another view ----
  shareLink() {
    navigator.share({
      // text: 'Chia sẻ liên kết trang web mini',
      // url: this.router.url,
      url: this.getLinkViewWebsite()!,
    });
    console.log(this.getLinkViewWebsite()!);
  }

  shareQR() {
    // this.qrService.openQr(this.router.url, 'QR Code Web Mini');
    this.qrService.openQr(this.getLinkViewWebsite()!, 'QR Code Web Mini');
    console.log(this.getLinkViewWebsite()!);
  }

  getLinkViewWebsite() {
    return localStorage.getItem(KeyStorage.link_view_website);
  }

  extractUsernameFromUrl(url: string): string {
    const lastSlashIndex = url?.lastIndexOf('/');
    const username = url?.substring(lastSlashIndex + 1);
    return username;
  }

  getPreviewContainerWebsiteAnotherView() {
    this.websiteService
      .anotherViewWebsite(this.keyWebsite)
      .subscribe((data: ResponseViewOwn) => {
        if (!data) {
          this.isValidWebsite = false;
          return;
        }
        this.previewContainer = {
          websiteName: data.websiteName,
          logo: data.logo,
          listBanner: data.banners?.map((banner: any) => {
            return banner.value;
          }),
          highLights: {
            type: ItemType.content,
            text: data.description,
            bgImage: '',
            backgroundColor: '',
          },
          // --- contact icon ---
          contactArray: this.renderContactArray(data),
        };
        localStorage.setItem(KeyStorage.website_id, data.id!);
        localStorage.setItem(KeyStorage.username_view, this.extractUsernameFromUrl(data.profilelink!));
        localStorage.setItem(KeyStorage.memberId_view, data.memberId!);
        this.getListSubTabAnoView(data.id!);
      });
  }

  getListSubTabAnoView(websiteId: string) {
    this.websiteService.getListSubTab(websiteId, localStorage.getItem(KeyStorage.username_view)!).subscribe((data) => {
      if (!data) return;
      this.getListCateAnotherView(data[0].id);
      localStorage.setItem(KeyStorage.subTab_id, data[0].id);
    });
  }

  getListCateAnotherView(subtabId: string) {
    this.websiteService
      .getListCategory(subtabId, localStorage.getItem(KeyStorage.username_view)!, 1, 1000)
      .subscribe((data) => {
        if (!data) return;
        if (data.totalCount === 0) {
          this.isHasCategory = false;
          return;
        }
        this.isHasCategory = true;
        this.previewContainer.tabs = [];
        data.data.forEach((tab: any) => {
          if (tab.enable) {
            this.previewContainer.tabs?.push({
              title: tab.categoryName,
              type:
                tab.type === CategoryType.LandingPage
                  ? CategoryType.LandingPage
                  : CategoryType.Post,
              id: tab.id,
              isSelected: false,
              code: tab.code,
            });
          }
        });
        // set route khi view từ id tab
        this.setRouteByCodeTab();
      });
  }

  setRouteByCodeTab() {
    if (!this.previewContainer.tabs) return;
    if (!this.codeTabSelected) return;
    let tabSelected = this.previewContainer.tabs.find((tab) => {
      return tab.code === this.codeTabSelected;
    });
    tabSelected!.isSelected = true;
    this.selectedIndex = this.previewContainer.tabs.indexOf(tabSelected!);
  }

  setParamsURLWhenChangeTab(tabCurrent: any) {
    this.router.navigate([`/${this.keyWebsite}`], {
      queryParams: { t: tabCurrent.code },
    });
  }

  // ---- end another view ----
  changeTab(event: NzTabChangeEvent) {
    this.isDoneLoadingDetail = false;
    let tabCurrent = this.previewContainer.tabs?.[event.index!];
    if (!tabCurrent) return;
    tabCurrent!.isSelected = true;
    if (
      this.typePreview === TypePreview.Login ||
      this.typePreview === TypePreview.Unlogin
    ) {
      this.setParamsURLWhenChangeTab(tabCurrent);
    }
    if (this.typePreview === TypePreview.Home) {
      if (tabCurrent?.type === CategoryType.Post) {
        this.detailPost(tabCurrent.id);
      } else {
        this.detailLanding(tabCurrent!.id);
      }
    } else {
      this.resetTab();
    }
    localStorage.setItem(KeyStorage.category_code, tabCurrent!.code);
  }

  resetTab() {
    this.isDoneLoadingDetail = true;
    this.isEmptyLanding = false;
    this.isEmptyPost = false;
  }

  check($event: any) {
    this.selectedIndex = $event;
  }
  // ---- navigate to media ----
  navigateToZalo() {
    let linkZalo = this.previewContainer?.contactArray?.zalo?.href?.includes(
      'https://zalo.me/'
    )
      ? this.previewContainer?.contactArray?.zalo?.href
      : 'https://zalo.me/' + this.previewContainer?.contactArray?.zalo?.href;
    const win = window.open(linkZalo);
    win!.focus();
  }

  navigateToFacebook() {
    const win = window.open(
      this.previewContainer?.contactArray?.messenger?.href
    );
    win!.focus();
  }
  // ---- end navigate to media ----
  // ---- login when not logged in ----
  login() {
    this.authService.login();
  }

  checkIsLogin() {
    this.authService.isAuthenticated$.subscribe((data) => {
      if (data) {
        this.typePreview = TypePreview.Login;
      } else {
        this.typePreview = TypePreview.Unlogin;
      }
    });
  }
  // ---- end login when not logged in ----
  // ----- HOME ------
  addCategory() {
    let drawer = this.drawerService.open(
      CategoryModalCreateComponent,
      'Thêm mới danh mục',
      350
    );
    drawer.afterClose.subscribe((success) => {
      if (success) {
        this.loadingService.openLoadingModal();
        this.getPreviewContainerWebsite();
        // set default
        this.isDoneLoadingDetail = true;
        this.isEmptyLanding = true;
        this.isEmptyPost = true;
      }
    });
  }

  detailPost(id: string) {
    this.postService.getList({ cateId: id }).subscribe((data: any) => {
      if (data.totalCount === 0) {
        this.isEmptyPost = true;
      } else {
        this.isEmptyPost = false;
      }
      this.isDoneLoadingDetail = true;
    });
  }

  detailLanding(id: string) {
    this.landingService.getDetail(id).subscribe((data: any) => {
      if (data.length === 0) {
        this.isEmptyLanding = true;
      } else {
        this.isEmptyLanding = false;
      }
      this.isDoneLoadingDetail = true;
    });
  }

  category() {
    this.router.navigate(['pages/category']);
  }

  createLandingPage() {
    localStorage.setItem(KeyStorage.from, String(CreateLandingFrom.Home));
    this.router.navigate(['/pages/landing-page/add']);
  }

  createPost(tab: any, tabindex: number) {
    this.router.navigate(['pages/post/create'], {
      queryParams: {
        code: tab?.code,
        tabIndex: tabindex,
      },
    });
  }

  arrangePost(id: string, index: number) {
    this.router.navigate(['pages/post/arrange', id as string], {
      queryParams: {
        index: index,
      },
    });
  }

  editLandingPage(tabdetail: any) {
    this.router.navigate(['pages/landing-page/edit']);
    this.commonService.goToEditLandingComponent(tabdetail.id);
  }

  handleSlideToPostTab() {
    this.selectedIndex =
      Number(localStorage.getItem(KeyStorage.index_tab_slide_to)) || 0;
    localStorage.setItem(KeyStorage.index_tab_slide_to, String(0));
    // this.previewLogicSer.isAddPostSuccess$.subscribe((data) => {
    //   if (!data.isSuccess) return;
    //   if (!this.previewContainer.tabs) return;
    //   let tabSelected = this.previewContainer.tabs.find((tab) => {
    //     return tab.code === data.postCode;
    //   });
    //   tabSelected!.isSelected = true;
    //   this.ngZone.run(() => {
    //     this.selectedIndex = this.previewContainer.tabs!.indexOf(tabSelected!);
    //     // console.log(this.selectedIndex);
    //   });
    // });
  }

  handleManagePost() {
    this.selectedIndex =
      Number(localStorage.getItem(KeyStorage.index_tab_slide_to)) || 0;
    localStorage.setItem(KeyStorage.index_tab_slide_to, String(0));
    // this.businessService.managePost$.subscribe({
    //   next: (data) => {
    //     if (data) {
    //       this.codeTabSelected = String(data);
    //       let tabSelected = this.previewContainer.tabs!.find((tab) => {
    //         return tab.code === this.codeTabSelected;
    //       });
    //       tabSelected!.isSelected = true;
    //       // this.selectedIndex = 2;
    //       // let index = this.previewContainer.tabs!.indexOf(tabSelected!);
    //       // this.selectedIndex = index;
    //       // console.log(this.selectedIndex);
    //       // setTimeout(() => {
    //       //   this.selectedIndex = 2;
    //       // }, 1000);
    //     }
    //   },
    // });
    // this.selectedIndex = 2;
  }

  handleUpdatePost() {
    this.businessService.updatePost$.subscribe({
      next: (data) => {
        if (data) {
          this.getPreviewContainerWebsite();
        }
      },
    });
  }

  handleLoadingAgain() {
    this.previewLogicSer.isLoadingAgain$.subscribe((data) => {
      if (!data) return;
      this.getPreviewContainerWebsite();
      // this.handleSlideToPostTab();
      // window.location.reload();
    });
  }
  // ----- END HOME -----
}
export enum TypePreview {
  Login,
  Unlogin,
  Viewown,
  Home,
}
