import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { KeyStorage } from 'src/app/core/enums';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { TypeComponent } from '../../../enums/typeComponents.enum';
import { ItemType, TypeContent } from '../../../models/screenEdit';
import Quill from 'quill';

// const FontAttributor = Quill.import('attributors/class/font');
// FontAttributor.whitelist = [
//   'IRANSans',
//   'roboto',
//   'cursive',
//   'fantasy',
//   'monospace',
// ];
// Quill.register(FontAttributor, true);
@Component({
  selector: 'app-add-content',
  templateUrl: './edit-type-content.component.html',
  styleUrls: ['./edit-type-content.component.scss'],
})
export class EditTypeContentComponent implements OnInit, OnChanges {
  @Input() type: TypeComponent = TypeComponent.Create;
  @Input() idSelected: string = '';
  item: TypeContent = new TypeContent();
  TypeComponent = TypeComponent;
  editorForm: FormGroup = this.fb.group({
    landingID: localStorage.getItem('landingpage_id'),
  });
  text: any = '';

  constructor(
    private fb: FormBuilder,
    private drawRef: NzDrawerRef,
    private landingService: LandingPageService,
    private noti: NotiService
  ) {}

  ngOnInit(): void {
    if (!this.idSelected || this.type === TypeComponent.Create) return;
    this.getDetail();
  }

  ngOnChanges(changes: SimpleChanges) {}

  getDetail() {
    // -------- call api -----------
    this.landingService.getDetailContent(this.idSelected).subscribe((data) => {
      this.item = {
        id: data.id,
        type: ItemType.content,
        text: data.value,
      };
      // ----------- patch value --------
      this.text = this.item.text!;
    });
  }

  update() {
    let body = {
      id: this.item.id,
      landingID: this.idSelected,
      value: this.text,
    };
    // API UPDATE
    this.landingService.updateContent(body).subscribe(
      (data) => {
        this.noti.success();
        this.emitSuccess();
      },
      (err) => {
        this.noti.warning();
      }
    );
  }
  cancel() {
    this.drawRef.close();
  }

  create() {
    // ------- create landing item ------
    this.landingService
      .addItem({
        categoryID: localStorage.getItem(KeyStorage.cetagory_id),
        type: ItemType.content,
        priority: localStorage.getItem(KeyStorage.priority),
      })
      .subscribe(
        (data) => {
          if (!data) return;
          // ------- create type content ------
          this.landingService
            .addContent({
              landingID: data.id,
              value: this.text,
              alignmentRight: 3,
              alignmentLeft: 3,
            })
            .subscribe(
              (data) => {
                if (!data) return;
                this.noti.success('Thêm mới thành công');
                this.emitSuccess();
              },
              (err) => {
                this.noti.warning();
              }
            );
        },
        (err) => {
          this.noti.warning();
        }
      );
  }

  emitSuccess() {
    this.drawRef.close(true);
  }

  changeText($event: any) {
    this.text = $event;
  }
}

export class IsSaveContent {
  isSave?: boolean;
  content?: any;
}
