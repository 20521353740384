import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from 'src/environments/environment';
import { EndPoint, KeyInfoUser } from '../enums';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  private url = `${ENV.API_URL}${EndPoint.post}`;

  constructor(
    private http:HttpClient,
    private helperService: HelperService
    ) { }

  // tạo bài đăng
  public create(params:any){
    return this.http.post(`${this.url}/create`, params)
  }

  // update bài đăng
  public update(body:any){
    return this.http.post(`${this.url}/update`, body)
  }

  public getList(params:any){
    const qr = this.helperService.generateQueryString({
      ...params,
      username: this.helperService.getInfoUserByKey(KeyInfoUser.user_name)
    })
    return this.http.get(`${this.url}/list-all?${qr}`)
  }

  // Xóa bài đăng
  public delete(postId:string):Observable<any>{
    return this.http.delete(`${this.url}/${postId}`);
  }

  // search bài đăng
  public search(params:any):Observable<any>{
    const qr = this.helperService.generateQueryString(params)
    return this.http.get(`${this.url}/create?${qr}`);
  }

  // image bài đăng
  public image(form:any){
    return this.http.post(`${this.url}/image`, form)
  }

  // sắp xếp
  public swap(formData:any):Observable<any>{
    return this.http.post(`${this.url}/swap`, formData)
  }

  // chi tiết
  public detail(id:string, params:any):Observable<any>{
    const qr = this.helperService.generateQueryString(params)
    return this.http.get(`${this.url}/${id}?${qr}`);
  }


}
