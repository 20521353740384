import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { type } from 'os';
import { CATEGORY_TYPE } from 'src/app/core/const';
import { KeyStorage } from 'src/app/core/enums';
import { ECategoryType } from 'src/app/core/enums/category.enum';
import { CategoryService } from 'src/app/core/services/category.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-category-modal-create',
  templateUrl: './category-modal-create.component.html',
  styleUrls: ['./category-modal-create.component.scss'],
})
export class CategoryModalCreateComponent implements OnInit {
  @Input() accept!: () => void;
  @Input() default: ECategoryType = ECategoryType.Post;
  public categoryTypes = CATEGORY_TYPE;
  public form!: FormGroup;

  constructor(
    private drawerRef: NzDrawerRef<string>,
    private fb: FormBuilder,
    private storageService: StorageService,
    private categoryService: CategoryService,
    private notiService: NotiService
  ) {
    this.form = this.fb.nonNullable.group({
      subTabId: [this.storageService.getLocal(KeyStorage.subTab_id)],
      categoryName: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(50)]),
      ],
      type: ['', Validators.compose([Validators.required])],
      enable: [true],
    });
  }

  ngOnInit(): void {
    console.log(this.default);
    this.form.patchValue({
      type: this.default,
    });
  }

  close() {
    this.drawerRef.close();
  }

  submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.categoryService.create(this.form.value).subscribe({
      next: (resp: any) => {
        this.drawerRef.close({
          isSucess: true,
          addedCategoryId: resp.id,
        });
        this.notiService.success('Tạo danh mục thành công');
      },
      error: (err) => {
        this.notiService.warning(err.error);
      },
    });
  }

  toggleSwitch(e: boolean) {
    this.form.get('enable')?.patchValue(e);
  }

  get categoryNameControl(): FormControl {
    return this.form.get('categoryName') as FormControl;
  }
}
