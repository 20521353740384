import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BoxInPost } from '../../../models/preview';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/core/services/helper.service';
import { KeyInfoUser, KeyStorage } from 'src/app/core/enums';
import { TypePreview } from '../../preview-container/preview-container.component';
import { PostService } from 'src/app/core/services/post.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { switchMap } from 'rxjs';
import { BusinessService } from '../../../../../core/services/business.service';
import { PreviewLogicService } from '../../../services/preview-logic.service';

@Component({
  selector: 'app-box-view-post',
  templateUrl: './box-view-post.component.html',
  styleUrls: ['./box-view-post.component.scss'],
})
export class BoxViewPostComponent implements OnInit {
  @Input() post: BoxInPost = new BoxInPost();
  @Input() typePreview: TypePreview = TypePreview.Viewown;
  @Input() keyWebsite: string = '';
  public TypePreview = TypePreview;
  public checked = true;

  errorLoading = './../../../../../../assets/images/error_loading.png';
  public ETypePreview = TypePreview;
  public form!: FormGroup;
  constructor(
    private router: Router,
    private helperService: HelperService,
    private postService: PostService,
    private notificationService: NotiService,
    private fb: FormBuilder,
    private notiService: NotiService,
    private businessService: BusinessService,
    private previewLogicService: PreviewLogicService
  ) {
    this.form = this.fb.group({
      id: '',
      hidden: false,
      outOfStock: false,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
  }

  ngOnInit(): void {
    this.form.patchValue(this.post);
    this.formChangeValue();
  }

  formChangeValue() {
    let postTemp = { ...this.post };
    delete postTemp.thumbnail;
    this.form.valueChanges
      .pipe(
        switchMap(() => {
          return this.postService.update({
            ...postTemp,
            ...this.form.value,
          });
        })
      )
      .subscribe({
        next: () => {
          this.notiService.success('Cập nhật thành công');
          this.router.navigate(['pages/home']);
          this.businessService.updatePost(true);
        },
        error: () => {
          this.notiService.error('Cập nhật thất bại');
        },
      });
  }

  view(postId: string | any) {
    this.router.navigate(['/view/post', postId as string], {
      queryParams: {
        username: this.helperService.getInfoUserByKey(KeyInfoUser.user_name),
      },
    });
  }

  detailPostNavigate(postId: any) {
    this.router.navigate(['/view/post', postId as string], {
      queryParams: {
        username: this.helperService.getInfoUserByKey(KeyInfoUser.user_name),
      },
    });
    // if (
    //   this.typePreview === TypePreview.Viewown ||
    //   this.typePreview === TypePreview.Home
    // ) {
    //   this.router.navigate(['/view/post', postId as string], {
    //     queryParams: {
    //       username: this.helperService.getInfoUserByKey(KeyInfoUser.user_name),
    //     },
    //   });
    // } else {
    //   localStorage.setItem(KeyStorage.post_detail_id, postId);
    //   this.router.navigate([`${this.keyWebsite}/detail-post`]);
    // }
  }

  // update bài đăng
  update(post: any) {
    this.router.navigate(['/pages/post/update/', post?.id]);
  }

  // delete bài đăng
  delete(post: any) {
    this.postService.delete(post?.id as string).subscribe({
      next: () => {
        this.previewLogicService.setIsLoadingAgainSource(true);
        this.notificationService.success('Xóa thành công bài đăng');
        // this.checkQueryString();
      },
      error: (err) => {
        this.notificationService.warning('Xóa lỗi');
      },
    });
  }
}
