import {Validators} from "@angular/forms";
import {FORMAT_EMAIL} from "../const";

export enum WebsiteAction {
  save = 'save',
}

export enum ContacIcon {
  phone = './../../../assets/icons/ic-phone.svg',
  email = './../../../assets/icons/ic-email.svg',
  messenger = './../../../assets/icons/ic-messenger.svg',
  zalo = './../../../assets/icons/ic-zalo.svg',
}

export enum EWebsiteFormControl {
  email = "email",
  banners = "banners",
  description = "description",
  logo = "logo",
  messenger = "messenger",
  phoneNumber = "phoneNumber",
  websiteName = "websiteName",
  zalo = "zalo",
  card = "card",
}