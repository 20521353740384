import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';

import * as signalR from '@microsoft/signalr';
import { HubConnection, Subject } from '@microsoft/signalr';

import { environment as ENV } from 'src/environments/environment.local';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  hubUrl: string;
  connection?: HubConnection;
  messages: Subject<any> = new Subject();
  private url = ENV.CONNECT_URL
  private url_connect = this.url + '/notificationHub'
  headers = new HttpHeaders({
    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
  })
  userId: string
  private listTabSource = new BehaviorSubject<{
    [id: string | number]: any
  }>({});
  private listNotiSource = new BehaviorSubject<{
    [id: string | number]: any
  }>({});
  private total = new BehaviorSubject<any>(0);
  listTab: {
    [id: string | number]: any
  } = {
      'all': {
        name: 'Tất cả',
        count: 0
      }
    }
  listNoti: {
    [id: string | number]: any
  } = {
      'all': []
    }

  public total$ = this.total.asObservable();
  public listTab$ = this.listTabSource.asObservable();
  public listNoti$ = this.listNotiSource.asObservable();

  constructor(
    private _http: HttpClient,
    private _jwt: JwtHelperService
  ) {
    this.hubUrl = this.url_connect;
    this.userId = this._jwt.decodeToken(localStorage.getItem('access_token')!)?.sub
  }

  public async init(): Promise<void> {
    let Interval = setInterval(() => {
      if (localStorage.getItem('access_token')) {
        this.headers = new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        })
        this.getTypeNoti(0, 100000).subscribe((data: any[]) => {
          data.forEach(element => {
            this.listTab[element?.id] = element
            this.listNoti[element?.id] = {}
          });
          this.listTabSource.next(this.listTab)
          this.listNotiSource.next(this.listNoti)
          try {
            this.connection = new signalR.HubConnectionBuilder()
              .withUrl(this.hubUrl, {
                withCredentials: false,
                headers: {
                  "Authorization": 'Bearer ' + localStorage.getItem('access_token')!
                },
                accessTokenFactory() {
                  return localStorage.getItem('access_token')!
                },
                // skipNegotiation: true,
                // transport: signalR.HttpTransportType.WebSockets,
              })
              .withAutomaticReconnect()
              .build();

            this.connection.start().then(() => {

              this.getTabCount()

              this.connection?.on("notify", (data: any) => {
                this.getTabCount()
                Object.keys(this.listTab).forEach((e: any) => {
                  if (this.listTab[e]?.name === data.notiType) {
                    this.GetNotificationNotSeen({ type: e })
                    setTimeout(() => {
                      this.getListNoti({
                        type: e,
                        page: 0,
                        pageSize: 100000000
                      })
                    }, 500);
                    setTimeout(() => {
                      this.getListNoti({
                        page: 0,
                        pageSize: 100000000
                      })
                    }, 500);
                  }
                })
              });

              this.connection?.on("notify-list", (data: any) => {
                if (data.type !== null) {
                  this.listNoti[data.type] = data.data
                  this.listNotiSource.next(this.listNoti);
                }
                this.getTabCount()
              });

              this.connection?.on("notify-count", (data: any) => {
                this.listTab[data.type].count = data.count
                this.listTabSource.next(this.listTab)
              });
            });

          }
          catch (error) {
            console.log(`SignalR connection error: ${error}`);
          }
        })
        clearInterval(Interval)
      }
    }, 200)
  }

  getListNoti(body: any) {
    body.userId = this.userId
    this.connection?.invoke('GetNotificationByUser', body, this.connection?.connectionId)
  }

  GetNotificationNotSeen(body: any) {
    body.userId = this.userId
    this.connection?.invoke('GetNotificationNotSeenByUser', body, this.connection?.connectionId)
      .then((v: any) => { })
      .catch((r: any) => { })
  }

  getTypeNoti(page: any, pageSize: any): Observable<any> {
    return this._http
      .get(this.url + '/gateway/delivery/notificationtype/search?page=' + page + '&pageSize=' + pageSize, { headers: this.headers })
      .pipe((response: any) => response);
  }

  getTabCount() {
    let j = 0
    let countAll = 0
    let listTabKey = Object.keys(this.listTab)
    let Interval = setInterval(() => {
      if (j < listTabKey.length) {
        this.GetNotificationNotSeen({ type: listTabKey[j] })
        j += 1
      } else {
        listTabKey.forEach((e: any) => {
          if (e !== 'all') {
            countAll += this.listTab[e].count
          }
        })
        this.listTab['all'].count = countAll
        this.total.next(countAll)
        this.listTabSource.next(this.listTab);
        clearInterval(Interval)
      }
    }, 500);
  }

  UpdateSeenNoti(body: any) {
    this.connection?.invoke('UpdateSeenOneNotification', {
      notificationId: body.notificationId,
      userId: this.userId
    }, this.connection?.connectionId)
      .then((v: any) => { })
      .catch((r: any) => { })
    this.getTabCount()
  }
}