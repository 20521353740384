import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { NotiService } from 'src/app/core/services/noti.service';
import { TypeFooter } from '../../enums/typeFooter.enum';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Input() idSelected: string = '';
  @Input() typeFooter: TypeFooter = TypeFooter.Content;
  item: any = '';
  color = 'rgba(255, 255, 255, 0)';
  constructor(
    private drawerRef: NzDrawerRef,
    private landingService: LandingPageService,
    private noti: NotiService
  ) {}

  ngOnInit(): void {
    switch (this.typeFooter) {
      case TypeFooter.Action: {
        this.getDetailAction();
        break;
      }
      case TypeFooter.Content: {
        this.getDetailContent();
        break;
      }
      case TypeFooter.Image: {
        this.getDetailImage();
        break;
      }
      case TypeFooter.Link: {
        this.getDetailLink();

        break;
      }
      case TypeFooter.Button: {
        this.getDetailButton();

        break;
      }
    }
  }

  cancel() {
    this.drawerRef.close();
  }

  // ----- update 5 type -----
  update() {
    switch (this.typeFooter) {
      case TypeFooter.Content: {
        this.updateTypeContent();
        break;
      }
      case TypeFooter.Action: {
        this.updateTypeAction();
        break;
      }
      case TypeFooter.Image: {
        this.updateTypeImage();
        break;
      }
      case TypeFooter.Button: {
        this.updateTypeButton();
        break;
      }
      case TypeFooter.Link: {
        this.updateTypeLink();
        break;
      }
    }
  }

  updateTypeContent() {
    let body = {
      id: this.item.id,
      landingID: this.idSelected,
      value: this.item.value,
      title: this.item.title,
      background: '',
      backgroundColor: this.color,
    };
    this.landingService.updateContent(body).subscribe(
      (next) => {
        this.noti.success();
        this.drawerRef.close(true);
      },
      (error) => this.noti.warning()
    );
  }
  updateTypeAction() {
    let body = {
      id: this.item.id,
      landingID: this.idSelected,
      title: this.item.title,
      actionInfor: this.item.actionInfor,
      background: '',
      backgroundColor: this.color,
      actions: this.item.actions,
    };
    this.landingService.updateAction(body).subscribe(
      (next) => {
        this.noti.success();
        this.drawerRef.close(true);
      },
      (error) => this.noti.warning()
    );
  }
  updateTypeImage() {
    let body = {
      landingID: this.idSelected,
      backgroundColor: this.color,
      backgroundImage: '',
      value: this.item.map((image: any) => {
        return image.value;
      }),
    };
    // console.log(this.item);
    this.landingService.updateListImage(body).subscribe(
      (next) => {
        this.noti.success();
        this.drawerRef.close(true);
      },
      (error) => this.noti.warning()
    );
  }
  updateTypeButton() {
    let body = {
      id: this.item[0].id,
      landingID: this.idSelected,
      backgroundColorButton: this.color,
      backgroundImageButton: '',
      height: this.item[0].height,
    };
    this.landingService.updateButton(body).subscribe(
      (next) => {
        this.noti.success();
        this.drawerRef.close(true);
      },
      (error) => this.noti.warning()
    );
  }
  updateTypeLink() {
    let body = {
      id: this.item[0].id,
      landingID: this.idSelected,
      backgroundImage: '',
      backgroundColor: this.color,
    };
    this.landingService.updateConnective(body).subscribe(
      (next) => {
        this.noti.success();
        this.drawerRef.close(true);
      },
      (error) => this.noti.warning()
    );
  }
  // ---- end update 5 type ----
  // ---- get detail 5 type ----
  getDetailAction() {
    this.landingService.getDetailAction(this.idSelected).subscribe((data) => {
      this.item = data;
      if (!data.backgroundColor) return;
      this.color = data.backgroundColor;
    });
  }

  getDetailContent() {
    this.landingService.getDetailContent(this.idSelected).subscribe((data) => {
      this.item = data;
      if (!data.backgroundColor) return;
      this.color = data.backgroundColor;
    });
  }
  getDetailImage() {
    this.landingService.getDetailImage(this.idSelected).subscribe((data) => {
      this.item = data;
      if (!data[0].backgroundColor) return;
      this.color = data[0].backgroundColor;
      console.log(this.color);
    });
  }
  getDetailLink() {
    this.landingService
      .getDetailConnective(this.idSelected)
      .subscribe((data) => {
        this.item = data;
        if (!data[0].backgroundColor) return;
        this.color = data[0].backgroundColor;
      });
  }
  getDetailButton() {
    this.landingService.getDetailButton(this.idSelected).subscribe((data) => {
      this.item = data;
      if (!data[0].backgroundColorButton) return;
      this.color = data[0].backgroundColorButton;
    });
  }
  // ----end get detail 5 type ----
}
