import { RouterModule, Routes } from '@angular/router';
import { AddLandingPageComponent } from '../add-landing-page/add-landing-page.component';
import { EditContainerComponent } from './edit-container.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'add',
    pathMatch: 'full',
  },
  {
    path: 'add',
    component: AddLandingPageComponent,
  },
  {
    path: 'edit',
    component: EditContainerComponent,
  },
];

export const EditContainerRoutes = RouterModule.forChild(routes);
