import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as ENV } from 'src/environments/environment';
import { EndPoint } from '../enums/endpoint.enum';
import { KeyStorage } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class WebsiteService {
  private website_url = `${ENV.API_URL}${EndPoint.business}`;
  private subtab_url = `${ENV.API_URL}${EndPoint.subtab}`;
  private category_url = `${ENV.API_URL}${EndPoint.category}`;
  private post_url = `${ENV.API_URL}${EndPoint.post}`;
  constructor(private http: HttpClient) {}
  // ---- view own website -> has authorize -----
  viewOwnWebsite(): Observable<any> {
    return this.http.get(
      this.website_url + `/own/${localStorage.getItem(KeyStorage.website_id)}`
    );
  }

  // ---- end view own website -> has authorize ----
  // ---- ano view ----
  anotherViewWebsite(key: string): Observable<any> {
    // Đang fix tạm
    return this.http.get(this.website_url + '/ano/' + key);
  }

  getWebsiteHasLinkInresponse(): Observable<any> {
    return this.http.get(
      this.website_url +
        `/${localStorage.getItem(KeyStorage.website_id)}` +
        '?username=' +
        localStorage.getItem(KeyStorage.username_view)!
    );
  }
  // ---- end ano view ----
  getAll(username: string): Observable<any> {
    return this.http.get(
      this.website_url + '/website/list-all?username=' + username
    );
  }

  getListSubTab(websiteId: string, username: string): Observable<any> {
    return this.http.get(
      this.subtab_url +
        '/list-all?websiteId=' +
        websiteId +
        '&username=' +
        username
    );
  }

  getListCategory(
    subtabId: string,
    username: string,
    page: number,
    pageSize: number
  ): Observable<any> {
    return this.http.get(
      this.category_url +
        '/list?subTabId=' +
        subtabId +
        '&username=' +
        username +
        '&Page=' +
        page +
        '&PageSize=' +
        pageSize
    );
  }

  getListCategoryUninitialized(
    subtabId: string,
    username: string
  ): Observable<any> {
    return this.http.get(
      this.category_url +
        '/list-uninitialized?subtabId=' +
        subtabId +
        '&username=' +
        username
    );
  }

  // ------- Post view -------
  getListPost(cateId: string, page: number, pageSize: number, userName: string): Observable<any> {
    return this.http.get(
      this.post_url +
        '/list-all?cateId=' +
        cateId +
        '&subTabId=' +
        localStorage.getItem(KeyStorage.subTab_id) +
        '&page=' +
        page +
        '&pageSize=' +
        pageSize +
        '&username=' +
        userName
    );
  }

  getDetailPost(postId: string): Observable<any> {
    return this.http.get(
      this.post_url +
        '/' +
        postId +
        '?username=' +
        localStorage.getItem(KeyStorage.username_view)!
    );
  }
}
