import { Clipboard } from '@angular/cdk/clipboard';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';
import { LandingPageService } from 'src/app/core/services/landingPage.service';
import { QrService } from 'src/app/core/services/qr.service';
import { ShareLinkService } from 'src/app/core/services/shareLink.service';
import { ItemType } from 'src/app/features/landing-page/models/screenEdit';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-landing-page-view',
  templateUrl: './landing-page-view.component.html',
  styleUrls: ['./landing-page-view.component.scss'],
})
export class LandingPageViewComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild('CTAElement', { read: ElementRef, static: false })
  ctaElement!: ElementRef;
  @Input() isShowCoppyBar: boolean = true;
  @Input() id: string = '';
  @Input() isSelected: boolean = false;
  @Input() landingLink: string = '';
  ItemType = ItemType;
  listAllItems: any[] = [];
  listObserver: any;
  isCompleteCallAPISource = new BehaviorSubject<boolean>(false);
  isCompleteCallAPI$ = this.isCompleteCallAPISource.asObservable();
  linkLanding: string = '';
  constructor(
    private landingService: LandingPageService,
    private clipbboard: Clipboard,
    private message: NzMessageService,
    private cdr: ChangeDetectorRef,
    private shareLinkService: ShareLinkService,
    private modal: NzModalService,
    private qrService: QrService
  ) {}

  ngOnInit(): void {
    // this.isCompleteCallAPI$.subscribe((data) => {
    //   if (!data) return;
    //   this.handleIntersection();
    // });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.id && this.isSelected) {
      setTimeout(() => {
        this.getDetail();
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isCompleteCallAPI$.subscribe((data) => {
        if (!data) return;
        this.handleIntersection();
      });
    });
  }

  getDetail() {
    this.listAllItems = [];
    this.landingService.getDetail(this.id).subscribe((data) => {
      if (data) {
        this.listAllItems = data;
        this.isCompleteCallAPISource.next(true);
        this.cdr.detectChanges();
      }
    });
  }

  // ---------------- COMMON ----------------
  handleIntersection() {
    setTimeout(() => {
      this.listObserver = document.querySelectorAll('.container');
      // console.log(this.listObserver);
      // console.log(this.listAllItems);
      const observer = new IntersectionObserver((entries) => {
        // console.log(entries);
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let itemId = entry.target.id;
            let item = this.listAllItems.find((item) => item.id === itemId);
            let index = this.listAllItems.indexOf(item);
            item.isIntersection = true;
            if (index > 0) {
              let itemPrevious = this.listAllItems[index - 1];
              if (!itemPrevious) return;
              itemPrevious.isIntersection = true;
            }
            if (index < this.listAllItems.length) {
              let itemNext = this.listAllItems[index + 1];
              if (!itemNext) return;
              itemNext.isIntersection = true;
            }
          }
        });
      });
      this.listObserver.forEach((item: any) => {
        observer.observe(item);
      });
    });
  }

  coppyLinkLanding() {
    if (!this.landingLink) return;
    this.message.create(
      'success',
      `Sao chép liên kết landing page thành công !`
    );
    this.clipbboard.copy(this.landingLink);
  }

  shareLink() {
    this.shareLinkService.shareLink(this.landingLink);
  }

  shareQR() {
    this.qrService.openQr(this.landingLink, 'QR Code Landing Page');
  }
}
