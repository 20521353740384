<form [formGroup]="form">
  <div class="font-semibold text-base">Xem trước</div>
  <div class="my-2">
    <!-- ----- preview ------ -->
    <button
      class="w-full text-[{{ form.get('textColor')?.value }}] h-[30px]"
      [ngStyle]="{
        'background-color': form.get('bgColor')?.value
          ? form.get('bgColor')?.value
          : '',
        color: form.get('textColor')?.value,
        'border-radius': borderRadius ? borderRadius + 'px' : 0 + 'px',
        height: height + 'px',
        'font-size': fontSize + 'px'
      }"
    >
      {{ form.get("buttonText")?.value }}
    </button>
    <!-- ------ end preview ------- -->
    <!-- ------ edit area ------ -->
    <div class="flex flex-col gap-y-4 mt-3">
      <div>
        <div class="mb-1">
          <span class="text-red-500 mr-2">*</span>
          <span>Tiêu đề nút bấm</span>
        </div>
        <input nz-input formControlName="buttonText" />
      </div>
      <div>
        <div class="mb-1">
          <span class="text-red-500 mr-2">*</span>
          <span>Bo góc</span>
        </div>
        <div class="flex items-center">
          <nz-slider
            [nzMin]="1"
            [nzMax]="100"
            [(ngModel)]="borderRadius"
            [ngModelOptions]="{ standalone: true }"
            class="grow"
          ></nz-slider>
          <nz-input-number
            [nzMin]="1"
            [nzMax]="100"
            [ngStyle]="{ marginLeft: '16px' }"
            [(ngModel)]="borderRadius"
            [ngModelOptions]="{ standalone: true }"
            [nzFormatter]="formatterPixel"
          ></nz-input-number>
        </div>
      </div>
      <div>
        <div class="mb-1">
          <span class="text-red-500 mr-2">*</span>
          <span>Chiều cao</span>
        </div>
        <div class="flex items-center">
          <nz-slider
            [nzMin]="30"
            [nzMax]="100"
            [(ngModel)]="height"
            [ngModelOptions]="{ standalone: true }"
            class="grow"
          ></nz-slider>
          <nz-input-number
            [nzMin]="30"
            [nzMax]="100"
            [ngStyle]="{ marginLeft: '16px' }"
            [(ngModel)]="height"
            [ngModelOptions]="{ standalone: true }"
            [nzFormatter]="formatterPixel"
          ></nz-input-number>
        </div>
      </div>
      <div>
        <div class="mb-1">
          <span class="text-red-500 mr-2">*</span>
          <span>Cỡ chữ</span>
        </div>
        <div class="flex items-center">
          <nz-slider
            [nzMin]="14"
            [nzMax]="40"
            [(ngModel)]="fontSize"
            [ngModelOptions]="{ standalone: true }"
            class="grow"
          ></nz-slider>
          <nz-input-number
            [nzMin]="14"
            [nzMax]="40"
            [ngStyle]="{ marginLeft: '16px' }"
            [(ngModel)]="fontSize"
            [ngModelOptions]="{ standalone: true }"
            [nzFormatter]="formatterPixel"
          ></nz-input-number>
        </div>
      </div>
      <div>
        <div class="mb-1">Màu nền</div>
        <div class="flex items-center h-10">
          <input
            formControlName="bgColor"
            type="color"
            class="grow mr-2 h-full"
          />
          <span>{{ form.get("bgColor")?.value }}</span>
        </div>
      </div>
      <div>
        <div class="mb-1">Màu chữ</div>
        <div class="flex items-center h-10">
          <input
            formControlName="textColor"
            type="color"
            class="grow mr-2 h-full"
          />
          <span>{{ form.get("textColor")?.value }}</span>
        </div>
      </div>
    </div>
    <!-- ------ end edit area ------ -->
  </div>

  <button class="button-default w-full py-2 mt-2 mb-7" (click)="setDefault()">
    <i class="fa-solid fa-rotate mr-2"></i>
    <span>Để mặc định</span>
  </button>
</form>
<!-- footer -->
<div class="absolute bottom-0 left-0 right-0 bg-white">
  <div class="flex items-center justify-between px-5 py-2">
    <button
      class="button-outline-default grow hover:opacity-70 mr-4"
      (click)="cancel()"
    >
      Hủy
    </button>

    <button
      *ngIf="type === TypeComponent.Edit"
      [disabled]="form.invalid"
      class="button-success grow hover:opacity-70"
      (click)="update()"
    >
      Cập nhật
    </button>
    <button
      *ngIf="type === TypeComponent.Create"
      [disabled]="form.invalid"
      class="button-success grow hover:opacity-70"
      (click)="create()"
    >
      Thêm mới
    </button>
  </div>
</div>
