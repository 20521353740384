<form [formGroup]="form" class="mb-12 flex flex-col gap-y-4">
  <div>
    <div class="mb-1">Tiêu đề</div>
    <input type="text" nz-input formControlName="label" />
  </div>
  <div>
    <div class="mb-1">
      <span class="text-red-500 mr-1">*</span>
      <span> Video </span>
    </div>
    <input (blur)="changeLink()" formControlName="value" type="text" nz-input />
  </div>
  <div
    *ngIf="
      form.get('value')?.value.includes('www.youtube.com') ||
      form.get('value')?.value.includes('youtu.be')
    "
  >
    <ng-container *ngIf="safeSrc">
      <iframe
        class="mt-3"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        [src]="safeSrc | safe"
        frameborder="3"
        style="width: 100%; height: 100%"
      ></iframe>
    </ng-container>
  </div>
</form>
<!-- footer -->
<div class="absolute bottom-0 left-0 right-0 bg-white">
  <div class="flex items-center justify-between px-5 py-2">
    <button
      class="button-outline-default grow hover:opacity-70 mr-4"
      (click)="cancel()"
    >
      Hủy
    </button>
    <button
      [disabled]="form.invalid"
      *ngIf="type === TypeComponent.Edit"
      class="button-success grow hover:opacity-70"
      (click)="update()"
    >
      Cập nhật
    </button>
    <button
      [disabled]="form.invalid"
      *ngIf="type === TypeComponent.Create"
      class="button-success grow hover:opacity-70"
      (click)="create()"
    >
      Thêm mới
    </button>
  </div>
</div>
