<div class="width-editor box-border">
  <quill-editor
    [(ngModel)]="htmlText"
    placeholder="Nhập mô tả ..."
    [modules]="quillConfig"
    format="json"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onContentChanged)="onContentChanged($event)"
  >
  </quill-editor>
</div>