<nz-skeleton [nzActive]="true" [nzLoading]="isLoading"></nz-skeleton>
<div
  *ngIf="!isLoading"
  class="w-full text-center flex justify-center items-center"
  id="button-{{ id }}"
  [ngStyle]="{
    'background-image':
      item.backgroundImageButton && !item.backgroundColorButton
        ? 'url(' + item.backgroundImageButton + ')'
        : '',
    'background-color': item.backgroundColorButton
      ? item.backgroundColorButton
      : '',
    'padding-top': item.alignmentTop ? item.alignmentTop + 'px' : '0px',
    'padding-bottom': item.alignmentBottom
      ? item.alignmentBottom + 'px'
      : '0px',
    'padding-right': item.alignmentRight ? item.alignmentRight + '%' : '0px',
    'padding-left': item.alignmentLeft ? item.alignmentLeft + '%' : '0px'
  }"
>
  <button
    (click)="doBtnFunction()"
    class="w-full blink shadow-md flex items-center justify-center"
    [ngStyle]="{
      'background-color': item.bgColor,
      color: item.textColor,
      'border-radius': item.borderRadius ? item.borderRadius + 'px' : 0 + 'px',
      height: item.height + 'px',
      'font-size': item.fontSize + 'px'
    }"
  >
    {{ item.buttonText }}
  </button>
</div>
