<div>
  <div class="flex items-center">
    <span class="mr-3">Màu nền:</span>
    <input type="color" [(ngModel)]="color" />
  </div>
  <div class="flex items-center">
    <span class="mr-3">Mã màu: </span>
    <span>{{ color }}</span>
  </div>
</div>
<div class="absolute bottom-0 left-0 right-0 bg-white">
  <div class="flex items-center justify-between px-5 py-2">
    <button
      class="button-outline-default grow hover:opacity-70 mr-4"
      (click)="cancel()"
    >
      Hủy
    </button>
    <button class="button-success grow hover:opacity-70" (click)="update()">
      Cập nhật
    </button>
  </div>
</div>
