export class UserInfo {
  id?: string;
  fullName?: string;
  nickName?: string;
  bio?: string;
  favoriteQuote?: string;
  login?: string;
  avatar?: string;
  coverImage?: string;
  dob?: any;
  gender?: any;
  email?: any;
  phoneNumber?: any;
  referralCode?: any;
  address?: string;
  city?: string;
  district?: string;
  commune?: string;
  hideInfo?: any;
  registerAddressLink?: string;
  hightlight?: string;
  logo?: any;
  baseInfor?: BaseInfor[];
  error?: any;
}

export class BaseInfor {
  id?: string;
  type?: string;
  title?: string;
  error?: any;
}
