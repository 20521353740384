<nz-radio-group
  nzCheckedColor="#52c41a"
  nzColor="#f5222d"
  class="flex flex-col"
  [(ngModel)]="item.typeFunctionBtn"
>
  <div>
    <label nz-radio class="block" [nzValue]="ButtonType.scrolltocta"
      >Kéo đến kêu gọi hành động</label
    >
  </div>
  <div class="my-3">
    <label nz-radio class="block" [nzValue]="ButtonType.opendialog"
      >Mở ra hộp thông tin</label
    >
  </div>
  <div>
    <label nz-radio class="block" [nzValue]="ButtonType.linkextra"
      >Liên kết bổ sung</label
    >
  </div>
</nz-radio-group>
<nz-divider style="margin: 16px 0px"></nz-divider>
<div *ngIf="item.typeFunctionBtn === null">
  <div class="font-semibold">Chưa chọn tính năng cho nút bấm</div>
</div>
<!-- <pre>{{ item | json }}</pre> -->
<div [ngSwitch]="item.typeFunctionBtn">
  <div *ngSwitchCase="ButtonType.scrolltocta"></div>
  <div
    *ngSwitchCase="ButtonType.opendialog"
    class="flex items-center justify-between"
  >
    <div
      class="button-success py-2 px-3 rounded-sm"
      (click)="openDialogAction()"
    >
      Xem hộp thông tin
    </div>
    <div
      style="border: 1px solid #333"
      class="button-default py-2 px-3 rounded-sm"
      (click)="editDialogAction()"
    >
      Sửa hộp thông tin
    </div>
  </div>
  <div *ngSwitchCase="ButtonType.linkextra">
    <div class="mb-2">Liên kết</div>
    <input type="text" nz-input nzSize="large" [(ngModel)]="item.link" />
  </div>
</div>
<!-- footer -->
<div class="absolute bottom-0 left-0 right-0 bg-white">
  <div class="flex items-center justify-between px-5 py-2">
    <button
      class="button-outline-default grow hover:opacity-70 mr-4"
      (click)="cancel()"
    >
      Hủy
    </button>

    <button class="button-success grow hover:opacity-70" (click)="update()">
      Cập nhật
    </button>
  </div>
</div>
