<div class="h-full w-full" *ngIf="type === TypeUpload.Custom">
  <label
    [for]="'uploadCustom' + index"
    class="rounded-sm cursor-pointer hover:opacity-70 w-full h-full block z-10"
  >
    <ng-content></ng-content>
  </label>
  <input
    (change)="uploadImageCustom($event)"
    [id]="'uploadCustom' + index"
    class="hidden"
    type="file"
    accept="image/png, image/jpeg"
  />
</div>
<div *ngIf="type === TypeUpload.Default">
  <div class="grid gap-1" [ngClass]="typeCol">
    <!-- hiển thị ảnh -->
    <div class="relative" *ngFor="let img of listImage">
      <img
        [nzSrc]="img"
        nz-image
        alt=""
        class="rounded-sm cursor-pointer hover:opacity-70 block border border-solid border-disable"
      />
      <button
        class="absolute top-1 right-1 text-red-text bg-white p-0 rounded-full w-[20px] h-[20px]"
        (click)="removeBanner(img)"
      >
        <i class="fa-regular fa-trash-can fa-sm"></i>
      </button>
    </div>
    <!-- button thêm mới -->
    <label
      *ngIf="listImage.length < maxNumberImage"
      [for]="'uploadImage' + index"
      class="rounded-sm cursor-pointer hover:opacity-70 block w-full aspect-video border border-solid border-primary"
    >
      <div
        class="relative flex items-center justify-center text-primary h-full w-full"
      >
        <i
          class="absolute text-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 fa-solid fa-plus m-auto"
        ></i>
      </div>
    </label>
    <input
      (change)="uploadImage($event)"
      [id]="'uploadImage' + index"
      class="hidden"
      type="file"
      accept="image/png, image/jpeg"
    />
  </div>
</div>
<div *ngIf="type === TypeUpload.Only">
  <div class="grid gap-1" [ngClass]="typeCol">
    <img
      *ngFor="let img of listImage"
      [src]="img"
      alt=""
      class="rounded-sm cursor-pointer hover:opacity-70 block border border-dashed border-disable"
    />
    <label
      [for]="'uploadImage' + index"
      style="border: dashed 1px #a5a5a9"
      class="rounded-sm cursor-pointer hover:opacity-70 block aspect-square"
    >
      <div class="relative flex items-center justify-center aspect-square">
        <i
          class="absolute text-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 fa-solid fa-plus m-auto"
        >
        </i>
      </div>
    </label>
    <input
      (change)="uploadImage($event)"
      [id]="'uploadImage' + index"
      class="hidden"
      type="file"
      accept="image/png, image/jpeg"
    />
  </div>
</div>
