import { Injectable } from '@angular/core';
import { Keys } from '../components/edit-components/edit-type-action/edit-type-action.component';
import {
  BodyUpdateAction,
  ItemType,
  listActionsOptionsEnum,
  ResponseAction,
  TypeAction,
} from '../models/screenEdit';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  keys: Keys[] = [
    {
      key: 'name',
      value: listActionsOptionsEnum.Fullname,
    },
    {
      key: 'phone',
      value: listActionsOptionsEnum.Phone,
    },
    {
      key: 'email',
      value: listActionsOptionsEnum.Email,
    },
    {
      key: 'address',
      value: listActionsOptionsEnum.Address,
    },
    {
      key: 'birthday',
      value: listActionsOptionsEnum.Birthday,
    },
    {
      key: 'note',
      value: listActionsOptionsEnum.Note,
    },
  ];
  constructor() {}
  mapResponseToView(response: ResponseAction): TypeAction {
    let result: TypeAction = {
      id: response.id,
      content: response.title,
      type: ItemType.action,
      btnActionText: response.actionInfor,
      bgImage: response.background,
      backgroundColor: response.backgroundColor,
      alignmentBottom: response.alignmentBottom || 0,
      alignmentTop: response.alignmentTop || 0,
      alignmentRight: response.alignmentRight || 0,
      alignmentLeft: response.alignmentLeft || 0,
      // list input
      name: {
        isShow: response.actions?.includes(
          String(listActionsOptionsEnum.Fullname)
        ),
      },
      email: {
        isShow: response.actions?.includes(
          String(listActionsOptionsEnum.Email)
        ),
      },
      phone: {
        isShow: response.actions?.includes(
          String(listActionsOptionsEnum.Phone)
        ),
      },
      address: {
        isShow: response.actions?.includes(
          String(listActionsOptionsEnum.Address)
        ),
      },
      birthday: {
        isShow: response.actions?.includes(
          String(listActionsOptionsEnum.Birthday)
        ),
      },
      note: {
        isShow: response.actions?.includes(String(listActionsOptionsEnum.Note)),
      },
    };
    return result;
  }
  mapViewToResponse(formValue: any, landingID: string): BodyUpdateAction {
    return {
      title: formValue.content,
      actionInfor: formValue.buttonText,
      actions: this.pushToListAction(formValue),
      landingID: landingID,
    };
  }

  pushToListAction(value: any) {
    let listResult: string[] = [];
    this.keys.forEach((key: Keys) => {
      if (value[key.key]) {
        listResult.push(String(key.value));
      }
    });
    return listResult;
  }
}
