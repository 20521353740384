import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]',
})
export class NumberOnlyDirective {
  constructor() {}

  @HostListener('paste', ['$event']) onPaste(event: any) {
    const value = event.clipboardData.getData('text');
    if (value.search(/[^0-9]/) >= 0) {
      event.preventDefault();
    }
  }

  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    if (event.key.search(/[^0-9]/) >= 0) {
      event.preventDefault();
    }
  }
}
