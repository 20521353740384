export const POST_STATUS = [
  {
    value: 1,
    label: 'Hết hàng',
    checked: false
  },
  {
    value: 2,
    label: 'Ẩn bài đăng',
    checked: false
  }
]