import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent implements OnInit, OnChanges {
  @Input() skeletonCount: number = 1;
  @Input() isLoading: boolean = true;
  skeletonList: number[] = [1];
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.skeletonCount > 1) {
      this.skeletonList = [];
      for (let i = 0; i < this.skeletonCount; i++) {
        this.skeletonList.push(1);
      }
    }
  }
}
