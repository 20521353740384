<div *nzModalTitle>
  <h3>{{ inputTitle }}</h3>
</div>
<div class="text-center">
  <image-cropper
    [cropperMinWidth]="500"
    [imageChangedEvent]="file"
    [aspectRatio]="aspectRatio"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (loadImageFailed)="loadImageFailed()"
    [transform]="imageTransform"
    [roundCropper]="inputRoundedCropper"
    [maintainAspectRatio]="maintainAspectRatio"
    [imageQuality]="1000"
  ></image-cropper>
</div>
<div *nzModalFooter>
  <button
    class="button-info mr-3 px-3"
    [disabled]="disabledBtn"
    (click)="cancel()"
  >
    Hủy
  </button>
  <button class="button-success px-3" [disabled]="disabledBtn" (click)="ok()">
    Xác nhận
  </button>
</div>
